import { Card, Grid, Hidden, Paper, Typography } from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import image from "../../../../src/Assets/Images/loginImg.svg";
import Logoimg from "../../../Assets/Logos/epackbookLogoBlue.svg";
import GenericInput from "../../../common-components/form-elements/genericInput";
import GenericLoadingButton from "../../../common-components/form-elements/genericLoadingButton";
import {
  authInitial,
  authSchema,
} from "../../../common-components/validator/auth-validation";
import { useAuth } from "../../../hooks/useAuth";
import {
  EMAIL_ADDRESS_LABEL,
  LOGIN,
  LOGIN_WITH_EMAIL_AND_PASSWORD,
  LOGIN_WITH_MOBILE,
  OTP_LABEL,
  PASSWORD_LABEL,
  REGISTER_AND_LOGIN,
  VERIFY_OTP,
} from "../../../languages/en";
import { getAuthState, getRoleState } from "../../../store/selectors";
import {
  requestForGetAuthDetails,
  requestForLogin,
  requestForVerifyOTP,
  resetAuth,
} from "../../../store/slices/AuthSlice";
import { requestForAllRole } from "../../../store/slices/RoleSlice";

const Login = () => {
  const dispatch = useDispatch();

  const { signin, moveToRegister } = useAuth();
  const auth = useSelector(getAuthState);
  const role = useSelector(getRoleState);
  const {
    responseFromLogin,
    responseToVerifyOTP,

    loginResponse: { id: authId },
  } = auth;
  const { allRole } = role;
  const [buttonText, setButtonText] = useState(LOGIN);
  const [vendorID, setVendorID] = useState(null);
  const navigation = useNavigate();
  const initialLoginDetails = {
    email: "",
    password: "",
    mobile: "",
    otp: "",
  };
  const [loginDetails, setLoginDetails] = useState(initialLoginDetails);
  const onEmailChange = (evt) => {
    if (evt.target.value === "") {
      dispatch(resetAuth());
      setButtonText(LOGIN);
      return;
    }
    setLoginDetails({ ...loginDetails, email: evt.target.value });
    setButtonText(LOGIN_WITH_EMAIL_AND_PASSWORD);
  };

  const onMobileChange = (evt) => {
    if (evt.target.value === "") {
      dispatch(resetAuth());
      setButtonText(LOGIN);
      return;
    }
    setLoginDetails({ ...loginDetails, mobile: evt.target.value });
    setButtonText(LOGIN_WITH_MOBILE);
  };
  const onOTPCHange = (evt) => {
    if (evt.target.value === "") {
      dispatch(resetAuth());
      setButtonText(LOGIN);
      return;
    }
    setLoginDetails({ ...loginDetails, otp: evt.target.value });
  };
  const onPasswordChange = (evt) => {
    if (evt.target.value === "") {
      dispatch(resetAuth());
      setButtonText(LOGIN);
      return;
    }
    setLoginDetails({ ...loginDetails, password: evt.target.value });
  };

  const onSubmit = (values) => {
    if (
      loginDetails.email === "" &&
      loginDetails.password === "" &&
      loginDetails.otp === "" &&
      loginDetails.mobile !== ""
    ) {
      setButtonText(VERIFY_OTP);
      dispatch(requestForLogin({ mobile: values.mobile, roleID: vendorID }));
    } else if (
      loginDetails.email === "" &&
      loginDetails.password === "" &&
      loginDetails.otp !== "" &&
      loginDetails.mobile !== ""
    ) {
      dispatch(requestForVerifyOTP({ mobile: values.mobile, otp: values.otp }));
    } else if (
      loginDetails.email !== "" &&
      loginDetails.password !== "" &&
      loginDetails.otp === "" &&
      loginDetails.mobile === ""
    ) {
      dispatch(
        requestForLogin({ email: values.email, password: values.password })
      );
    }
    signin();
  };
  useEffect(() => {
    if (responseToVerifyOTP) {
      dispatch(requestForGetAuthDetails(authId));
    }
  }, [authId, responseToVerifyOTP]);
  useEffect(() => {
    if (moveToRegister) {
      navigation("/registration");
    }
  }, [moveToRegister]);
  useEffect(() => {
    dispatch(requestForAllRole());
  }, []);
  useEffect(() => {
    const vendor = allRole.find(
      (ele) => (ele?.roleName).toLowerCase() === "vendor"
    );
    setVendorID(vendor?.id);
  }, [allRole]);

  return (
    <Paper sx={{ height: "93.5vh" }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <img src={Logoimg} alt="Logo" style={{ width: "300px" }} />
        </Grid>
      </Grid>
      <Formik
        initialValues={authInitial}
        validationSchema={authSchema}
        onSubmit={onSubmit}
      >
        {({ handleChange, handleBlur, isValid, errors }) => (
          <Form>
            <Grid container spacing={2} sx={{ py: 0 }}>
              <Grid item xs={12} md={6}>
                <Card sx={{ padding: "20px", gap: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5" fontWeight={300}>
                        {REGISTER_AND_LOGIN}
                      </Typography>
                    </Grid>
                    {!loginDetails.email && (
                      <Fragment>
                        <Grid item xs={12}>
                          <GenericInput
                            label={"Mobile Number"}
                            type={"text"}
                            name="mobile"
                            id="mobile"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyUp={onMobileChange}
                          />
                          <ErrorMessage
                            name={"mobile"}
                            component="p"
                            className="error"
                          ></ErrorMessage>
                        </Grid>
                        {responseFromLogin && (
                          <Grid item xs={12}>
                            <GenericInput
                              label={OTP_LABEL}
                              type={"text"}
                              name="otp"
                              id="otp"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onKeyUp={onOTPCHange}
                            />
                            <ErrorMessage
                              name={"otp"}
                              component={"p"}
                              className="error"
                            ></ErrorMessage>
                          </Grid>
                        )}
                      </Fragment>
                    )}

                    {!loginDetails.mobile && (
                      <Fragment>
                        <Grid item xs={12}>
                          <GenericInput
                            label={EMAIL_ADDRESS_LABEL}
                            type={"text"}
                            name="email"
                            id="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyUp={onEmailChange}
                          />
                          <ErrorMessage
                            name={"email"}
                            component={"p"}
                            className="error"
                          ></ErrorMessage>
                        </Grid>
                        {loginDetails.email && (
                          <Grid item xs={12}>
                            <GenericInput
                              label={PASSWORD_LABEL}
                              type={"password"}
                              name="password"
                              id="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onKeyUp={onPasswordChange}
                            />
                            <ErrorMessage
                              name={"password"}
                              component={"p"}
                              className="error"
                            ></ErrorMessage>
                          </Grid>
                        )}
                      </Fragment>
                    )}
                    <Grid item xs={12}>
                      <GenericLoadingButton
                        fullWidth
                        type="submit"
                        sx={{ my: 3 }}
                      >
                        {buttonText}
                      </GenericLoadingButton>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Hidden mdDown>
                <Grid item md={6}>
                  <img
                    src={image}
                    alt="img"
                    style={{ width: "100%", height: "100%" }}
                  />
                </Grid>
              </Hidden>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};

export default Login;
