import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas/rootSagas";
import AuthSlice from "./slices/AuthSlice";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import CompanySlice from "./slices/CompanySlice";
import ThemeSlice from "./slices/ThemeSlice";
import UtilsSlice from "./slices/UtilsSlice";
import RoleSlice from "./slices/RoleSlice";
import ModuleSlice from "./slices/ModuleSlice";
import FeatureSlice from "./slices/FeatureSlice";
import ModuleFeatureMapperSlice from "./slices/ModuleFeatureMapperSlice";
import BrFrSlice from "./slices/BrFrSlice";
import EmployeeEnrollSlice from "./slices/EmployeeEnrollSlice";
import PlanSlice from "./slices/PlanSlice";

const sagaMiddleware = createSagaMiddleware();
const storeMiddleware = [sagaMiddleware];
const persistConfig = {
  key: "root",
  storage,
};
const appReducer = combineReducers({
  auth: AuthSlice,
  company: CompanySlice,
  theme: ThemeSlice,
  role: RoleSlice,
  utils: UtilsSlice,
  module: ModuleSlice,
  feature: FeatureSlice,
  moduleFeatureMapper: ModuleFeatureMapperSlice,
  brFr: BrFrSlice,
  employeeEnroll: EmployeeEnrollSlice,
  plan: PlanSlice,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const _store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(storeMiddleware);
  },
});
sagaMiddleware.run(rootSaga);
export const _persistorStore = persistStore(_store);
export default _store;
