import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  allBrFrs: [],
  requestAllBrFrByCompanyId: false,
  requestCreateBrFr: false,
  responseCreateBrFr: false,
  failedCreateBrFr: false,
  requestDeleteBrFr: false,
  requestUpdateBrFr: false,
  failedAllBrFrByCompanyId: false,
  responseAllBrFrByCompanyId: false,
  responseDeleteBrFr: false,
  responseUpdateBrFr: false,
  failedDeleteBrFr: false,
  failedUpdateBrFr: false,
};
export const BrFrSlice = createSlice({
  name: "BrFr",
  initialState,
  reducers: {
    reset: (state) => {
      state.requestAllBrFrByCompanyId = false;
      state.failedAllBrFrByCompanyId = false;
      state.responseAllBrFrByCompanyId = false;
      state.requestCreateBrFr = false;
      state.responseCreateBrFr = false;
      state.failedCreateBrFr = false;
      state.requestUpdateBrFr = false;
      state.responseUpdateBrFr = false;
      state.failedUpdateBrFr = false;
      state.requestDeleteBrFr = false;
      state.responseDeleteBrFr = false;
      state.failedDeleteBrFr = false;
    },
    requestForAllBrFrByCompanyId: (state) => {
      state.requestAllBrFrByCompanyId = true;
    },
    responseForAllBrFrByCompanyId: (state, action) => {
      state.allBrFrs = action?.payload;
      state.responseAllBrFrByCompanyId = true;
    },
    failedForAllBrFrByCompanyId: (state) => {
      state.failedAllBrFrByCompanyId = true;
    },
    requestForCreateBrFr: (state) => {
      state.requestCreateBrFr = true;
    },
    responseForCreateBrFr: (state) => {
      state.responseCreateBrFr = true;
    },
    failedForCreateBrFr: (state) => {
      state.failedCreateBrFr = true;
    },
    requestForDeleteBrFr: (state) => {
      state.requestDeleteBrFr = true;
    },
    responseForDeleteBrFr: (state) => {
      state.responseDeleteBrFr = true;
    },
    failedForDeleteBrFr: (state) => {
      state.failedDeleteBrFr = true;
    },
    requestForUpdateBrFr: (state) => {
      state.requestUpdateBrFr = true;
    },
    responseForUpdateBrFr: (state) => {
      state.responseUpdateBrFr = true;
    },
    failedForUpdateBrFr: (state) => {
      state.failedUpdateBrFr = true;
    },
  },
});
export default BrFrSlice.reducer;
export const {
  failedForAllBrFrByCompanyId,
  failedForCreateBrFr,
  failedForDeleteBrFr,
  failedForUpdateBrFr,
  requestForAllBrFrByCompanyId,
  requestForCreateBrFr,
  responseForCreateBrFr,
  requestForDeleteBrFr,
  requestForUpdateBrFr,
  reset,
  responseForAllBrFrByCompanyId,
  responseForDeleteBrFr,
  responseForUpdateBrFr,
} = BrFrSlice.actions;
