import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  allModuleFeatureMappers: [],
  requestAllModuleFeatureMapper: false,
  requestCreateModuleFeatureMapper: false,
  requestDeleteModuleFeatureMapper: false,
  requestUpdateModuleFeatureMapper: false,
  failedAllModuleFeatureMapper: false,
  responseAllModuleFeatureMapper: false,
  responseCreateModuleFeatureMapper: false,
  responseDeleteModuleFeatureMapper: false,
  responseUpdateModuleFeatureMapper: false,
  failedCreateModuleFeatureMapper: false,
  failedDeleteModuleFeatureMapper: false,
  failedUpdateModuleFeatureMapper: false,
};
export const ModuleFeatureMapperSlice = createSlice({
  name: "moduleFeatureMapper",
  initialState,
  reducers: {
    reset: (state) => {
      state.requestAllModuleFeatureMapper = false;
      state.failedAllModuleFeatureMapper = false;
      state.responseAllModuleFeatureMapper = false;
      state.requestCreateModuleFeatureMapper = false;
      state.responseCreateModuleFeatureMapper = false;
      state.failedCreateModuleFeatureMapper = false;
      state.requestUpdateModuleFeatureMapper = false;
      state.responseUpdateModuleFeatureMapper = false;
      state.failedUpdateModuleFeatureMapper = false;
      state.requestDeleteModuleFeatureMapper = false;
      state.responseDeleteModuleFeatureMapper = false;
      state.failedDeleteModuleFeatureMapper = false;
    },
    requestForAllModuleFeatureMapper: (state) => {
      state.requestAllModuleFeatureMapper = true;
    },
    responseForAllModuleFeatureMapper: (state, action) => {
      state.allModuleFeatureMappers = action?.payload;
      state.responseAllModuleFeatureMapper = true;
    },
    failedForAllModuleFeatureMapper: (state) => {
      state.failedAllModuleFeatureMapper = true;
    },
    requestForCreateModuleFeatureMapper: (state) => {
      state.requestCreateModuleFeatureMapper = true;
    },
    responseForCreateModuleFeatureMapper: (state) => {
      state.responseCreateModuleFeatureMapper = true;
    },
    failedForCreateModuleFeatureMapper: (state) => {
      state.failedCreateModuleFeatureMapper = true;
    },
    requestForDeleteModuleFeatureMapper: (state) => {
      state.requestDeleteModuleFeatureMapper = true;
    },
    responseForDeleteModuleFeatureMapper: (state) => {
      state.responseDeleteModuleFeatureMapper = true;
    },
    failedForDeleteModuleFeatureMapper: (state) => {
      state.failedDeleteModuleFeatureMapper = true;
    },
    requestForUpdateModuleFeatureMapper: (state) => {
      state.requestUpdateModuleFeatureMapper = true;
    },
    responseForUpdateModuleFeatureMapper: (state) => {
      state.responseUpdateModuleFeatureMapper = true;
    },
    failedForUpdateModuleFeatureMapper: (state) => {
      state.failedUpdateModuleFeatureMapper = true;
    },
  },
});
export default ModuleFeatureMapperSlice.reducer;
export const {
  reset,
  requestForAllModuleFeatureMapper,
  failedForAllModuleFeatureMapper,
  responseForAllModuleFeatureMapper,
  failedForCreateModuleFeatureMapper,
  requestForCreateModuleFeatureMapper,
  responseForCreateModuleFeatureMapper,
  failedForDeleteModuleFeatureMapper,
  requestForDeleteModuleFeatureMapper,
  responseForDeleteModuleFeatureMapper,
  failedForUpdateModuleFeatureMapper,
  requestForUpdateModuleFeatureMapper,
  responseForUpdateModuleFeatureMapper,
} = ModuleFeatureMapperSlice.actions;
