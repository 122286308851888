import * as Yup from "yup";
import { NAME_WITH_CHARACTERS_REGEX } from "../../../utils/regular-expressions";
export const moduleInitial = {
  moduleName: "",
  parentModuleID: 0,
};

export const moduleSchema = Yup.object().shape({
  moduleName: Yup.string("Please Enter Module Name!")
    .required("Module Name Is Required!")
    .matches(NAME_WITH_CHARACTERS_REGEX, "Please Enter Valid Module Name!"),
  parentModuleID: Yup.string("Please Select Parent Module ID!"),
});
