import { Divider } from "@mui/material";

const GenericDivider = () => {
  return (
    <Divider
      sx={{
        height: "0.5px",
        width: "100%",
        p:"5px",
        backgroundColor: (theme) => theme.palette.primary.contrastText,
      }}
    />
  );
};
export default GenericDivider;
