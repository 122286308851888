import { Route, Routes } from "react-router-dom";

import AddBrFr from "./add";

const BrFrRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AddBrFr />}></Route>
    </Routes>
  );
};
export default BrFrRoutes;
