import * as Yup from "yup";
import { NAME_WITH_CHARACTERS_REGEX } from "../../../utils/regular-expressions";
export const featureInitial = {
  featureName: "",
};

export const featureSchema = Yup.object().shape({
  featureName: Yup.string("Please Enter Feature Name!")
    .required("Feature Name Is Required!")
    .matches(NAME_WITH_CHARACTERS_REGEX, "Please Enter Valid Feature Name!"),
});
