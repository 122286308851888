import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { Grid, InputAdornment, Paper } from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import { Fragment, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import GenericAction from "../../../../../../common-components/form-elements/genericAction";
import GenericCheckbox from "../../../../../../common-components/form-elements/genericCheckbox";
import GenericDataGrid from "../../../../../../common-components/form-elements/genericDataGrid";
import GenericDropdown from "../../../../../../common-components/form-elements/genericDropdown";
import GenericImagePicker from "../../../../../../common-components/form-elements/genericImagePicker";
import GenericInput from "../../../../../../common-components/form-elements/genericInput";
import GenericLoadingButton from "../../../../../../common-components/form-elements/genericLoadingButton";
import GenericSwitch from "../../../../../../common-components/form-elements/genericSwitch";
import SubHeader from "../../../../../../common-components/page-elements/SubHeader";
import {
  ACCOUNT_HOLDER_NAME_LABEL,
  ACCOUNT_IFSC_LABEL,
  ACCOUNT_LABEL,
  ACCOUNT_NUMBER_LABEL,
  ACCOUNT_TYPE_LABEL,
  ACCOUNT_TYPE_LIST,
  SELECT_BANK_LABEL,
  SUBMIT_LABEL,
  VPA_LABEL,
  VPA_SUFFIX_LABEL,
} from "../../../../../../languages/en";
const AddAccounts = () => {
  const navigate = useNavigate();
  const onDeleteHandler = (data) => {
    // dispatch(requestForDeleteFeature(data?.id));
    // if (responseDeleteFeature) {
    //   dispatch(reset());
    // }
  };
  const onStatusChangeHandler = (row) => {
    // dispatch(requestForUpdateFeature({ ...row, isActive: !row.isActive }));
    // if (responseUpdateFeature) {
    //   dispatch(reset());
    // }
  };
  const onEditHandler = (data) => {
    navigate(`./${data?.id}`);
  };
  const getQRCode = (data) => {
    console.log("qrcode data", data);
  };
  const columns = useMemo(() => {
    return [
      {
        field: "serialNo",
        headerName: "Serial No.",

        renderCell: (data) => {
          return data.row.serialNo + 1;
        },
      },
      { field: "id", headerName: "ID." },

      {
        field: "bankName",
        headerName: "Bank Name",
        width: 150,

        editable: false,
      },
      {
        field: "isActive",
        headerName: "Status",
        width: 250,
        editable: false,
        renderCell: (data) => {
          return (
            <GenericSwitch
              start={"Active"}
              end={"Inactive"}
              value={data?.row?.isActive}
              onChange={() => onStatusChangeHandler(data?.row)}
            />
          );
        },
      },
      {
        field: "createdAt",
        headerName: "Created Date",
        width: 150,
        editable: false,
        renderCell: (data) => {
          return new Date(data?.row?.createdAt).toDateString();
        },
      },
      {
        field: "updatedAt",
        headerName: "Updated Date",
        width: 150,
        editable: false,
        renderCell: (data) => {
          return new Date(data?.row?.updatedAt).toDateString();
        },
      },
      {
        headerName: "Action",
        width: 150,
        field: "",
        editable: false,
        renderCell: (data) => {
          return (
            <GenericAction
              data={data?.row}
              onDeleteHandler={(data) => onDeleteHandler(data)}
              onEditHandler={(data) => onEditHandler(data)}
              deleteRequired={true}
              editRequired={true}
            />
          );
        },
      },
    ];
  }, []);
  const onSubmit = () => {};
  return (
    <Fragment>
      <SubHeader title={ACCOUNT_LABEL} />
      <Paper sx={{ padding: "30px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Formik onSubmit={onSubmit}>
              {({ handleChange, handleBlur, isValid }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={6}>
                      <GenericDropdown
                        data={ACCOUNT_TYPE_LIST}
                        label={ACCOUNT_TYPE_LABEL}
                        name="accountType"
                        // onChange={handleChange}
                        // onBlur={handleBlur}
                        id="accountType"
                      />
                      <ErrorMessage
                        name={"accountType"}
                        component="p"
                        className="error"
                      ></ErrorMessage>
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <GenericDropdown
                        data={ACCOUNT_TYPE_LIST}
                        label={SELECT_BANK_LABEL}
                        name="bankID"
                        // onChange={handleChange}
                        // onBlur={handleBlur}
                        id="bankID"
                      />
                      <ErrorMessage
                        name={"bankID"}
                        component="p"
                        className="error"
                      ></ErrorMessage>
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <GenericInput
                        label={ACCOUNT_HOLDER_NAME_LABEL}
                        name="accountHolderName"
                        id="accountHolderName"
                        // onChange={handleChange}
                        // onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name={"accountHolderName"}
                        component="p"
                        className="error"
                      ></ErrorMessage>
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <GenericInput
                        label={ACCOUNT_NUMBER_LABEL}
                        name="accountNumber"
                        id="accountNumber"
                        // onChange={handleChange}
                        // onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name={"accountNumber"}
                        component="p"
                        className="error"
                      ></ErrorMessage>
                    </Grid>

                    <Grid item md={4} xs={6}>
                      <GenericInput
                        label={ACCOUNT_IFSC_LABEL}
                        name="ifscCode"
                        id="ifscCode"
                        // onChange={handleChange}
                        // onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name={"ifscCode"}
                        component="p"
                        className="error"
                      ></ErrorMessage>
                    </Grid>
                    <Grid item md={2} xs={3}>
                      <GenericInput
                        label={VPA_LABEL}
                        name="vpa"
                        id="vpa"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <AlternateEmailIcon
                                sx={{ height: 15, width: 15 }}
                              />
                            </InputAdornment>
                          ),
                        }}
                        // onChange={handleChange}
                        // onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name={"vpa"}
                        component="p"
                        className="error"
                      ></ErrorMessage>
                    </Grid>
                    <Grid item md={2} xs={3}>
                      <GenericInput
                        label={VPA_SUFFIX_LABEL}
                        name="vpaSuffix"
                        id="vpaSuffix"

                        // onChange={handleChange}
                        // onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name={"upiId"}
                        component="p"
                        className="error"
                      ></ErrorMessage>
                    </Grid>

                    <Grid item md={4} xs={6}>
                      <GenericImagePicker
                        getImageUrl={getQRCode}
                        sx={{ width: "90%" }}
                        isCompressed={true}
                      />
                    </Grid>
                    <Grid
                      item
                      md={8}
                      xs={6}
                      sx={{
                        alignItems: "center",
                        border: "1px solid #CACACA",
                        mt: 2,
                        borderRadius: "5px",
                        p: "0px 0px 0px 16px !important",
                      }}
                    >
                      <GenericCheckbox
                        list={[]}
                        // onChange={onContactCheckboxChange}
                        // value={formik?.values?.chooseModule}
                        style={{ display: "flex" }}
                        // onBlur={formik.handleBlur}
                        // onKeyUp={formik.handleChange}
                        // onFocus={formik.handleChange}
                        // error={
                        //   formik?.errors?.chooseModule &&
                        //   formik?.touched?.chooseModule &&
                        //   formik?.errors?.chooseModule
                        // }
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <GenericLoadingButton
                        disabled={!isValid}
                        type="submit"
                        sx={{ float: "right" }}
                      >
                        {SUBMIT_LABEL}
                      </GenericLoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <GenericDataGrid
                  getRowHeight={() => "auto"}
                  rows={[]?.map((row, index) => {
                    return {
                      serialNo: index++,
                      ...row,
                    };
                  })}
                  columns={columns}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};
export default AddAccounts;
