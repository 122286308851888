import * as Yup from "yup";
import { ADDRESS_REGEX, COMPANY_NAME_REGEX, COMPANY_WEBSITE_REGEX, EMAIL_REGEX, MOBILE_REGEX, OWNER_REGEX, PINCODE_REGEX } from "../../../utils/regular-expressions";

export const companyAddressDetailsInitial = {
  pincode: "",
  state: "",
  city: "",
  locality: "",
  companyAddress: "",
  district: "",
  landmark: "",
  postOffice: "",
}
export const companyAddressDetailsSchema = Yup.object().shape({
  pincode: Yup.string("Enter Pincode")
   .required("Pincode Is Required")
   .matches(PINCODE_REGEX, "Please Enter 6 Digit Pincode Number Only "),
   state: Yup.string("Enter State")
   .required("State Is Required")
   .matches(OWNER_REGEX, "Please Enter Valid State "),
   city: Yup.string("Enter City")
   .required("City Is Required")
   .matches(OWNER_REGEX, "Please Enter Valid City"),
   locality: Yup.string("Enter Locality")
   .required("Locality Is Required")
   .matches(OWNER_REGEX, "Please Enter Valid Locality"),
   companyAddress: Yup.string("Please Enter Company Address")
   .required("Company Address Is Required")
   .matches(ADDRESS_REGEX, "Please Enter Valid Address"),
   district: Yup.string("Enter District")
  .required("District Is Required"),
  landmark: Yup.string("Enter Landmark"),
  postOffice: Yup.string("Enter Post Office")
  
   
})
export const companySignatureDetailsInitial = {
  companySignature: "",
}
export const profileInitial = {
  companyLogo: "",
  companyProfile: "",
  
  referralCode: "",
  serialNo: "",
  
};
export const companyBasicDetailsInitial = {
  contactPerson: "",
  companyName: "",
  companyMobile: "",
  email: "",
  punchLine: "",
  companyWebsite: "",
}
export const companyBasicDetailsSchema = Yup.object().shape({
  contactPerson: Yup.string("Enter Contact Person")
   .required("Contact Person Is Required")
   .matches(OWNER_REGEX, "Please Enter Contact Person Name "),
  companyName: Yup.string("Enter Company Name")
   .required("Company Name Is Required")
   .matches(COMPANY_NAME_REGEX, "Please Enter Valid Name "),
  companyMobile: Yup.string("Enter Mobile Number")
   .required("Mobile Number Is Required")
   .matches(MOBILE_REGEX, "Please Enter Valid Mobile Number"),
   companyWebsite: Yup.string("Enter Company Website")
   .required("Company Website Is Required")
   .matches(COMPANY_WEBSITE_REGEX, "Please Enter Valid Company website"),
  email: Yup.string("Please Enter Company Email")
   .required("Company Email Is Required")
   .matches(EMAIL_REGEX, "Please Enter Valid Email"),
  punchLine: Yup.string("Enter Punch line")
  .required("Punch Line Is Required")
   
})
// export const asasasasas = Yup.object().shape({
//   companyName: Yup.string("Enter Company Name")
//     .required("Company Name Is Required")
//     .matches(COMPANY_NAME_REGEX, "Please Enter Valid Name "),
//   contactPerson: Yup.string("Enter Contact Person")
//     .required("Contact Person Is Required")
//     .matches(NAME_WITH_CHARACTERS_REGEX, "Please Enter Contact Person Name "),
//   companyEmail: Yup.string("Please Enter Company Email")
//     .required("Company Email Is Required")
//     .matches(EMAIL_REGEX, "Please Enter Valid Email"),
//   pincode: Yup.string("Enter Pincode")
//     .required(" Pincode Is Required")
//     .max(6, "Pickup Address Pincode Should Be 6 Digits Only")
//     .matches(PINCODE_REGEX, "Please Enter Valid Pickup Address Pincode"),
//   address: Yup.string("Enter Address")
//     .matches(ADDRESS_REGEX, "Please Enter Valid Address")
//     .required("Address is Required"),
//   companyType: Yup.string()
//     .typeError("You must select an Option")
//     .required("Company Type is required"),
//   panNo: Yup.string("Enter Party Name").when("companyType", {
//     is: "proprietorship",
//     then: (schema) =>
//       schema
//         .required("PAN Number Is Required")
//         .matches(PAN_REGEX, "Please Enter Valid PAN Number"),
//     otherwise: null,
//   }),
//   firmPanNo: Yup.string("Enter Firm PAN Number").when("companyType", {
//     is: "partnershipFirm",
//     then: (schema) =>
//       schema
//         .required("Firm PAN Number Is Required")
//         .matches(PAN_REGEX, "Please Enter Valid PAN Number"),
//     otherwise: null,
//   }),
//   incNo: Yup.string("CIN Number").when("companyType", {
//     is: "pvtLtdCompany",
//     then: (schema) =>
//       schema.matches(
//         /^(?:[LU][0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}|[0-9A-Z]{8})$/,
//         "Please Enter Valid Number"
//       ),
//     otherwise: null,
//   }),
//   cinNo: Yup.string("CIN Number").when("companyType", {
//     is: "llp",
//     then: (schema) =>
//       schema.matches(
//         /^(?:[LU][0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}|[0-9A-Z]{8})$/,
//         "Please Enter Valid Number"
//       ),
//     otherwise: null,
//   }),
//   gstNo: Yup.string("Enter GST Number"),

//   companyWebsite: Yup.string().matches(
//     COMPANY_WEBSITE_REGEX,
//     "Please Enter Valid Website Name"
//   ),
//   iba: Yup.string().required("IBA is required"),
//   ibaApprovalCode: Yup.string("Enter IBA Approval code").when("iba", {
//     is: "approved",
//     then: (schema) => schema.required("IBA Approval code is required"),
//     otherwise: null,
//   }),
//   ibaCodeValidTill: Yup.string("Enter IBA Valid Till").when("iba", {
//     is: "approved",
//     then: (schema) => schema.required("IBA code valid till is required"),
//     otherwise: null,
//   }),
// });
