import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  pickup: {
    city: [],
    state: [],
    locality: [],
  },
  drop: {
    city: [],
    state: [],
    locality: [],
  },
  uploadImage: "",

  // Add Vehicle Images
  RcImage: "",
  pollutionCertificate: "",
  permitCertificate: "",

  // Vehicle Driver Images
  driverPhoto: "",
  uploadLicence: "",
  aadharFront: "",
  aadharback: "",
  bannerIMG: "",

  // template
  templateImage: "",
  // Serial Number
  serialNumber: "",
  isGetSerialNumber: false,
  isRequestToGetSerialNumber: false,
  isFailedToGetSerialNumber: false,

  // Save Image
  isImageSaved: false,
  isRequestToSaveImage: false,
  isSaveImageFailed: false,

  // add logo

  companyLogo: "",
  profileImage: "",

  // upload Signature

  uploadSign: "",

  otherPickup: {
    city: [],
    state: [],
    locality: [],
  },
  otherDrop: {
    city: [],
    state: [],
    locality: [],
  },
};

export const UtilsSlice = createSlice({
  name: "Utils",
  initialState,
  reducers: {
    requestToGetPickLocation: (state, action) => {},
    responseToGetPickLocation: (state, action) => {
      const { city, locality } = action?.payload;
      return {
        ...state,
        pickup: {
          city,
          state: action?.payload?.state,
          locality,
        },
      };
    },
    failedToGetPickLocation: (state, action) => {},
    requestToGetDropLocation: (state, action) => {},
    responseToGetDropLocation: (state, action) => {
      const { city, locality } = action?.payload;
      return {
        ...state,
        drop: {
          city,
          state: action?.payload?.state,
          locality,
        },
      };
    },
    failedToGetDropLocation: (state, action) => {},

    requestToGetOtherPickLocation: (state, action) => {},
    responseToGetOtherPickLocation: (state, action) => {
      const { city, locality } = action?.payload;
      return {
        ...state,
        otherPickup: {
          city,
          state: action?.payload?.state,
          locality,
        },
      };
    },
    failedToGetOtherPickLocation: (state, action) => {},
    requestToGetOtherDropLocation: (state, action) => {},
    responseToGetOtherDropLocation: (state, action) => {
      const { city, locality } = action?.payload;
      return {
        ...state,
        otherDrop: {
          city,
          state: action?.payload?.state,
          locality,
        },
      };
    },
    failedToGetOtherDropLocation: (state, action) => {},

    utilsReset: (state, action) => {
      return {
        ...state,
        pickup: {
          ...state.pickup,
          city: [],
          state: [],
          locality: [],
        },
        drop: {
          ...state.drop,
          city: [],
          state: [],
          locality: [],
        },

        otherPickup: {
          ...state.otherPickup,
          city: [],
          state: [],
          locality: [],
        },
      };
    },
    utilsPickUpReset: (state, action) => {
      return {
        ...state,
        pickup: {
          ...state.pickup,
          city: [],
          state: [],
          locality: [],
        },
      };
    },
    utilsDropReset: (state, action) => {
      return {
        ...state,
        drop: {
          ...state.drop,
          city: [],
          state: [],
          locality: [],
        },
      };
    },
    requestToSaveImage: (state, action) => {
      return {
        ...state,
        isImageSaved: false,
        isRequestToSaveImage: true,
        isSaveImageFailed: false,
      };
    },

    responseToSaveImage: (state, action) => {
      const { type, imageResponse } = action?.payload || {};
      switch (type) {
        case "driverPhoto":
          return {
            ...state,
            driverPhoto: imageResponse?.Location,
            isImageSaved: true,
            isRequestToSaveImage: true,
            isSaveImageFailed: false,
          };
        case "licenseImage":
          return {
            ...state,
            uploadLicence: imageResponse?.Location,
            isImageSaved: true,
            isRequestToSaveImage: true,
            isSaveImageFailed: false,
          };
        case "aadharFront":
          return {
            ...state,
            aadharFront: imageResponse?.Location,
            isImageSaved: true,
            isRequestToSaveImage: true,
            isSaveImageFailed: false,
          };
        case "aadharBack":
          return {
            ...state,
            aadharback: imageResponse?.Location,
            isImageSaved: true,
            isRequestToSaveImage: true,
            isSaveImageFailed: false,
          };
        case "RcDoc":
          return {
            ...state,
            RcImage: imageResponse?.Location,
            isImageSaved: true,
            isRequestToSaveImage: true,
            isSaveImageFailed: false,
          };
        case "pollutionCertificate":
          return {
            ...state,
            pollutionCertificate: imageResponse?.Location,
            isImageSaved: true,
            isRequestToSaveImage: true,
            isSaveImageFailed: false,
          };
        case "permitCertificate":
          return {
            ...state,
            permitCertificate: imageResponse?.Location,
          };
        case "banner":
          return {
            ...state,
            bannerIMG: imageResponse?.Location,
            isImageSaved: true,
            isRequestToSaveImage: true,
            isSaveImageFailed: false,
          };
        case "templateImage":
          return {
            ...state,
            templateImage: imageResponse?.Location,
            isImageSaved: true,
            isRequestToSaveImage: true,
            isSaveImageFailed: false,
          };
        case "companyLogo":
          return {
            ...state,
            companyLogo: imageResponse?.Location,
            isImageSaved: true,
            isRequestToSaveImage: true,
            isSaveImageFailed: false,
          };
        case "uploadSignature":
          return {
            ...state,
            uploadSign: imageResponse?.Location,
            isImageSaved: true,
            isRequestToSaveImage: true,
            isSaveImageFailed: false,
          };
        default:
          return state;
      }
    },

    failedToSaveImage: (state, action) => {
      return {
        ...state,
        isImageSaved: false,
        isRequestToSaveImage: true,
        isSaveImageFailed: true,
      };
    },
    resetSaveImageApiStatus: (state, action) => {
      return {
        ...state,
        // uploadImage: "",
        isImageSaved: false,
        isRequestToSaveImage: false,
        isSaveImageFailed: false,
      };
    },

    resetSaveImage: (state, action) => {
      return {
        ...state,
        companyLogo: "",
        uploadSign: "",
        RcImage: "",
        pollutionCertificate: "",
        permitCertificate: "",
        driverPhoto: "",
        uploadLicence: "",
        aadharFront: "",
        aadharback: "",
        bannerIMG: "",
        templateImage: "",
        profileImage: "",

        isImageSaved: false,
        isRequestToSaveImage: false,
        isSaveImageFailed: false,
      };
    },

    responseToGetSerialNumber: (state, action) => {
      return {
        ...state,
        state: {
          ...state.state,
          serialNumber: action?.payload,
          isGetSerialNumber: true,
          isRequestToGetSerialNumber: true,
          isFailedToGetSerialNumber: false,
        },
      };
    },
    requestToGetSerialNumber: (state, action) => {
      return {
        ...state,
        state: {
          ...state.state,
          isGetSerialNumber: false,
          isRequestToGetSerialNumber: true,
          isFailedToGetSerialNumber: false,
        },
      };
    },
    failedToGetSerialNumber: (state, action) => {
      return {
        ...state,
        state: {
          ...state.state,
          isGetSerialNumber: false,
          isRequestToGetSerialNumber: true,
          isFailedToGetSerialNumber: true,
        },
      };
    },

    requestToGetConsigneePickupLocation: (state, action) => {},
    responseToGetConsigneePickupLocation: (state, action) => {
      const { city, locality } = action?.payload;
      return {
        ...state,
        consigneePickup: {
          city,
          state: action?.payload?.state,
          locality,
        },
      };
    },
    failedToGetConsigneePickupLocation: (state, action) => {},

    requestToGetConsigneeDropLocation: (state, action) => {},
    responseToGetConsigneeDropLocation: (state, action) => {
      const { city, locality } = action?.payload;
      return {
        ...state,
        consigneeDrop: {
          city,
          state: action?.payload?.state,
          locality,
        },
      };
    },
    failedToGetConsigneeDropLocation: (state, action) => {},
    updateProfileImage: (state, action) => {
      return {
        ...state,
        profileImage: action.payload,
      };
    },
  },
});
export default UtilsSlice.reducer;
export const {
  requestToGetPickLocation,
  failedToGetPickLocation,
  responseToGetPickLocation,
  failedToGetDropLocation,
  requestToGetDropLocation,
  requestToGetconsigneePickupLocation,
  responseToGetconsigneePickupLocation,
  failedToGetconsigneePickupLocation,
  requestToGetConsigneeDropLocation,
  responseToGetConsigneeDropLocation,
  failedToGetConsigneeDropLocation,
  utilsReset,
  responseToGetDropLocation,
  utilsPickUpReset,
  utilsDropReset,
  resetSaveImage,
  requestToSaveImage,
  responseToSaveImage,
  failedToSaveImage,
  resetSaveImageApiStatus,
  responseToGetSerialNumber,
  requestToGetSerialNumber,
  failedToGetSerialNumber,
  updateProfileImage,

  requestToGetOtherPickLocation,
  responseToGetOtherPickLocation,
  failedToGetOtherPickLocation,
  requestToGetOtherDropLocation,
  responseToGetOtherDropLocation,
  failedToGetOtherDropLocation,
} = UtilsSlice.actions;
