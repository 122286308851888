import { Grid, Paper } from "@mui/material";
import { Fragment, useMemo, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Form, Formik } from "formik";
import {
  FEATURE_LABEL,
  FEATURE_NAME_LABEL,
  SUBMIT_LABEL,
} from "../../../../../../languages/en";
import SubHeader from "../../../../../../common-components/page-elements/SubHeader";
import GenericInput from "../../../../../../common-components/form-elements/genericInput";
import GenericLoadingButton from "../../../../../../common-components/form-elements/genericLoadingButton";
import GenericDataGrid from "../../../../../../common-components/form-elements/genericDataGrid";

import {
  featureInitial,
  featureSchema,
} from "../../../../../../common-components/validator/feature-validation";
import {
  requestForAllFeature,
  requestForCreateFeature,
  requestForDeleteFeature,
  requestForUpdateFeature,
  reset,
  responseForCreateFeature,
} from "../../../../../../store/slices/FeatureSlice";
import { useNavigate } from "react-router-dom";
import GenericAction from "../../../../../../common-components/form-elements/genericAction";
import GenericSwitch from "../../../../../../common-components/form-elements/genericSwitch";

const AddFeature = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let {
    allFeatures,
    responseCreateFeature,
    responseDeleteFeature,
    responseUpdateFeature,
  } = useSelector((state) => state.feature);

  const columns = useMemo(() => {
    return [
      {
        field: "serialNo",
        headerName: "Serial No.",
        width: 90,
        renderCell: (data) => {
          return data.row.serialNo + 1;
        },
      },
      { field: "id", headerName: "ID.", width: 90 },
      {
        field: "featureName",
        headerName: "Feature Name",
        width: 150,
        editable: false,
      },
      {
        field: "isActive",
        headerName: "Status",
        width: 250,
        editable: false,
        renderCell: (data) => {
          return (
            <GenericSwitch
              start={"Active"}
              end={"Inactive"}
              value={data?.row?.isActive}
              onChange={() => onStatusChangeHandler(data?.row)}
            />
          );
        },
      },
      {
        field: "createdAt",
        headerName: "Created Date",
        width: 150,
        editable: false,
        renderCell: (data) => {
          return new Date(data?.row?.createdAt).toDateString();
        },
      },
      {
        field: "updatedAt",
        headerName: "Updated Date",
        width: 150,
        editable: false,
        renderCell: (data) => {
          return new Date(data?.row?.updatedAt).toDateString();
        },
      },
      {
        headerName: "Action",
        width: 150,
        field: "",
        editable: false,
        renderCell: (data) => {
          return (
            <GenericAction
              data={data?.row}
              onDeleteHandler={(data) => onDeleteHandler(data)}
              onEditHandler={(data) => onEditHandler(data)}
              deleteRequired={true}
              editRequired={true}
            />
          );
        },
      },
    ];
  }, []);

  const onSubmit = (values) => {
    dispatch(requestForCreateFeature(values));
    if (responseForCreateFeature) {
      dispatch(reset());
    }
  };
  useEffect(() => {
    dispatch(requestForAllFeature());
  }, [responseCreateFeature, responseDeleteFeature, responseUpdateFeature]);
  useEffect(() => {
    dispatch(requestForAllFeature());
  }, []);

  const onDeleteHandler = (data) => {
    dispatch(requestForDeleteFeature(data?.id));
    if (responseDeleteFeature) {
      dispatch(reset());
    }
  };
  const onStatusChangeHandler = (row) => {
    dispatch(requestForUpdateFeature({ ...row, isActive: !row.isActive }));
    if (responseUpdateFeature) {
      dispatch(reset());
    }
  };
  const onEditHandler = (data) => {
    navigate(`./${data?.id}`);
  };

  return (
    <Fragment>
      <SubHeader title={FEATURE_LABEL} />
      <Paper sx={{ padding: "30px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Formik
              initialValues={featureInitial}
              validationSchema={featureSchema}
              onSubmit={onSubmit}
            >
              {({ handleChange, handleBlur, isValid, errors }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <GenericInput
                        onChange={handleChange}
                        label={FEATURE_NAME_LABEL}
                        onBlur={handleBlur}
                        name="featureName"
                        id="featureName"
                      />
                      <ErrorMessage
                        name={"featureName"}
                        component="p"
                        className="error"
                      ></ErrorMessage>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <GenericLoadingButton disabled={!isValid} type="submit">
                        {SUBMIT_LABEL}
                      </GenericLoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
          <Grid item xs={12}>
            <GenericDataGrid
              columns={columns}
              rows={allFeatures?.map((row, index) => {
                return {
                  serialNo: index++,
                  ...row,
                };
              })}
            />
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};
export default AddFeature;
