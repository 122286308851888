import Randexp from "randexp";
export const generateRandomColors = () => {
  return `#${new Randexp(/^([a-f]){6}$/).gen()}`;
};
export const getAllUnique = (array) => {
  const uniqueObjects = new Set();
  const result = [];

  for (const item of array) {
    const objectString = JSON.stringify(item);

    if (!uniqueObjects.has(objectString)) {
      uniqueObjects.add(objectString);
      result.push(item);
    }
  }

  return result;
};

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};
export const deleteCookie = (cname) => {
  const d = new Date();
  d.setTime(d.getTime() - 7);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=;" + expires + ";path=/";
};
export const getNextRenewableDate = (days = "", months = "", years = "") => {
  let currentDate = new Date();

  if (months !== "" && months !== 0)
    currentDate.setMonth(currentDate.getMonth() + +months);
  else if (days !== "" && days !== 0)
    currentDate.setDate(currentDate.getDate() + +days);
  else if (years !== "" && years !== 0)
    currentDate.setFullYear(currentDate.getFullYear() + years);
  return new Date(currentDate).toString();
};
export const groupByField = (array, fieldName) => {
  return array.reduce((result, obj) => {
    const key = obj[fieldName];

    if (!result[key]) {
      result[key] = [];
    }
    result[key].push({ ...obj, field: key });

    return result;
  }, {});
};

export const valueMapper = (templateArr, subModuleArr) => {
  let arr = [];
  subModuleArr?.filter((item) => {
    let template = templateArr.filter((ele) => {
      if (ele?._id === item?._id) {
        let obj = { ...ele, item };
        arr.push(obj);
      }
    });
    return template;
  });
  return arr;
};

export const groupByModuleID = (data) => {
  const modules = {};
  const subModules = {};
  const features = {};
  data.forEach((item) => {
    const { Module, SubModule, Feature } = item;

    if (!modules[Module.id]) {
      modules[Module.id] = {
        title: Module.moduleName,
        key: `module_${Module.id}`,
        children: [],
      };
    }

    if (!subModules[SubModule.id]) {
      subModules[SubModule.id] = {
        title: SubModule.moduleName,
        key: `subModule_${SubModule.id}_${Module.id}`,
        children: [],
      };
      modules[Module.id].children.push(subModules[SubModule.id]);
    }

    features[Feature.id] = {
      title: Feature.featureName,
      key: `feature_${Feature.id}_${SubModule.id}_${Module.id}`,
      isLeaf: true,
    };
    subModules[SubModule.id].children.push(features[Feature.id]);
  });

  return Object.values(modules);
};
export const updateTreeVisibility = (inputData, tree) => {

  // Helper function to set visibility for matching labels
  function setVisibility(tree, label, visible) {
    for (const item of tree) {
      if (item.label === label) {
        item.visible = visible;
      }

      if (item.nestedItems && item.nestedItems.length > 0) {
        setVisibility(item.nestedItems, label, visible);
      }
    }
  }

  // Set visibility for "Dashboard"
  setVisibility(tree, "Dashboard", true);

  // Create a map to easily lookup visibility based on inputData
  const visibilityMap = {};
  inputData?.forEach(item => {
    visibilityMap[item.parentModuleName] = true;
    visibilityMap[item.moduleName] = true;
    visibilityMap[item.featureName] = true;
  });

  // Helper function to update visibility based on visibilityMap
  function updateVisibilityBasedOnData(tree) {
    for (const item of tree) {
      if (visibilityMap[item.label]) {
        item.visible = true;
      } else {
        item.visible = false;
      }
      if (item.nestedItems && item.nestedItems.length > 0) {
        updateVisibilityBasedOnData(item.nestedItems);
      }
    }
  }

  // Update visibility for the entire tree
  updateVisibilityBasedOnData(tree);

  return tree;
}
export const getAuthFeatureList = (authDetails) => {
  if (authDetails?.employee?.permissions) {
    return authDetails?.employee?.permissions
  } else if ((authDetails?.role?.companies?.length)) {
    return authDetails?.role?.companies[0].purchase?.plan?.planFeatures
  }
  else if (authDetails?.permissions?.length) {
    return authDetails?.permissions
  }
  else {
    return []
  }
}
export const getCurrentCompanyDetails = (authDetails) => {
  const { role } = authDetails;
  return (role.companies)
}