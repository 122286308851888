import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  allModules: [],
  allSubModules: [],
  requestAllModule: false,
  requestCreateModule: false,
  requestDeleteModule: false,
  requestUpdateModule: false,
  failedAllModule: false,
  responseAllModule: false,
  responseCreateModule: false,
  responseDeleteModule: false,
  responseUpdateModule: false,
  failedCreateModule: false,
  failedDeleteModule: false,
  failedUpdateModule: false,
  requestAllSubModule: false,
  failedAllSubModule: false,
  responseAllSubModule: false,
};
export const ModuleSlice = createSlice({
  name: "module",
  initialState,
  reducers: {
    reset: (state) => {
      state.requestAllModule = false;
      state.failedAllModule = false;
      state.responseAllModule = false;
      state.requestCreateModule = false;
      state.responseCreateModule = false;
      state.failedCreateModule = false;
      state.requestUpdateModule = false;
      state.responseUpdateModule = false;
      state.failedUpdateModule = false;
      state.requestDeleteModule = false;
      state.responseDeleteModule = false;
      state.failedDeleteModule = false;
      state.requestAllSubModule = false;
      state.failedAllSubModule = false;
      state.responseAllSubModule = false;
    },
    requestForAllSubModule: (state) => {
      state.requestAllSubModule = true;
    },
    responseForAllSubModule: (state, action) => {
      state.allSubModules = action?.payload;
      state.responseAllSubModule = true;
    },
    failedForAllSubModule: (state) => {
      state.failedAllSubModule = true;
    },
    requestForAllModule: (state) => {
      state.requestAllModule = true;
    },
    responseForAllModule: (state, action) => {
      state.allModules = action?.payload;
      state.responseAllModule = true;
    },
    failedForAllModule: (state) => {
      state.failedAllModule = true;
    },
    requestForCreateModule: (state) => {
      state.requestCreateModule = true;
    },
    responseForCreateModule: (state) => {
      state.responseCreateModule = true;
    },
    failedForCreateModule: (state) => {
      state.failedCreateModule = true;
    },
    requestForDeleteModule: (state) => {
      state.requestDeleteModule = true;
    },
    responseForDeleteModule: (state) => {
      state.responseDeleteModule = true;
    },
    failedForDeleteModule: (state) => {
      state.failedDeleteModule = true;
    },
    requestForUpdateModule: (state) => {
      state.requestUpdateModule = true;
    },
    responseForUpdateModule: (state) => {
      state.responseUpdateModule = true;
    },
    failedForUpdateModule: (state) => {
      state.failedUpdateModule = true;
    },
  },
});
export default ModuleSlice.reducer;
export const {
  reset,
  requestForAllModule,
  failedForAllModule,
  responseForAllModule,
  failedForCreateModule,
  requestForCreateModule,
  responseForCreateModule,
  failedForDeleteModule,
  requestForDeleteModule,
  responseForDeleteModule,
  failedForUpdateModule,
  requestForUpdateModule,
  responseForUpdateModule,
  failedForAllSubModule,
  requestForAllSubModule,
  responseForAllSubModule,
} = ModuleSlice.actions;
