import { Route, Routes } from "react-router-dom";
import AddPlan from "./add";
import UpdatePlan from "./update";



const PlansRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={<AddPlan />} />
        <Route path="/:id" element={<UpdatePlan />} />
        
        
      </Routes>
    );
  };
  export default PlansRoutes;