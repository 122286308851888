import * as Yup from "yup";
import { NAME_WITH_CHARACTERS_REGEX } from "../../../utils/regular-expressions";
export const roleInitial = {
  roleName: "",
};

export const roleSchema = Yup.object().shape({
  roleName: Yup.string("Please Enter Role Name!")
    .required("Role Name Is Required!")
    .matches(NAME_WITH_CHARACTERS_REGEX, "Please Enter Valid Role Name!"),
});
