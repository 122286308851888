import { put, takeLatest } from "redux-saga/effects";
import {
  authDetailsByIDService,
  loginService,
  registerEmployeeService,
  verifyOTPService,
} from "../../../services/auth";
import {
  failedForVerifyOTP,
  failedToGetAuthDetails,
  failedToLogin,
  failedToRegisterEmployee,
  requestForGetAuthDetails,
  requestForLogin,
  requestForRegisterEmployee,
  requestForVerifyOTP,
  responseForGetAuthDetails,
  responseForLogin,
  responseForRegisterEmployee,
  responseForVerifyOTP
} from "../../slices/AuthSlice";

function* loginSaga(action) {
  try {
    const response = yield loginService(action.payload);
    const { code } = response;
    
    if (code === 200) {
      yield put(responseForLogin(response?.data));
    } else {
      yield put(failedToLogin(response));
    }
  } catch (error) {
    yield put(failedToLogin(error));
  }
}
function* verifyOTPSaga(action) {
  try {
    const response = yield verifyOTPService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForVerifyOTP(response?.data));
    } else {
      yield put(failedForVerifyOTP(response));
    }
  } catch (error) {
    yield put(failedForVerifyOTP(error));
  }
}
function* registerEmployeeSaga(action) {
  try {
    const response = yield registerEmployeeService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForRegisterEmployee(response));
    } else {
      yield put(failedToRegisterEmployee(response));
    }
  } catch (error) {
    yield put(failedToRegisterEmployee(error));
  }
}
function* getAuthDetailsSaga(action) {
  try {
    const response = yield authDetailsByIDService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForGetAuthDetails(response?.data));
    } else {
      yield put(failedToGetAuthDetails(response));
    }
  } catch (error) {
    yield put(failedToGetAuthDetails(error));
  }
}

export function* watcherGetAuthDetailsSaga() {
  yield takeLatest(requestForGetAuthDetails.type, getAuthDetailsSaga);
}
export function* watcherLoginSaga() {
  yield takeLatest(requestForLogin.type, loginSaga);
}
export function* watcherVerifyOTPSaga() {
  yield takeLatest(requestForVerifyOTP.type, verifyOTPSaga);
}
export function* watcherRegisterEmployeeSaga() {
  yield takeLatest(requestForRegisterEmployee.type, registerEmployeeSaga);
}
