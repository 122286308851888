import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "underscore";
import { AuthContext } from "../context/AuthContext";
import { getAuthState } from "../store/selectors";
import {
  requestForGetAuthDetails,
  requestToLogOut,
  resetAuth,
} from "../store/slices/AuthSlice";
import { deleteCookie, setCookie } from "../utils";

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [moveToRegister, setMoveToRegister] = useState(false);
  const auth = useSelector(getAuthState);

  const { loginResponse, responseToGetAuthDetails, authDetails } = auth;

  const signin = useCallback(async () => {
    if (!_.isUndefined(loginResponse?.token)) {
      setCookie(btoa("epackbook.token"), btoa(loginResponse?.token), 7);
      const authID = loginResponse?.id;
      dispatch(requestForGetAuthDetails(authID));
    }
  }, []);
  useEffect(() => {
    
    if (
      _?.isArray(authDetails?.role?.companies) &&
      _?.isEmpty(authDetails?.role?.companies) &&
      responseToGetAuthDetails
    ) {
      setMoveToRegister(true);
    } else {
      setMoveToRegister(false);
      if (
        (_.isArray(authDetails?.role?.companies) ||
          _.isObject(authDetails?.role?.companies)) &&
        !_.isUndefined(authDetails?.role?.companies) &&
        !_.isNull(authDetails?.role?.companies) &&
        responseToGetAuthDetails
      ) {
        setIsAuthenticated(true);
      } else if (
        _.isArray(authDetails?.permissions) &&
        !_.isUndefined(authDetails?.permissions)
      ) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    }
  }, [authDetails?.role?.companies, responseToGetAuthDetails]);
  useEffect(() => {
    signin();
  }, [signin]);
  const signout = () => {
    dispatch(resetAuth());
    dispatch(requestToLogOut());
    deleteCookie(btoa("epackbook.token"));
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, signin, signout, moveToRegister }}
    >
      {children}
    </AuthContext.Provider>
  );
};
