import { Fragment, useEffect,  useState } from 'react'
import SubHeader from '../../../../../../common-components/page-elements/SubHeader'
import {  Grid, Paper, Typography } from '@mui/material'
import GenericDropdown from '../../../../../../common-components/form-elements/genericDropdown'
import GenericInput from '../../../../../../common-components/form-elements/genericInput'
import GenericLoadingButton from '../../../../../../common-components/form-elements/genericLoadingButton'

import {
  SUBMIT_LABEL,
  
  UPDATE_PLAN_LABEL
} from '../../../../../../languages/en'
import GenericTreeMap from '../../../../../../common-components/page-elements/genericTreeMap'
import { useDispatch, useSelector } from 'react-redux'
import {  useParams } from 'react-router-dom'
import {  useFormik } from 'formik'
import { groupByModuleID } from '../../../../../../utils'


import {
  planInitial,
  planSchema
} from '../../../../../../common-components/validator/plan-validation'
import _ from 'underscore'
import { requestForUpdatePlan } from '../../../../../../store/slices/PlanSlice'

const UpdatePlan = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  let { allModuleFeatureMappers } = useSelector(
    state => state.moduleFeatureMapper
  )
  const { allPlans } = useSelector(
    state => state.plan
  )
  const [treeData, setTreeData]  = useState([])
  const [planFeatures, setPlanFeatures]  = useState([])
  const [planFeatureQTY, setPlanFeatureQTY] = useState({})


  const onSelect = selectedKeys => {
    
  }
  const onCheck = checkedKeys => {
    
      setPlanFeatures(checkedKeys)
                          }
                          

  const updateClick = () => {
    const { values, errors } = formik
    
    if (!Object.keys(errors).length) {
      dispatch(requestForUpdatePlan({ ...values,planFeatures, id }));
    }
  }

  const formik = useFormik({
    initialValues: planInitial,
    validationSchema: planSchema
  })
  useEffect(() => {
    setTreeData(groupByModuleID(allModuleFeatureMappers));
  }, [allModuleFeatureMappers])

  useEffect(() => {
    const res = allPlans?.find(
      (plan) => plan.id === +id,
    );

    formik.setFieldValue("planName", res?.planName);
    formik.setFieldValue("planMRP", res?.planMRP);
    formik.setFieldValue("planDuration", res?.planDuration);
    formik.setFieldValue("planOfferPrice", res?.planOfferPrice);
    setPlanFeatures(JSON.parse(res?.planFeatures));
    setPlanFeatureQTY(res?.planFeatureQTY)
  }, [allPlans]);

  return (
    <Fragment>
      <SubHeader title={UPDATE_PLAN_LABEL} />
      <Paper sx={{ padding: '30px' }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={3} xs={12}>
              <GenericInput
                label='Plan Name'
                name='planName'
                id='planName'
                value={formik.values.planName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.planName && formik.touched.planName ? (
                <p name={'planName'} className='error'>
                  {formik.errors.planName}
                </p>
              ) : null}
            </Grid>
            <Grid item md={3} xs={12}>
              <GenericDropdown
                label='Plan Duration'
                name='planDuration'
                id='planDuration'
                value={formik.values.planDuration}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                data={[
                  {
                    value: 'Monthly',
                    label: 'Monthly'
                  },
                  {
                    value: 'Half Yearly',
                    label: 'Half Yearly'
                  },
                  {
                    value: 'Yearly',
                    label: 'Yearly'
                  }
                ]}
              />
              
              {formik.errors.planDuration && formik.touched.planDuration ? (
                <p name={'planDuration'} className='error'>
                  {formik.errors.planDuration}
                </p>
              ) : null}
            </Grid>

            <Grid item md={3} xs={12}>
              <GenericInput
                name='planMRP'
                id='planMRP'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.planMRP}
                label='Plan MRP'
              />
              {formik.errors.planMRP && formik.touched.planMRP ? (
                <p name={'planMRP'} className='error'>
                  {formik.errors.planMRP}
                </p>
              ) : null}
            </Grid>
            <Grid item md={3} xs={12}>
              <GenericInput
                name='planOfferPrice'
                id='planOfferPrice'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label='Plan Offer Price'
                value={formik.values.planOfferPrice}
              />
              {formik.errors.planOfferPrice && formik.touched.planOfferPrice ? (
                <p name={'planOfferPrice'} className='error'>
                  {formik.errors.planOfferPrice}
                </p>
              ) : null}
            </Grid>

            
            <Grid item xs={12} sx={{ my: 2 }}>
              <Typography variant='h6' sx={{ mb: 2 }}>
                Plan Features
              </Typography>

               <GenericTreeMap
                defaultCheckedKeys={[...planFeatures]}
                defaultExpandedKeys={planFeatures}
                checkable={true}
                isInputRequired={true}
                data={treeData}
                onSelect={(selected) => {
                  onSelect(selected);
                }}
                onCheck={(selected) => {
                  onCheck(selected);
                }}
                // getInputValues={()=>console.log()}
                id={"planFeatures"}
                name={"planFeatures"}
              /> 

              
            </Grid>

            <Grid item md={12} xs={12}>
              <GenericLoadingButton
                onClick={updateClick}
                disabled={ !formik.isValid}
                sx={{ my: 2, float: 'right' }}
                type='submit'
              >
                {SUBMIT_LABEL}
              </GenericLoadingButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Fragment>
  )
}
export default UpdatePlan
