import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { SUBMODULE_NAVIGATION_ROUTES } from "../../../../common-components/constants";
import { getAuthState } from "../../../../store/selectors";
import { getAuthFeatureList } from "../../../../utils";

const ContentManagementRoutes = () => {
  const auth = useSelector(getAuthState);
  const { authDetails } = auth;
  const featureList = getAuthFeatureList(authDetails);
  let modules = featureList?.map((ele) =>
    ele?.moduleName !== null ? ele.moduleName : ""
  );
  modules = new Set(modules);
  const modulesArray = Array.from(modules)?.map((it) =>
    it.split(" ").join("-").toLowerCase()
  );

  return (
    <Routes>
      {SUBMODULE_NAVIGATION_ROUTES?.map((subModule, key) => {
        return modulesArray?.map((module, index) => {
          const regax = new RegExp(subModule?.path);
          if (regax.test(module)) {
            return (
              <Route
                path={`/${subModule?.path}/*`}
                element={subModule?.element}
                key={`${key}_${index}`}
              />
            );
          } 
        });
      })}
    </Routes>
  );
};

export default ContentManagementRoutes;
