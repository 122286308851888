import { put, takeLatest } from "redux-saga/effects";
import {
  getAllFeatureService,
  deleteFeatureService,
  updateFeatureService,
  createFeatureService,
} from "../../../services/feature";
import {
  failedForAllFeature,
  failedForCreateFeature,
  failedForDeleteFeature,
  failedForUpdateFeature,
  requestForAllFeature,
  requestForCreateFeature,
  requestForDeleteFeature,
  requestForUpdateFeature,
  responseForAllFeature,
  responseForCreateFeature,
  responseForDeleteFeature,
  responseForUpdateFeature,
} from "../../slices/FeatureSlice";

function* getAllFeatureSaga(action) {
  try {
    const response = yield getAllFeatureService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForAllFeature(response?.data));
    } else {
      yield put(failedForAllFeature(response));
    }
  } catch (error) {
    yield put(failedForAllFeature(error));
  }
}

function* createFeatureSaga(action) {
  try {
    const response = yield createFeatureService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForCreateFeature());
    } else {
      yield put(failedForCreateFeature(response));
    }
  } catch (error) {
    yield put(failedForCreateFeature(error));
  }
}

function* deleteFeatureSaga(action) {
  try {
    const response = yield deleteFeatureService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForDeleteFeature(response));
    } else {
      yield put(failedForDeleteFeature(response));
    }
  } catch (error) {
    yield put(failedForDeleteFeature(error));
  }
}
function* updateFeatureSaga(action) {
  try {
    const response = yield updateFeatureService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForUpdateFeature(response));
    } else {
      yield put(failedForUpdateFeature(response));
    }
  } catch (error) {
    yield put(failedForUpdateFeature(error));
  }
}

export function* watcherCreateFeatureSaga() {
  yield takeLatest(requestForCreateFeature.type, createFeatureSaga);
}
export function* watcherAllFeatureSaga() {
  yield takeLatest(requestForAllFeature.type, getAllFeatureSaga);
}

export function* watcherDeleteFeatureSaga() {
  yield takeLatest(requestForDeleteFeature.type, deleteFeatureSaga);
}
export function* watcherUpdateFeatureSaga() {
  yield takeLatest(requestForUpdateFeature.type, updateFeatureSaga);
}
