import { Grid, Paper } from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import { Fragment, useMemo } from "react";
import GenericAction from "../../../../../../common-components/form-elements/genericAction";
import GenericDataGrid from "../../../../../../common-components/form-elements/genericDataGrid";
import GenericInput from "../../../../../../common-components/form-elements/genericInput";
import GenericLoadingButton from "../../../../../../common-components/form-elements/genericLoadingButton";
import GenericSwitch from "../../../../../../common-components/form-elements/genericSwitch";
import SubHeader from "../../../../../../common-components/page-elements/SubHeader";
import { ADD_BANK_LABEL, BANK_LABEL, SUBMIT_LABEL } from "../../../../../../languages/en";
import { useNavigate } from "react-router-dom";
import { bankInitial, bankSchema } from "../../../../../../common-components/validator/bank-validation";
const AddBanks = () => {
  const navigate = useNavigate();
  const onDeleteHandler = (data) => {
    // dispatch(requestForDeleteFeature(data?.id));
    // if (responseDeleteFeature) {
    //   dispatch(reset());
    // }
  };
  const onStatusChangeHandler = (row) => {
    // dispatch(requestForUpdateFeature({ ...row, isActive: !row.isActive }));
    // if (responseUpdateFeature) {
    //   dispatch(reset());
    // }
  };
  const onEditHandler = (data) => {
    navigate(`./${data?.id}`);
  };
  const columns = useMemo(() => {
    return [
      {
        field: "serialNo",
        headerName: "Serial No.",

        renderCell: (data) => {
          return data.row.serialNo + 1;
        },
      },
      { field: "id", headerName: "ID." },

      {
        field: "bankName",
        headerName: "Bank Name",
        width: 150,

        editable: false,
      },
      {
        field: "isActive",
        headerName: "Status",
        width: 250,
        editable: false,
        renderCell: (data) => {
          return (
            <GenericSwitch
              start={"Active"}
              end={"Inactive"}
              value={data?.row?.isActive}
              onChange={() => onStatusChangeHandler(data?.row)}
            />
          );
        },
      },
      {
        field: "createdAt",
        headerName: "Created Date",
        width: 150,
        editable: false,
        renderCell: (data) => {
          return new Date(data?.row?.createdAt).toDateString();
        },
      },
      {
        field: "updatedAt",
        headerName: "Updated Date",
        width: 150,
        editable: false,
        renderCell: (data) => {
          return new Date(data?.row?.updatedAt).toDateString();
        },
      },
      {
        headerName: "Action",
        width: 150,
        field: "",
        editable: false,
        renderCell: (data) => {
          return (
            <GenericAction
              data={data?.row}
              onDeleteHandler={(data) => onDeleteHandler(data)}
              onEditHandler={(data) => onEditHandler(data)}
              deleteRequired={true}
              editRequired={true}
            />
          );
        },
      },
    ];
  }, []);
  const onSubmit = () => {};
  return (
    <Fragment>
      <SubHeader title={BANK_LABEL} />
      <Paper sx={{ padding: "30px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Formik onSubmit={onSubmit} initialValues={bankInitial} validationSchema={bankSchema}>
              {({ handleChange, handleBlur, isValid }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={6}>
                      <GenericInput
                        label={ADD_BANK_LABEL}
                        name="bank"
                        id="bank"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name={"bank"}
                        component="p"
                        className="error"
                      ></ErrorMessage>
                    </Grid>

                    <Grid item xs={6} md={4}>
                      <GenericLoadingButton
                        disabled={!isValid}
                        type="submit"
                        sx={{ float: "left" }}
                      >
                        {SUBMIT_LABEL}
                      </GenericLoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <GenericDataGrid
                  getRowHeight={() => "auto"}
                  rows={[]?.map((row, index) => {
                    return {
                      serialNo: index++,
                      ...row,
                    };
                  })}
                  columns={columns}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};
export default AddBanks;
