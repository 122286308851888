import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  allFeatures: [],
  requestAllFeature: false,
  requestCreateFeature: false,
  requestDeleteFeature: false,
  requestUpdateFeature: false,
  failedAllFeature: false,
  responseAllFeature: false,
  responseCreateFeature: false,
  responseDeleteFeature: false,
  responseUpdateFeature: false,
  failedCreateFeature: false,
  failedDeleteFeature: false,
  failedUpdateFeature: false,
};
export const FeatureSlice = createSlice({
  name: "feature",
  initialState,
  reducers: {
    reset: (state) => {
      state.requestAllFeature = false;
      state.failedAllFeature = false;
      state.responseAllFeature = false;
      state.requestCreateFeature = false;
      state.responseCreateFeature = false;
      state.failedCreateFeature = false;
      state.requestUpdateFeature = false;
      state.responseUpdateFeature = false;
      state.failedUpdateFeature = false;
      state.requestDeleteFeature = false;
      state.responseDeleteFeature = false;
      state.failedDeleteFeature = false;
    },
    requestForAllFeature: (state) => {
      state.requestAllFeature = true;
    },
    responseForAllFeature: (state, action) => {
      state.allFeatures = action?.payload;
      state.responseAllFeature = true;
    },
    failedForAllFeature: (state) => {
      state.failedAllFeature = true;
    },
    requestForCreateFeature: (state) => {
      state.requestCreateFeature = true;
    },
    responseForCreateFeature: (state) => {
      state.responseCreateFeature = true;
    },
    failedForCreateFeature: (state) => {
      state.failedCreateFeature = true;
    },
    requestForDeleteFeature: (state) => {
      state.requestDeleteFeature = true;
    },
    responseForDeleteFeature: (state) => {
      state.responseDeleteFeature = true;
    },
    failedForDeleteFeature: (state) => {
      state.failedDeleteFeature = true;
    },
    requestForUpdateFeature: (state) => {
      state.requestUpdateFeature = true;
    },
    responseForUpdateFeature: (state) => {
      state.responseUpdateFeature = true;
    },
    failedForUpdateFeature: (state) => {
      state.failedUpdateFeature = true;
    },
  },
});
export default FeatureSlice.reducer;
export const {
  reset,
  requestForAllFeature,
  failedForAllFeature,
  responseForAllFeature,
  failedForCreateFeature,
  requestForCreateFeature,
  responseForCreateFeature,
  failedForDeleteFeature,
  requestForDeleteFeature,
  responseForDeleteFeature,
  failedForUpdateFeature,
  requestForUpdateFeature,
  responseForUpdateFeature,
} = FeatureSlice.actions;
