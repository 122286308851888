import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { PARENT_MODULE_NAVIGATION_ROUTES } from "../../common-components/constants";
import DashboardLayout from "../../common-components/page-elements/dashboard-layout";
import {
  defaultTheme,
  gracefulTheme,
  primaryTheme,
  secondaryTheme,
} from "../../common-components/themes";
import { useAuth } from "../../hooks/useAuth";
import { getAuthState } from "../../store/selectors";
import { getAuthFeatureList } from "../../utils";
import Index from "./dashboard/index";

const ERPRouting = () => {
  const theme = useSelector((state) => state.theme);
  const { currentTheme } = theme;
  const [selectedTheme, setSelectedTheme] = useState(primaryTheme);
  const { isAuthenticated } = useAuth();
  const auth = useSelector(getAuthState);
  const { authDetails } = auth;
  const featureList = getAuthFeatureList(authDetails);

  let parentModules = featureList?.map((ele) =>
    ele?.parentModuleName !== null ? ele.parentModuleName : ""
  );
  parentModules = new Set(parentModules);

  const parentModulesArray = Array.from(parentModules)?.map((it) =>
    it.split(" ").join("-").toLowerCase()
  );

  useEffect(() => {
    setSelectedTheme(
      currentTheme === "default"
        ? defaultTheme
        : currentTheme === "primary"
          ? primaryTheme
          : currentTheme === "graceful"
            ? gracefulTheme
            : secondaryTheme
    );
  }, [currentTheme]);

  return (
    <Fragment>
      {/* {isAuthenticated ? (
        
      ) : (
        <Navigate to="/" />
      )} */}
      <ThemeProvider theme={selectedTheme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DashboardLayout>
            <Routes>
              <Route path="/" element={<Index />} />

              {PARENT_MODULE_NAVIGATION_ROUTES?.map((parent, key) => {
                return parentModulesArray.map((module, index) => {
                  
                  if (parent?.path?.indexOf(module) > -1) {
                    return (
                      <Route
                        path={`/${parent?.path}/*`}
                        element={parent?.element}
                        key={`${key}_${index}`}
                      />
                    );
                  } else {
                  }
                });
              })}
            </Routes>
          </DashboardLayout>
        </LocalizationProvider>
      </ThemeProvider>
    </Fragment>
  );
};
export default ERPRouting;
