import {
  Box,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import GenericDivider from "../../../../../../common-components/form-elements/genericDivider";
import GenericDropdown from "../../../../../../common-components/form-elements/genericDropdown";
import GenericInput from "../../../../../../common-components/form-elements/genericInput";
import GenericLoadingButton from "../../../../../../common-components/form-elements/genericLoadingButton";
import SubHeader from "../../../../../../common-components/page-elements/SubHeader";
import {
  companyAddressDetailsInitial,
  companyAddressDetailsSchema,
  companyBasicDetailsInitial,
  companyBasicDetailsSchema,
} from "../../../../../../common-components/validator/profile-validation";
import {
  ADDRESS_DETAILS_LABEL,
  ADDRESS_LABEL,
  BASIC_DETAILS_LABEL,
  CITY_LABEL,
  COMPANY_EMAIL_LABEL,
  COMPANY_NAME_LABEL,
  COMPANY_WEBSITE_LABEL,
  CONTACT_PERSON_LABEL,
  DISTRICT_LABEL,
  LANDMARK_LABEL,
  LOCALITY_LABEL,
  PINCODE_LABEL,
  POSI_OFFICE_LABEL,
  PROFILE_LABEL,
  PUNCH_LINE_LABEL,
  REG_MOBILE_NUMBER_LABEL,
  REGISTRATION_DETAILS_LABEL,
  SIGNATURE_DETAILS_LABEL,
  STATE_LABEL,
  UPDATE_DETAILS_LABEL,
  UPDATE_LABEL,
} from "../../../../../../languages/en";
import { getCompanyState } from "../../../../../../store/selectors";
import {
  requestForCurrentCompany,
  requestForUpdateCompany,
} from "../../../../../../store/slices/CompanySlice";

const CompanyProfile = () => {
  const [editable, setEditable] = useState({
    editCompanyBasicDetails: true,
    editCompanyAddressDetails: true,
    editCompanyRegistrationDetails: true,
    editCompanySignatureDetails: true,
  });
  const company = useSelector(getCompanyState);
  const { currentCompany } = company;
  const { id: companyID } = currentCompany;
  const [basicDetailsInitial, setBasicDetailsInitial] = useState(
    companyBasicDetailsInitial
  );
  const [addressDetailsInitial, setAddressDetailsInitial] = useState(
    companyAddressDetailsInitial
  );

  useEffect(() => {
    setBasicDetailsInitial({
      companyName: currentCompany?.companyName || "",
      contactPerson: currentCompany?.contactPerson || "",
      punchLine: currentCompany?.punchLine || "",
      companyMobile: currentCompany?.companyMobile || "",
      email: currentCompany?.email || "",
      companyWebsite: currentCompany?.companyWebsite || "",
    });
    setAddressDetailsInitial({
      companyAddress: currentCompany?.companyAddress || "",
      city: currentCompany?.city || "",
      state: currentCompany?.state || "",
      locality: currentCompany?.locality || "",
      pincode: currentCompany?.pincode || "",
      landmark: currentCompany?.landmark || "",
      district: currentCompany?.district || "",
      postOffice: currentCompany?.postOffice || "",
    });
  }, []);
  const dispatch = useDispatch();
  const onBasicDetailsSubmit = (values) => {
    dispatch(requestForUpdateCompany({ id: companyID, ...values }));
    // dispatch(requestForCurrentCompany(companyID));
  };
  const onAddressDetailsSubmit = (values) => {
    dispatch(requestForUpdateCompany({ id: companyID, ...values }));
    // dispatch(requestForCurrentCompany(companyID));
  };
  useEffect(() => {
    dispatch(requestForCurrentCompany(companyID));
  }, [company, dispatch]);

  return (
    <Fragment>
      <Box>
        <SubHeader title={PROFILE_LABEL} />
      </Box>
      <Paper sx={{ padding: "30px" }}>
        <Formik
          initialValues={basicDetailsInitial}
          enableReinitialize={true}
          onSubmit={onBasicDetailsSubmit}
          validationSchema={companyBasicDetailsSchema}
        >
          {({ handleChange, handleBlur, isValid, values }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container sx={{ justifyContent: "space-between" }}>
                    <Typography variant="h6" fontWeight={600}>
                      {BASIC_DETAILS_LABEL}
                    </Typography>
                    <Tooltip title={UPDATE_DETAILS_LABEL}>
                      <IconButton
                        onClick={() =>
                          setEditable({
                            ...editable,
                            editCompanyBasicDetails:
                              !editable?.editCompanyBasicDetails,
                          })
                        }
                      >
                        <MdEdit size={20} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
                <GenericDivider />
                <Grid item xs={12} md={2}>
                  <Typography variant="body2" gutterBottom fontWeight={600}>
                    {COMPANY_NAME_LABEL}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericInput
                    className={
                      editable?.editCompanyBasicDetails ? "editable-input" : ""
                    }
                    inputProps={{
                      disabled: editable?.editCompanyBasicDetails,
                    }}
                    id="companyName"
                    name="companyName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.companyName}
                  />
                  <ErrorMessage
                    name={"companyName"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant="body2" gutterBottom fontWeight={600}>
                    {CONTACT_PERSON_LABEL}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericInput
                    className={
                      editable?.editCompanyBasicDetails ? "editable-input" : ""
                    }
                    inputProps={{
                      disabled: editable?.editCompanyBasicDetails,
                    }}
                    id="contactPerson"
                    name="contactPerson"
                    value={values.contactPerson}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name={"contactPerson"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>

                <Grid item xs={12} md={2}>
                  <Typography variant="body2" gutterBottom fontWeight={600}>
                    {REG_MOBILE_NUMBER_LABEL}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericInput
                    className={
                      editable?.editCompanyBasicDetails ? "editable-input" : ""
                    }
                    inputProps={{
                      disabled: editable?.editCompanyBasicDetails,
                    }}
                    id="companyMobile"
                    name="companyMobile"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.companyMobile}
                  />
                  <ErrorMessage
                    name={"companyMobile"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>

                <Grid item xs={12} md={2}>
                  <Typography variant="body2" gutterBottom fontWeight={600}>
                    {COMPANY_EMAIL_LABEL}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericInput
                    className={
                      editable?.editCompanyBasicDetails ? "editable-input" : ""
                    }
                    inputProps={{
                      disabled: editable?.editCompanyBasicDetails,
                    }}
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name={"email"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant="body2" gutterBottom fontWeight={600}>
                    {PUNCH_LINE_LABEL}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericInput
                    className={
                      editable?.editCompanyBasicDetails ? "editable-input" : ""
                    }
                    inputProps={{
                      disabled: editable?.editCompanyBasicDetails,
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="punchLine"
                    name="punchLine"
                    value={values.punchLine}
                  />
                  <ErrorMessage
                    name={"punchLine"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant="body2" gutterBottom fontWeight={600}>
                    {COMPANY_WEBSITE_LABEL}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericInput
                    className={
                      editable?.editCompanyBasicDetails ? "editable-input" : ""
                    }
                    inputProps={{
                      disabled: editable?.editCompanyBasicDetails,
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="companyWebsite"
                    name="companyWebsite"
                    value={values.companyWebsite}
                  />
                  <ErrorMessage
                    name={"companyWebsite"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>

                {!editable?.editCompanyBasicDetails && (
                  <Grid item xs={12}>
                    <GenericLoadingButton
                      disabled={!isValid}
                      type="submit"
                      sx={{ my: 3, float: "right" }}
                    >
                      {UPDATE_LABEL}
                    </GenericLoadingButton>
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>

      <Paper sx={{ padding: "30px", my: 2 }}>
        <Formik
          enableReinitialize={true}
          initialValues={addressDetailsInitial}
          validationSchema={companyAddressDetailsSchema}
          onSubmit={onAddressDetailsSubmit}
        >
          {({ handleBlur, handleChange, values, isValid }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container sx={{ justifyContent: "space-between" }}>
                    <Typography variant="h6" fontWeight={600}>
                      {ADDRESS_DETAILS_LABEL}
                    </Typography>

                    <Tooltip title={UPDATE_DETAILS_LABEL}>
                      <IconButton
                        onClick={() =>
                          setEditable({
                            ...editable,
                            editCompanyAddressDetails:
                              !editable?.editCompanyAddressDetails,
                          })
                        }
                      >
                        <MdEdit size={20} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
                <GenericDivider />

                <Grid item xs={12} md={2}>
                  <Typography variant="body2" gutterBottom fontWeight={600}>
                    {PINCODE_LABEL}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericInput
                    className={
                      editable?.editCompanyAddressDetails
                        ? "editable-input"
                        : ""
                    }
                    inputProps={{
                      disabled: editable?.editCompanyAddressDetails,
                    }}
                    id="pincode"
                    name="pincode"
                    value={values.pincode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name={"pincode"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>

                <Grid item xs={12} md={2}>
                  <Typography variant="body2" gutterBottom fontWeight={600}>
                    {STATE_LABEL}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericDropdown
                    className={
                      editable?.editCompanyAddressDetails
                        ? "editable-input"
                        : ""
                    }
                    inputProps={{
                      disabled: editable?.editCompanyAddressDetails,
                    }}
                    name="state"
                    id="state"
                    value={values.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name={"state"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant="body2" gutterBottom fontWeight={600}>
                    {CITY_LABEL}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericDropdown
                    className={
                      editable?.editCompanyAddressDetails
                        ? "editable-input"
                        : ""
                    }
                    inputProps={{
                      disabled: editable?.editCompanyAddressDetails,
                    }}
                    id="city"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name={"city"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant="body2" gutterBottom fontWeight={600}>
                    {LOCALITY_LABEL}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericDropdown
                    className={
                      editable?.editCompanyAddressDetails
                        ? "editable-input"
                        : ""
                    }
                    inputProps={{
                      disabled: editable?.editCompanyAddressDetails,
                    }}
                    id="locality"
                    name="locality"
                    value={values.locality}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name={"locality"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>

                <Grid item xs={12} md={2}>
                  <Typography variant="body2" gutterBottom fontWeight={600}>
                    {ADDRESS_LABEL}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericInput
                    className={
                      editable?.editCompanyAddressDetails
                        ? "editable-input"
                        : ""
                    }
                    inputProps={{
                      disabled: editable?.editCompanyAddressDetails,
                    }}
                    id="companyAddress"
                    name="companyAddress"
                    value={values.companyAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name={"companyAddress"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>

                <Grid item xs={12} md={2}>
                  <Typography variant="body2" gutterBottom fontWeight={600}>
                    {DISTRICT_LABEL}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericInput
                    className={
                      editable?.editCompanyAddressDetails
                        ? "editable-input"
                        : ""
                    }
                    inputProps={{
                      disabled: editable?.editCompanyAddressDetails,
                    }}
                    id="district"
                    name="district"
                    value={values.district}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name={"district"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant="body2" gutterBottom fontWeight={600}>
                    {LANDMARK_LABEL}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericInput
                    className={
                      editable?.editCompanyAddressDetails
                        ? "editable-input"
                        : ""
                    }
                    inputProps={{
                      disabled: editable?.editCompanyAddressDetails,
                    }}
                    id="landmark"
                    name="landmark"
                    value={values.landmark}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name={"landmark"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant="body2" gutterBottom fontWeight={600}>
                    {POSI_OFFICE_LABEL}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericInput
                    className={
                      editable?.editCompanyAddressDetails
                        ? "editable-input"
                        : ""
                    }
                    inputProps={{
                      disabled: editable?.editCompanyAddressDetails,
                    }}
                    id="postOffice"
                    name="postOffice"
                    value={values.postOffice}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name={"postOffice"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>

                {!editable?.editCompanyAddressDetails && (
                  <Grid item xs={12}>
                    <GenericLoadingButton
                      disabled={!isValid}
                      type="submit"
                      sx={{ my: 3, float: "right" }}
                    >
                      {UPDATE_LABEL}
                    </GenericLoadingButton>
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>

      <Paper sx={{ padding: "30px" }}>
        <Formik>
          {({ handleBlur, handleChange }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container sx={{ justifyContent: "space-between" }}>
                    <Typography variant="h6" fontWeight={600}>
                      {REGISTRATION_DETAILS_LABEL}
                    </Typography>
                    <Tooltip title={UPDATE_DETAILS_LABEL}>
                      <IconButton
                        onClick={() =>
                          setEditable({
                            ...editable,
                            editCompanyRegistrationDetails:
                              !editable?.editCompanyRegistrationDetails,
                          })
                        }
                      >
                        <MdEdit size={20} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
                <GenericDivider />

                <Grid item xs={12} md={2}>
                  <Typography variant="body2" gutterBottom fontWeight={600}>
                    Company Type
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericInput
                    className={false ? "editable-input" : ""}
                    inputProps={{
                      disabled: false,
                    }}
                    onChange={() => {}}
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <Typography variant="body2" gutterBottom fontWeight={600}>
                    PAN / CIN No.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericInput
                    className={false ? "editable-input" : ""}
                    inputProps={{
                      disabled: false,
                    }}
                    onChange={() => {}}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant="body2" gutterBottom fontWeight={600}>
                    GST No.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericInput
                    className={false ? "editable-input" : ""}
                    inputProps={{
                      disabled: false,
                    }}
                    onChange={() => {}}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant="body2" gutterBottom fontWeight={600}>
                    ISO No.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericInput
                    className={false ? "editable-input" : ""}
                    inputProps={{
                      disabled: false,
                    }}
                    onChange={() => {}}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant="body2" gutterBottom fontWeight={600}>
                    Udyam Regd. No.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericInput
                    className={false ? "editable-input" : ""}
                    inputProps={{
                      disabled: false,
                    }}
                    onChange={() => {}}
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <Typography variant="body2" gutterBottom fontWeight={600}>
                    Shop Act No.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericInput
                    className={false ? "editable-input" : ""}
                    inputProps={{
                      disabled: false,
                    }}
                    onChange={() => {}}
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <Typography variant="body2" gutterBottom fontWeight={600}>
                    IBA Code
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericInput
                    className={false ? "editable-input" : ""}
                    inputProps={{
                      disabled: false,
                    }}
                    onChange={() => {}}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant="body2" gutterBottom fontWeight={600}>
                    IBA Expiry
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericInput
                    className={false ? "editable-input" : ""}
                    inputProps={{
                      disabled: false,
                    }}
                    onChange={() => {}}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>

      <Paper sx={{ padding: "30px", my: 2 }}>
        <Formik>
          {({ handleBlur, handleChange }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container sx={{ justifyContent: "space-between" }}>
                    <Typography variant="h6" fontWeight={600}>
                      {SIGNATURE_DETAILS_LABEL}
                    </Typography>
                    <Tooltip title={UPDATE_DETAILS_LABEL}>
                      <IconButton
                        onClick={() =>
                          setEditable({
                            ...editable,
                            editCompanySignatureDetails:
                              !editable?.editCompanySignatureDetails,
                          })
                        }
                      >
                        <MdEdit size={20} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
                <GenericDivider />

                <Grid item md={4} xs={12}>
                  <img
                    src={false ? "" : "Upload Signature"}
                    height={165}
                    width={100}
                    alt=""
                    loading="lazy"
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Fragment>
  );
};
export default CompanyProfile;
