import React, { useState, Fragment } from "react";
import { Tree } from "antd";
import { Grid } from "@mui/material";
import GenericInput from "../form-elements/genericInput";
const { TreeNode } = Tree;
const GenericTreeMap = (props) => {
  const {
    data,
    onSelect,
    onCheck,
    isInputRequired,
  } = props;
  const [inputValues, setInputValues] = useState({});
  const handleInputChange = (key, e) => {
    setInputValues({
      ...inputValues,
      [key]: e.target.value,
    });
  };
  
  const renderTreeNodes = (data) =>
    data?.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          title={
            <Grid container spacing={2}>
              <Grid item xs={10}>
                {item.title}
              </Grid>
              {isInputRequired && (
                <Grid item xs={2}>
                  <GenericInput
                    sx={{ width: 50 }}
                    
                    value={inputValues[item.key] || ""}
                    onChange={(e) => handleInputChange(item.key, e)}
                  />
                </Grid>
              )}
            </Grid>
          }
          key={item.key}
          dataRef={item}
        />
      );
    });
  return (
    <Fragment>
      <Tree onSelect={onSelect} onCheck={onCheck} treeData={data} autoExpandParent={true} {...props}>
        {renderTreeNodes(data)}
      </Tree>
    </Fragment>
  );
};

export default GenericTreeMap;
