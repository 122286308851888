import { createSelector } from "@reduxjs/toolkit";

const selectState = state => state;
export const getAuthState = createSelector([selectState],
    state => state.auth);
export const getRoleState = createSelector([selectState],
    state => state.role);
export const getCompanyState = createSelector([selectState],
    state => state.company);
    export const getBrFrState = createSelector([selectState],
        state => state.brFr);
    export const getModuleFeatureMapperState = createSelector([selectState],
        state => state.moduleFeatureMapper);
    export const getEmployeeEnrollState = createSelector([selectState],
        state => state.employeeEnroll);