import {
  EMAIL_REGEX,
  MOBILE_REGEX,
  OTP_REGEX,
  PASSWORD_REGEX,
} from "../../../utils/regular-expressions";
import * as Yup from "yup";
export const authInitial = {
  email: "",
  password: "",
  mobile: "",
  otp: "",
};

export const authSchema = Yup.object().shape({
  mobile: Yup.string("Please Enter Your Mobile Number!").matches(
    MOBILE_REGEX,
    "Please Enter Valid Mobile Number!",
  ),
  email: Yup.string("Please Enter Your Email ID!").matches(
    EMAIL_REGEX,
    "Please Enter Valid Email ID!",
  ),
  otp: Yup.string("Please Enter OTP!").when("mobile", {
    is: (mobile) => {
      return mobile !== "";
    },
    then: (schema) =>
      schema.matches(OTP_REGEX, "OTP Should Be 6 Digit Number Only!"),
    otherwise: null,
  }),
  password: Yup.string("Please Enter Your Password!").when(
    ["email", "mobile"],
    {
      is: (email, mobile) => {
        return (
          (email !== "" && mobile === undefined) ||
          (email !== "" && mobile === "")
        );
      },
      then: (schema) =>
        schema
          .required("Password Is Required!")
          .matches(
            PASSWORD_REGEX,
            "Password Should Contain At least 1 character and 1 Special Character!",
          ),
      otherwise: null,
    },
  ),
});
