import { Fragment, useEffect, useMemo, useState } from 'react'
import SubHeader from '../../../../../../common-components/page-elements/SubHeader'
import { Grid, Paper, Typography } from '@mui/material'
import GenericDropdown from '../../../../../../common-components/form-elements/genericDropdown'
import GenericInput from '../../../../../../common-components/form-elements/genericInput'
import GenericLoadingButton from '../../../../../../common-components/form-elements/genericLoadingButton'
import { ADD_PLAN_LABEL, SUBMIT_LABEL } from '../../../../../../languages/en'
import GenericTreeMap from '../../../../../../common-components/page-elements/genericTreeMap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ErrorMessage, Form, Formik } from 'formik'
import { groupByModuleID } from '../../../../../../utils'
import {
  requestForAllPlan,
  requestForCreatePlan,
  requestForDeletePlan,
  requestForUpdatePlan,
  reset
} from '../../../../../../store/slices/PlanSlice'
import { requestForAllModuleFeatureMapper } from '../../../../../../store/slices/ModuleFeatureMapperSlice'
import GenericDataGrid from '../../../../../../common-components/form-elements/genericDataGrid'
import GenericAction from '../../../../../../common-components/form-elements/genericAction'
import GenericSwitch from '../../../../../../common-components/form-elements/genericSwitch'
import {
  planInitial,
  planSchema
} from '../../../../../../common-components/validator/plan-validation'

const AddPlan = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [planFeatures, setPlanFeatures] = useState([])
  const [planFeatureQTY, setPlanFeatureQTY] = useState({})
  const [planFeatureError, setPlanFeatureError] = useState('')
  let { allModuleFeatureMappers } = useSelector(
    state => state.moduleFeatureMapper
  )
  let { allPlans, responseCreatePlan, responseDeletePlan, responseUpdatePlan } =
    useSelector(state => state.plan)
  const [treeData, setTreeData] = useState([])
  useEffect(() => {
    setTreeData(groupByModuleID(allModuleFeatureMappers))
  }, [allModuleFeatureMappers])
  useEffect(() => {
    if (allModuleFeatureMappers?.length === 0) {
      dispatch(requestForAllModuleFeatureMapper())
    }
  }, [])
  const onSelect = selectedKeys => {
    setPlanFeatureError(
      selectedKeys.length > 0 ? '' : 'Please Select At Least One Feature!'
    )
  }
  const getInputValues = values => {
    setPlanFeatureQTY(values)
  }
  const onCheck = checkedKeys => {
    setPlanFeatureError(
      checkedKeys.length > 0 ? '' : 'Please Select At Least One Feature!'
    )
    setPlanFeatures(checkedKeys)
  }
  const onSubmit = values => {
    setPlanFeatureError(
      planFeatures.length > 0 ? '' : 'Please Select Any One Feature!'
    )
    const { planOfferPrice, planName, planDuration, isCouponEnabled, planMRP } =
      values
    if (!planFeatureError) {
      dispatch(
        requestForCreatePlan({
          planName,
          planDuration,
          planOfferPrice,
          planMRP,
          isCouponEnabled,
          planFeatures:JSON.stringify(planFeatures),
          planFeatureQTY
        })
      )
    }
    if (responseCreatePlan) {
      dispatch(reset())
    }
  }
  const onDeleteHandler = data => {
    dispatch(requestForDeletePlan(data?.id))
    if (responseDeletePlan) {
      dispatch(reset())
    }
  }
  const onStatusChangeHandler = row => {
    dispatch(requestForUpdatePlan({ ...row, isActive: !row.isActive }))
    if (responseUpdatePlan) {
      dispatch(reset())
    }
  }
  const onCouponEnableChangeHandler = row => {
    dispatch(
      requestForUpdatePlan({ ...row, isCouponEnabled: !row.isCouponEnabled })
    )
    if (responseUpdatePlan) {
      dispatch(reset())
    }
  }
  useEffect(() => {
    if (responseCreatePlan || responseDeletePlan || responseUpdatePlan) {
      dispatch(requestForAllPlan())
    }
  }, [dispatch, responseCreatePlan, responseDeletePlan, responseUpdatePlan])
  const onEditHandler = data => {
    dispatch(reset())
    navigate(`./${data?.id}`)
  }
  const columns = useMemo(() => {
    return [
      {
        field: 'serialNo',
        headerName: 'Serial No.',
        width: 90,
        renderCell: data => {
          return data.row.serialNo + 1
        }
      },
      { field: 'id', headerName: 'ID.', width: 90 },
      {
        field: 'planName',
        headerName: 'Plan Name',
        width: 150,
        editable: false
      },
      {
        field: 'planDuration',
        headerName: 'Plan Duration',
        width: 150,
        editable: false
      },
      {
        field: 'planMRP',
        headerName: 'Plan MRP',
        width: 150,
        editable: false
      },
      {
        field: 'planOfferPrice',
        headerName: 'Plan Offer Price',
        width: 150,
        editable: false
      },
      {
        field: 'planFeatures',
        headerName: 'Plan Features',
        width: 150,
        editable: false,
        renderCell: data => {
          const checkedKeys = JSON.parse(data?.row?.planFeatures)
          return (
            <Fragment>
              <GenericTreeMap
                data={treeData}
                checkable={false}
                isInputRequired={false}
                // getInputValues={() => console.log()}
                defaultCheckedKeys={checkedKeys}
              />
            </Fragment>
          )
        }
      },
      {
        field: 'isCouponEnabled',
        headerName: 'Coupon Enabled',
        width: 250,
        editable: false,
        renderCell: data => {
          return (
            <GenericSwitch
              start={'Enable'}
              end={'Disable'}
              value={data?.row?.isCouponEnabled}
              onChange={() => onCouponEnableChangeHandler(data?.row)}
            />
          )
        }
      },
      {
        field: 'isActive',
        headerName: 'Status',
        width: 250,
        editable: false,
        renderCell: data => {
          return (
            <GenericSwitch
              start={'Active'}
              end={'Inactive'}
              value={data?.row?.isActive}
              onChange={() => onStatusChangeHandler(data?.row)}
            />
          )
        }
      },
      {
        field: 'createdAt',
        headerName: 'Created Date',
        width: 150,
        editable: false,
        renderCell: data => {
          return new Date(data?.row?.createdAt).toDateString()
        }
      },
      {
        field: 'updatedAt',
        headerName: 'Updated Date',
        width: 150,
        editable: false,
        renderCell: data => {
          return new Date(data?.row?.updatedAt).toDateString()
        }
      },
      {
        headerName: 'Action',
        width: 150,
        field: '',
        editable: false,
        renderCell: data => {
          return (
            <GenericAction
              data={data?.row}
              onDeleteHandler={data => onDeleteHandler(data)}
              onEditHandler={data => onEditHandler(data)}
              deleteRequired={true}
              editRequired={true}
            />
          )
        }
      }
    ]
  }, [])

  return (
    <Fragment>
      <SubHeader title={ADD_PLAN_LABEL} />
      <Paper sx={{ padding: '30px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Formik
              initialValues={planInitial}
              validationSchema={planSchema}
              onSubmit={onSubmit}
            >
              {({ handleChange, handleBlur, values, errors, isValid }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                      <GenericInput
                        label='Plan Name'
                        name='planName'
                        id='planName'
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name={'planName'}
                        component='p'
                        className='error'
                      ></ErrorMessage>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <GenericDropdown
                        label='Plan Duration'
                        name='planDuration'
                        id='planDuration'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        data={[
                          {
                            value: 'Monthly',
                            label: 'Monthly'
                          },
                          {
                            value: 'Half Yearly',
                            label: 'Half Yearly'
                          },
                          {
                            value: 'Yearly',
                            label: 'Yearly'
                          }
                        ]}
                      />
                      <ErrorMessage
                        name={'planDuration'}
                        component='p'
                        className='error'
                      ></ErrorMessage>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <GenericInput
                        name='planMRP'
                        id='planMRP'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label='Plan MRP'
                      />
                      <ErrorMessage
                        name={'planMRP'}
                        component='p'
                        className='error'
                      ></ErrorMessage>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <GenericInput
                        name='planOfferPrice'
                        id='planOfferPrice'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label='Plan Offer Price'
                      />
                      <ErrorMessage
                        name={'planOfferPrice'}
                        component='p'
                        className='error'
                      ></ErrorMessage>
                    </Grid>
                    <Grid item xs={12} sx={{ my: 2 }}>
                      <Typography variant='h6' sx={{ mb: 2 }}>
                        Plan Feaures
                      </Typography>
                      <GenericTreeMap
                        checkable={true}
                        data={treeData}
                        onSelect={selected => {
                          onSelect(selected)
                        }}
                        onCheck={selected => {
                          onCheck(selected)
                        }}
                        isInputRequired={true}
                        id={'planFeatures'}
                        name={'planFeatures'}
                        // getInputValues={getInputValues}
                      />
                      {planFeatureError?.length !== 0 && (
                        <p name={'planFeatures'} className='error'>
                          {planFeatureError}
                        </p>
                      )}
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <GenericLoadingButton
                        disabled={!isValid && planFeatureError ? true : false}
                        sx={{ my: 2, float: 'right' }}
                        type='submit'
                      >
                        {SUBMIT_LABEL}
                      </GenericLoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
          <Grid item xs={12}>
            {/* <GenericDataGrid
              getRowHeight={() => 'auto'}
              rows={allPlans?.map((row, index) => {
                return {
                  serialNo: index++,
                  ...row
                }
              }) || []}
              columns={columns}
            /> */}
            {
              console.log(allPlans,"sasasa")
            }
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  )
}
export default AddPlan
