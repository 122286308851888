import { Grid, Paper } from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import { Fragment, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GenericAction from "../../../../../../common-components/form-elements/genericAction";
import GenericDataGrid from "../../../../../../common-components/form-elements/genericDataGrid";
import GenericDropdown from "../../../../../../common-components/form-elements/genericDropdown";
import GenericInput from "../../../../../../common-components/form-elements/genericInput";
import GenericLoadingButton from "../../../../../../common-components/form-elements/genericLoadingButton"; 
import SubHeader from "../../../../../../common-components/page-elements/SubHeader";
import {
  brFrInitial,
  brFrSchema,
} from "../../../../../../common-components/validator/brFr-validation";
import {
  ADDRESS_LABEL,
  BRANCH_OR_FRANCHISE_NAME_LABEL,
  BRFR_LABEL,
  BRFR_TYPE_LABEL,
  BRFR_TYPE_LIST,
  CITY_LABEL,
  EMAIL_ADDRESS_LABEL,
  GST_NO_LABEL,
  LANDMARK_LABEL,
  LOCALITY_LABEL,
  OWNER_NAME_LABEL,
  PINCODE_LABEL,
  REG_MOBILE_NUMBER_LABEL,
  STATE_LABEL,
  SUBMIT_LABEL,
  WEBSITE_ADDRESS_LABEL,
} from "../../../../../../languages/en";
import {
  getBrFrState,
  getCompanyState,
} from "../../../../../../store/selectors";
import {
  requestForAllBrFrByCompanyId,
  requestForCreateBrFr,
  requestForDeleteBrFr,
  reset,
} from "../../../../../../store/slices/BrFrSlice";

const AddBrFr = () => {
  const dispatch = useDispatch();
  const company = useSelector(getCompanyState);
  const {
    currentCompany: { id: companyID },
  } = company;
  const brFr = useSelector(getBrFrState);
  const { allBrFrs, responseDeleteBrFr, responseCreateBrFr } = brFr;
  const navigate = useNavigate();
  const onSubmit = (values) => {
    dispatch(requestForCreateBrFr({ ...values, companyID }));
  };

  const onDeleteHandler = (data) => {
    dispatch(requestForDeleteBrFr(data?.id));
  };
  useEffect(() => {
    dispatch(requestForAllBrFrByCompanyId(companyID));
  }, [responseCreateBrFr, responseDeleteBrFr, companyID]);
  const onEditHandler = (data) => {
    dispatch(reset());
    navigate(`./${data?.id}`);
  };
  const columns = useMemo(() => {
    return [
      {
        field: "serialNo",
        headerName: "Serial No.",

        renderCell: (data) => {
          return data.row.serialNo + 1;
        },
      },
      { field: "id", headerName: "ID." },
      {
        field: "brFrName",
        headerName: "Branch/Franchise Name",
        width: 200,
        editable: false,
      },
      {
        field: "brFrType",
        headerName: "Entity Type",
        width: 90,
        editable: false,
      },
      {
        field: "email",
        headerName: "Email",
        width: 195,
        editable: false,
      },
      {
        field: "registeredMobile",
        headerName: "Reg. Mobile",
        width: 100,
        editable: false,
      },
      {
        field: "locality",
        headerName: "Locality",
        width: 120,

        editable: false,
      },
      {
        field: "landmark",
        headerName: "Landmark",
        width: 120,

        editable: false,
      },
      {
        field: "address",
        headerName: "Address",
        width: 200,

        editable: false,
      },
      {
        field: "city",
        headerName: "City",
        width: 100,

        editable: false,
      },
      {
        field: "state",
        headerName: "State",
        width: 120,

        editable: false,
      },
      {
        field: "pincode",
        headerName: "Pincode",
        width: 80,

        editable: false,
      },
      {
        field: "website",
        headerName: "Website",
        width: 150,

        editable: false,
      },
      {
        field: "gstNo",
        headerName: "GST NO.",
        width: 150,

        editable: false,
      },
      {
        field: "createdAt",
        headerName: "Created Date",
        width: 150,
        editable: false,
        renderCell: (data) => {
          return new Date(data?.row?.createdAt).toDateString();
        },
      },
      {
        field: "updatedAt",
        headerName: "Updated Date",
        width: 150,
        editable: false,
        renderCell: (data) => {
          return new Date(data?.row?.updatedAt).toDateString();
        },
      },
      {
        headerName: "Action",
        width: 150,
        field: "",
        editable: false,
        renderCell: (data) => {
          return (
            <GenericAction
              data={data?.row}
              onDeleteHandler={(data) => onDeleteHandler(data)}
              onEditHandler={(data) => onEditHandler(data)}
              deleteRequired={true}
              editRequired={true}
            />
          );
        },
      },
    ];
  }, []);
  return (
    <Fragment>
      <SubHeader title={BRFR_LABEL} />
      <Paper sx={{ padding: "30px" }}>
        <Formik
          onSubmit={onSubmit}
          initialValues={brFrInitial}
          validationSchema={brFrSchema}
        >
          {({ handleChange, handleBlur, isValid }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item md={4} xs={6}>
                  <GenericDropdown
                    data={BRFR_TYPE_LIST}
                    label={BRFR_TYPE_LABEL}
                    name="brFrType"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="brFrType"
                  />
                  <ErrorMessage
                    name={"brFrType"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>
                <Grid item md={4} xs={6}>
                  <GenericInput
                    label={BRANCH_OR_FRANCHISE_NAME_LABEL}
                    name="brFrName"
                    id="brFrName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name={"brFrName"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>
                <Grid item md={4} xs={6}>
                  <GenericInput
                    label={OWNER_NAME_LABEL}
                    name="ownerName"
                    id="ownerName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name={"ownerName"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>
                <Grid item md={4} xs={6}>
                  <GenericInput
                    label={REG_MOBILE_NUMBER_LABEL}
                    name="registeredMobile"
                    id="registeredMobile"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name={"registeredMobile"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>
                <Grid item md={4} xs={6}>
                  <GenericInput
                    label={EMAIL_ADDRESS_LABEL}
                    name="email"
                    id="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name={"email"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>
                <Grid item md={4} xs={6}>
                  <GenericInput
                    label={WEBSITE_ADDRESS_LABEL}
                    name="website"
                    id="website"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name={"website"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>
                <Grid item md={4} xs={6}>
                  <GenericInput
                    label={LANDMARK_LABEL}
                    name="landmark"
                    id="landmark"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name={"landmark"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>
                <Grid item md={4} xs={6}>
                  <GenericInput
                    label={LOCALITY_LABEL}
                    name="locality"
                    id="locality"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name={"locality"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>
                <Grid item md={8}>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={6}>
                      <GenericInput
                        label={CITY_LABEL}
                        name="city"
                        id="city"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name={"city"}
                        component="p"
                        className="error"
                      ></ErrorMessage>
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <GenericInput
                        label={STATE_LABEL}
                        name="state"
                        id="state"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name={"state"}
                        component="p"
                        className="error"
                      ></ErrorMessage>
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <GenericInput
                        label={PINCODE_LABEL}
                        name="pincode"
                        id="pincode"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name={"pincode"}
                        component="p"
                        className="error"
                      ></ErrorMessage>
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <GenericInput
                        label={GST_NO_LABEL}
                        name="gstNo"
                        id="gstNo"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name={"gstNo"}
                        component="p"
                        className="error"
                      ></ErrorMessage>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                  <GenericInput
                    label={ADDRESS_LABEL}
                    multiline
                    rows={3}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="address"
                    id="address"
                  />
                  <ErrorMessage
                    name={"address"}
                    component="p"
                    className="error"
                  ></ErrorMessage>
                </Grid>

                <Grid item xs={12} md={12}>
                  <GenericLoadingButton
                    disabled={!isValid}
                    type="submit"
                    sx={{ my: 2, float: "right" }}
                  >
                    {SUBMIT_LABEL}
                  </GenericLoadingButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GenericDataGrid
              getRowHeight={() => "auto"}
              rows={allBrFrs?.map((row, index) => {
                return {
                  serialNo: index++,
                  ...row,
                };
              })}
              columns={columns}
            />
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};

export default AddBrFr;
