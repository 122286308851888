import * as Yup from "yup";
import { NAME_WITH_CHARACTERS_REGEX } from "../../../utils/regular-expressions";
export const employeeEnrollInitial = {
  personName: "",
  entityName: "",
  brFrID: 0,
  mobileNumber: "",
  emailID: "",
  password: "",
  // permissions:[],
  roleID: "",
};

export const employeeEnrollSchema = Yup.object().shape({
  personName: Yup.string("Please Enter Person Name!").required(
    "Person Name Is Required!",
  ),
  entityName: Yup.string("Please Select Entity Name!").required(
    "Entity Name Is Required!",
  ),
  brFrID: Yup.string("Please Select Branch or Franchise!").required(
    "Branch or Franchise Name Is Required!",
  ),
  mobileNumber: Yup.string("Please Enter Mobile!").required(
    "Mobile Number Is Required!",
  ),
  emailID: Yup.string("Please Enter Email ID!").required(
    "Feature Name Is Required!",
  ),
  password: Yup.string("Please Enter Password!").required(
    "Feature Name Is Required!",
  ),

  roleID: Yup.string("Please Select Role Type").required(
    "Role Type Is Required!",
  ),
  // roleType: Yup.lazy((val) =>
  //   Array.isArray(val)
  //     ? Yup.array()
  //         .of(Yup.string())
  //         .min(1, "Please Select At Least One Role Type")
  //     : Yup.array().required("Please Select Role Type")
  // ),
});
