import React, { Fragment } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "../../common-components/page-elements/layout";
import { useAuth } from "../../hooks/useAuth";
import Login from "./login/login";
import Registration from "./registration/Registration";
const AuthRouting = () => {
  const { isAuthenticated } = useAuth();
  return (
    <Fragment>
      <Layout>
        {!isAuthenticated ? (
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/registration" element={<Registration />} />
          </Routes>
        ) : (
          <Navigate to="/erp" />
        )}
      </Layout>
    </Fragment>
  );
};
export default AuthRouting;
