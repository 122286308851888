import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  allRole: [],
  requestAllRole: false,
  requestCreateRole: false,
  requestDeleteRole: false,
  requestUpdateRole: false,
  failedAllRole: false,
  responseAllRole: false,
  responseCreateRole: false,
  responseDeleteRole: false,
  responseUpdateRole: false,
  failedCreateRole: false,
  failedDeleteRole: false,
  failedUpdateRole: false,
};
export const RoleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    reset: (state) => {
      state.requestAllRole = false;
      state.failedAllRole = false;
      state.responseAllRole = false;
      state.requestCreateRole = false;
      state.responseCreateRole = false;
      state.failedCreateRole = false;
      state.requestUpdateRole = false;
      state.responseUpdateRole = false;
      state.failedUpdateRole = false;
      state.requestDeleteRole = false;
      state.responseDeleteRole = false;
      state.failedDeleteRole = false;
    },
    requestForAllRole: (state) => {
      state.requestAllRole = true;
    },
    responseForAllRole: (state, action) => {
      state.allRole = action?.payload;
      state.responseAllRole = true;
    },
    failedForAllRole: (state) => {
      state.failedAllRole = true;
    },
    requestForCreateRole: (state) => {
      state.requestCreateRole = true;
    },
    responseForCreateRole: (state) => {
      state.responseCreateRole = true;
    },
    failedForCreateRole: (state) => {
      state.failedCreateRole = true;
    },
    requestForDeleteRole: (state) => {
      state.requestDeleteRole = true;
    },
    responseForDeleteRole: (state) => {
      state.responseDeleteRole = true;
    },
    failedForDeleteRole: (state) => {
      state.failedDeleteRole = true;
    },
    requestForUpdateRole: (state) => {
      state.requestUpdateRole = true;
    },
    responseForUpdateRole: (state) => {
      state.responseUpdateRole = true;
    },
    failedForUpdateRole: (state) => {
      state.failedUpdateRole = true;
    },
  },
});
export default RoleSlice.reducer;
export const {
  reset,
  requestForAllRole,
  failedForAllRole,
  responseForAllRole,
  failedForCreateRole,
  requestForCreateRole,
  responseForCreateRole,
  failedForDeleteRole,
  requestForDeleteRole,
  responseForDeleteRole,
  failedForUpdateRole,
  requestForUpdateRole,
  responseForUpdateRole,
} = RoleSlice.actions;
