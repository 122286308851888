import { Route, Routes } from "react-router-dom";
import AddRole from "./add";
import UpdateRole from "./update";
const RoleRoutes = () => {
  return (
    <Routes>
      <Route path={`/`} element={<AddRole />} />
      <Route path={`/:id`} element={<UpdateRole />} />
    </Routes>
  );
};
export default RoleRoutes;
