import { Grid, Paper } from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import { Fragment, useEffect, useMemo } from "react";
import GenericInput from "../../../../../../common-components/form-elements/genericInput";
import GenericLoadingButton from "../../../../../../common-components/form-elements/genericLoadingButton";

import GenericAction from "../../../../../../common-components/form-elements/genericAction";
import GenericDataGrid from "../../../../../../common-components/form-elements/genericDataGrid";
import SubHeader from "../../../../../../common-components/page-elements/SubHeader";

import { useDispatch, useSelector } from "react-redux";
import {
  roleInitial,
  roleSchema,
} from "../../../../../../common-components/validator/role-validation";
import {
  ROLE_LABEL,
  ROLE_NAME_LABEL,
  SUBMIT_LABEL,
} from "../../../../../../languages/en";

import { useNavigate } from "react-router-dom";
import GenericSwitch from "../../../../../../common-components/form-elements/genericSwitch";
import {
  requestForAllRole,
  requestForCreateRole,
  requestForDeleteRole,
  requestForUpdateRole,
  reset,
} from "../../../../../../store/slices/RoleSlice";

const AddRole = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { allRole, responseCreateRole, responseDeleteRole, responseUpdateRole } =
    useSelector((state) => state.role);

  const onDeleteHandler = (data) => {
    dispatch(requestForDeleteRole(data?.id));
    if (responseDeleteRole) {
      dispatch(reset());
    }
  };
  const onStatusChangeHandler = (row) => {
    dispatch(requestForUpdateRole({ ...row, isActive: !row.isActive }));
    if (responseUpdateRole) {
      dispatch(reset());
    }
  };
  const onEditHandler = (data) => {
    dispatch(reset());
    navigate(`./${data?.id}`);
  };
  const columns = useMemo(() => {
    return [
      {
        field: "serialNo",
        headerName: "Serial No.",
        width: 90,
        renderCell: (data) => {
          return data.row.serialNo + 1;
        },
      },
      { field: "id", headerName: "ID.", width: 90 },
      {
        field: "roleName",
        headerName: "Role Name",
        width: 150,
        editable: false,
      },
      {
        field: "isActive",
        headerName: "Status",
        width: 250,
        editable: false,
        renderCell: (data) => {
          return (
            <GenericSwitch
              start={"Active"}
              end={"Inactive"}
              value={data?.row?.isActive}
              onChange={() => onStatusChangeHandler(data?.row)}
            />
          );
        },
      },
      {
        field: "createdAt",
        headerName: "Created Date",
        width: 150,
        editable: false,
        renderCell: (data) => {
          return new Date(data?.row?.createdAt).toDateString();
        },
      },
      {
        field: "updatedAt",
        headerName: "Updated Date",
        width: 150,
        editable: false,
        renderCell: (data) => {
          return new Date(data?.row?.updatedAt).toDateString();
        },
      },
      {
        headerName: "Action",
        width: 150,
        field: "",
        editable: false,
        renderCell: (data) => {
          return (
            <GenericAction
              data={data?.row}
              onDeleteHandler={(data) => onDeleteHandler(data)}
              onEditHandler={(data) => onEditHandler(data)}
              onPreviewHandler={() => console.log()}
              onDownloadHandler={() => console.log()}
              onShareHandler={() => console.log()}
            />
          );
        },
      },
    ];
  });

  const onSubmit = (values) => {
    dispatch(requestForCreateRole(values));
    if (responseCreateRole) {
      dispatch(reset());
    }
  };
  useEffect(() => {
    dispatch(requestForAllRole());
  }, [responseCreateRole, responseDeleteRole, responseUpdateRole]);
  useEffect(() => {
    dispatch(requestForAllRole());
  }, []);

  return (
    <Fragment>
      <SubHeader title={ROLE_LABEL} />
      <Paper sx={{ padding: "30px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Formik
              initialValues={roleInitial}
              validationSchema={roleSchema}
              onSubmit={onSubmit}
            >
              {({ handleChange, handleBlur, isValid, errors }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <GenericInput
                        onChange={handleChange}
                        label={ROLE_NAME_LABEL}
                        onBlur={handleBlur}
                        name="roleName"
                        id="roleName"
                      />
                      <ErrorMessage
                        name={"roleName"}
                        component="p"
                        className="error"
                      ></ErrorMessage>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <GenericLoadingButton disabled={!isValid} type="submit">
                        {SUBMIT_LABEL}
                      </GenericLoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
          <Grid item xs={12}>
            <GenericDataGrid
              columns={columns}
              rows={allRole?.map((row, index) => {
                return {
                  serialNo: index++,
                  ...row,
                };
              })}
            />
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};
export default AddRole;
