import { Box, Grid, Paper, Typography } from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GenericAction from "../../../../../../common-components/form-elements/genericAction";
import GenericDataGrid from "../../../../../../common-components/form-elements/genericDataGrid";
import GenericDropdown from "../../../../../../common-components/form-elements/genericDropdown";
import GenericInput from "../../../../../../common-components/form-elements/genericInput";
import GenericLoadingButton from "../../../../../../common-components/form-elements/genericLoadingButton";
import GenericRadio from "../../../../../../common-components/form-elements/genericRadio";
import GenericSwitch from "../../../../../../common-components/form-elements/genericSwitch";
import GenericTreeMap from "../../../../../../common-components/page-elements/genericTreeMap";
import SubHeader from "../../../../../../common-components/page-elements/SubHeader";
import {
  employeeEnrollInitial,
  employeeEnrollSchema,
} from "../../../../../../common-components/validator/employee-enroll-validation";
import {
  ADD_EMPLOYEE_ENROLL_LABEL,
  SUBMIT_LABEL,
} from "../../../../../../languages/en";
import {
  getAuthState,
  getBrFrState,
  getCompanyState,
  getEmployeeEnrollState,
  getModuleFeatureMapperState,
  getRoleState,
} from "../../../../../../store/selectors";
import { requestForRegisterEmployee } from "../../../../../../store/slices/AuthSlice";
import { requestForAllBrFrByCompanyId } from "../../../../../../store/slices/BrFrSlice";
import {
  requestForAllEmployeeEnroll,
  requestForCreateEmployeeEnroll,
  requestForDeleteEmployeeEnroll,
  requestForUpdateEmployeeEnroll,
  reset,
} from "../../../../../../store/slices/EmployeeEnrollSlice";
import { groupByModuleID } from "../../../../../../utils";
const AddEmployeeEnrollment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState([]);
  const [permissionError, setPermissionError] = useState("");
  const auth = useSelector(getAuthState);
  const { loginResponse } = auth;
  const { id: authId } = loginResponse;
  const role = useSelector(getRoleState);
  let { allRole } = role;
  const brFr = useSelector(getBrFrState);
  let { allBrFrs } = brFr;
  const company = useSelector(getCompanyState);
  const {
    currentCompany: { id: companyID },
  } = company;
  const moduleFeatureMappers = useSelector(getModuleFeatureMapperState);
  let { allModuleFeatureMappers } = moduleFeatureMappers;
  const employeeEnroll = useSelector(getEmployeeEnrollState);
  let {
    allEmployeeEnrolls,
    responseCreateEmployeeEnroll,
    responseDeleteEmployeeEnroll,
    responseUpdateEmployeeEnroll,
  } = employeeEnroll;
  const [treeData, setTreeData] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const res = allRole
      ?.filter((el) => {
        if (
          (el?.roleName).toLowerCase() !== "super admin" &&
          (el?.roleName).toLowerCase() !== "admin"
        ) {
          return el;
        }
      })
      .map((el) => {
        return {
          value: el?.id,
          label: el?.roleName,
        };
      });

    setRoles(res);
  }, [allRole]);
  useEffect(() => {
    setTreeData(groupByModuleID(allModuleFeatureMappers));
  }, [allModuleFeatureMappers]);

  useEffect(() => {
    dispatch(requestForAllBrFrByCompanyId(companyID));
    dispatch(requestForAllEmployeeEnroll());
  }, []);
  const style = {
    display: "flex",
    alignItem: "center",
    justifyContent: "spaceBetween",
    width: "100%",
    border: "1px solid #C4C4C4",
    borderRadius: "4px",
  };

  const onSelect = (selectedKeys) => {
    setPermissionError(
      selectedKeys.length > 0 ? "" : "Please Select At Least One Permission!"
    );
  };

  const onCheck = (checkedKeys) => {
    console.log(checkedKeys, "checkedKeys");

    setPermissionError(
      checkedKeys.length > 0 ? "" : "Please Select At Least One Permission!"
    );
    setPermissions(checkedKeys);
  };
  const onSubmit = (values) => {
    setPermissionError(
      permissions.length > 0 ? "" : "Please Select Any One Permission!"
    );
    const {
      emailID,
      password,
      personName,
      entityName,
      brFrID,
      mobileNumber,
      roleID,
    } = values;
    dispatch(
      requestForCreateEmployeeEnroll({
        personName,
        entityName,
        brFrID,
        mobileNumber,
        roleID,
        permissions,
        companyID: companyID,
      })
    ) && dispatch(requestForRegisterEmployee({ email: emailID, password }));
    if (responseCreateEmployeeEnroll) {
      dispatch(reset());
    }
  };
  const onDeleteHandler = (data) => {
    dispatch(requestForDeleteEmployeeEnroll(data?.id));
    if (responseDeleteEmployeeEnroll) {
      dispatch(reset());
    }
  };
  const onStatusChangeHandler = (row) => {
    dispatch(
      requestForUpdateEmployeeEnroll({ ...row, isActive: !row.isActive })
    );
    if (responseUpdateEmployeeEnroll) {
      dispatch(reset());
    }
  };
  const onEditHandler = (data) => {
    dispatch(reset());
    navigate(`./${data?.id}`);
  };
  const columns = useMemo(() => {
    return [
      {
        field: "serialNo",
        headerName: "Serial No.",
        width: 90,
        renderCell: (data) => {
          return data.row.serialNo + 1;
        },
      },
      { field: "id", headerName: "ID.", width: 90 },
      {
        field: "personName",
        headerName: "Person Name",
        width: 150,
        editable: false,
      },
      {
        field: "entityName",
        headerName: "Entity Name",
        width: 150,
        editable: false,
      },
      {
        field: "roleID",
        headerName: "Role Name",
        width: 150,
        editable: false,
        renderCell: (data) => {
          return data?.row?.role?.roleName;
        },
      },
      {
        field: "permissions",
        headerName: "Permissions",
        width: 150,

        editable: false,
        renderCell: (data) => {
          const checkedKeys = JSON.parse(data?.row?.permissions);
          return (
            <Fragment>
              <GenericTreeMap
                data={treeData}
                checkable={false}
                defaultCheckedKeys={checkedKeys}
              />
            </Fragment>
          );
        },
      },

      {
        field: "isActive",
        headerName: "Status",
        width: 250,
        editable: false,
        renderCell: (data) => {
          return (
            <GenericSwitch
              start={"Active"}
              end={"Inactive"}
              value={data?.row?.isActive}
              onChange={() => onStatusChangeHandler(data?.row)}
            />
          );
        },
      },
      {
        field: "createdAt",
        headerName: "Created Date",
        width: 150,
        editable: false,
        renderCell: (data) => {
          return new Date(data?.row?.createdAt).toDateString();
        },
      },
      {
        field: "updatedAt",
        headerName: "Updated Date",
        width: 150,
        editable: false,
        renderCell: (data) => {
          return new Date(data?.row?.updatedAt).toDateString();
        },
      },
      {
        headerName: "Action",
        width: 150,
        field: "",
        editable: false,
        renderCell: (data) => {
          return (
            <GenericAction
              data={data?.row}
              onDeleteHandler={(data) => onDeleteHandler(data)}
              onEditHandler={(data) => onEditHandler(data)}
              deleteRequired={true}
              editRequired={true}
            />
          );
        },
      },
    ];
  }, []);

  useEffect(() => {
    dispatch(requestForAllEmployeeEnroll());
  }, [
    dispatch,
    responseCreateEmployeeEnroll,
    responseDeleteEmployeeEnroll,
    responseUpdateEmployeeEnroll,
  ]);
  return (
    <Fragment>
      <SubHeader title={ADD_EMPLOYEE_ENROLL_LABEL} />
      <Paper sx={{ padding: "30px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Formik
              initialValues={employeeEnrollInitial}
              validationSchema={employeeEnrollSchema}
              onSubmit={onSubmit}
            >
              {({ handleChange, handleBlur, values, errors, isValid }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                      <GenericInput
                        label="Person Name"
                        name="personName"
                        id="personName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name={"personName"}
                        component="p"
                        className="error"
                      ></ErrorMessage>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <GenericDropdown
                        label="Choose Entity"
                        name="entityName"
                        id="entityName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        data={[
                          {
                            value: "head office",
                            label: "Head Office",
                          },
                          {
                            value: "branch",
                            label: "Branch",
                          },
                          {
                            value: "franchise",
                            label: "Franchise",
                          },
                        ]}
                      />
                      <ErrorMessage
                        name={"entityName"}
                        component="p"
                        className="error"
                      ></ErrorMessage>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <GenericDropdown
                        label="Choose Branch/Franschise"
                        data={allBrFrs?.map((el) => {
                          return {
                            value: el?.id,
                            label: `${el?.brFrName} | ${el?.brFrType}`,
                          };
                        })}
                        name="brFrID"
                        id="brFrID"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name={"brFrID"}
                        component="p"
                        className="error"
                      ></ErrorMessage>
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <GenericInput
                        name="mobileNumber"
                        id="mobileNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Mobile Number"
                      />
                      <ErrorMessage
                        name={"mobileNumber"}
                        component="p"
                        className="error"
                      ></ErrorMessage>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <GenericInput
                        name="emailID"
                        id="emailID"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Email"
                      />
                      <ErrorMessage
                        name={"emailID"}
                        component="p"
                        className="error"
                      ></ErrorMessage>
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <GenericInput
                        name="password"
                        id="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Create Password"
                      />
                      <ErrorMessage
                        name={"password"}
                        component="p"
                        className="error"
                      ></ErrorMessage>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box sx={{ ...style }}>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#666666",
                            marginRight: "20px",
                            marginTop: "10px",
                            marginLeft: "15px",
                          }}
                        >
                          Role Type *
                        </Typography>
                        <GenericRadio
                          options={roles}
                          name="roleID"
                          id="roleID"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ display: "block" }}
                        />
                      </Box>
                      <ErrorMessage
                        name={"roleID"}
                        component="p"
                        className="error"
                      ></ErrorMessage>
                    </Grid>
                    <Grid item xs={12} sx={{ my: 2 }}>
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        Allow Permissions
                      </Typography>

                      <GenericTreeMap
                        checkable={true}
                        data={treeData}
                        onSelect={(selected) => {
                          onSelect(selected);
                        }}
                        onCheck={(selected) => {
                          onCheck(selected);
                        }}
                        id={"permissions"}
                        name={"permissions"}
                      />

                      {permissionError?.length !== 0 && (
                        <p name={"permissions"} className="error">
                          {permissionError}
                        </p>
                      )}
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <GenericLoadingButton
                        disabled={!isValid && permissionError ? true : false}
                        sx={{ my: 2, float: "right" }}
                        type="submit"
                      >
                        {SUBMIT_LABEL}
                      </GenericLoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
          <Grid item xs={12}>
            <GenericDataGrid
              getRowHeight={() => "auto"}
              rows={allEmployeeEnrolls?.map((row, index) => {
                return {
                  serialNo: index++,
                  ...row,
                };
              })}
              columns={columns}
            />
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};
export default AddEmployeeEnrollment;
