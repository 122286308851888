import { Fragment, useEffect, useMemo, useState } from "react";
import SubHeader from "../../../../../../common-components/page-elements/SubHeader";
import { Grid, Paper } from "@mui/material";
import GenericDropdown from "../../../../../../common-components/form-elements/genericDropdown";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GenericSwitch from "../../../../../../common-components/form-elements/genericSwitch";
import GenericAction from "../../../../../../common-components/form-elements/genericAction";
import {
  requestForAllModuleFeatureMapper,
  requestForCreateModuleFeatureMapper,
  requestForDeleteModuleFeatureMapper,
  requestForUpdateModuleFeatureMapper,
  reset,
} from "../../../../../../store/slices/ModuleFeatureMapperSlice";
import {
  requestForAllModule,
  requestForAllSubModule,
} from "../../../../../../store/slices/ModuleSlice";
import { requestForAllFeature } from "../../../../../../store/slices/FeatureSlice";
import { Form, Formik } from "formik";
import {
  moduleFeatureMapperInitial,
  moduleFeatureMapperSchema,
} from "../../../../../../common-components/validator/module-feature-mapper-validation";
import GenericLoadingButton from "../../../../../../common-components/form-elements/genericLoadingButton";
import { SUBMIT_LABEL } from "../../../../../../languages/en";
import GenericDataGrid from "../../../../../../common-components/form-elements/genericDataGrid";

const AddMapper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedModuleID, setSelectedModuleID] = useState(0);
  const [subModules, setSubModules] = useState([]);

  let {
    allModuleFeatureMappers,
    responseCreateModuleFeatureMapper,
    responseDeleteModuleFeatureMapper,
    responseUpdateModuleFeatureMapper,
  } = useSelector((state) => state.moduleFeatureMapper);

  let { allModules, allSubModules } = useSelector((state) => state.module);
  let { allFeatures } = useSelector((state) => state.feature);
  useEffect(() => {
    if (allModules?.length === 0) {
      dispatch(requestForAllModule());
    }
    if (allFeatures?.length === 0) {
      dispatch(requestForAllFeature());
    }
  }, []);
  useEffect(() => {
    setSubModules(() => {
      return allSubModules?.map((el) => {
        return {
          value: el?.id,
          label: el?.moduleName,
        };
      });
    });
  }, [allSubModules]);

  const columns = useMemo(() => {
    return [
      {
        field: "serialNo",
        headerName: "Serial No.",
        width: 90,
        renderCell: (data) => {
          return data.row.serialNo + 1;
        },
      },
      { field: "id", headerName: "ID.", width: 90 },
      {
        field: "moduleID",
        headerName: "Module Name",
        width: 150,
        renderCell: (data) => {
          return data.row.Module.moduleName;
        },
      },
      {
        field: "subModuleID",
        headerName: "Sub Module Name",
        width: 150,
        renderCell: (data) => {
          return data.row.SubModule.moduleName;
        },
      },
      {
        field: "featureID",
        headerName: "Feature Name",
        width: 150,
        renderCell: (data) => {
          return data.row.Feature.featureName;
        },
      },
      {
        field: "isActive",
        headerName: "Status",
        width: 250,
        editable: false,
        renderCell: (data) => {
          return (
            <GenericSwitch
              start={"Active"}
              end={"Inactive"}
              value={data?.row?.isActive}
              onChange={() => onStatusChangeHandler(data?.row)}
            />
          );
        },
      },
      {
        field: "createdAt",
        headerName: "Created Date",
        width: 150,
        editable: false,
        renderCell: (data) => {
          return new Date(data?.row?.createdAt).toDateString();
        },
      },
      {
        field: "updatedAt",
        headerName: "Updated Date",
        width: 150,
        editable: false,
        renderCell: (data) => {
          return new Date(data?.row?.updatedAt).toDateString();
        },
      },
      {
        headerName: "Action",
        width: 150,
        field: "",
        editable: false,
        renderCell: (data) => {
          return (
            <GenericAction
              data={data?.row}
              onDeleteHandler={(data) => onDeleteHandler(data)}
              onEditHandler={(data) => onEditHandler(data)}
              deleteRequired={true}
              editRequired={true}
            />
          );
        },
      },
    ];
  });

  const onSubmit = (values) => {
    dispatch(requestForCreateModuleFeatureMapper(values));
    if (responseCreateModuleFeatureMapper) {
      dispatch(reset());
    }
  };
  useEffect(() => {
    dispatch(requestForAllModuleFeatureMapper());
  }, [
    responseCreateModuleFeatureMapper,
    responseDeleteModuleFeatureMapper,
    responseUpdateModuleFeatureMapper,
  ]);
  useEffect(() => {
    dispatch(requestForAllModuleFeatureMapper());
  }, []);
  useEffect(() => {
    dispatch(requestForAllSubModule(selectedModuleID));
  }, [selectedModuleID]);

  const onDeleteHandler = (data) => {
    dispatch(requestForDeleteModuleFeatureMapper(data?.id));
    if (responseDeleteModuleFeatureMapper) {
      dispatch(reset());
    }
  };
  const onStatusChangeHandler = (row) => {
    dispatch(
      requestForUpdateModuleFeatureMapper({ ...row, isActive: !row.isActive }),
    );
    if (responseUpdateModuleFeatureMapper) {
      dispatch(reset());
    }
  };
  const onEditHandler = (data) => {
    navigate(`./${data?.id}`);
  };
  return (
    <Fragment>
      <SubHeader title={"Module Feature Mapping"} />
      <Paper sx={{ padding: "30px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Formik
              initialValues={moduleFeatureMapperInitial}
              validationSchema={moduleFeatureMapperSchema}
              onSubmit={onSubmit}
            >
              {({ handleChange, handleBlur, isValid, errors }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <GenericDropdown
                        label={"Mobule Name"}
                        data={allModules?.map((el) => {
                          return {
                            value: el.id,
                            label: el.moduleName,
                          };
                        })}
                        name="moduleID"
                        id="moduleID"
                        onChange={(evt) => {
                          setSelectedModuleID(evt.target.value);
                          handleChange(evt);
                        }}
                        onBlur={(evt) => {
                          setSelectedModuleID(evt.target.value);
                          handleBlur(evt);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <GenericDropdown
                        label={"Sub Mobule Name"}
                        data={[
                          { value: 0, label: "Default Sub Module" },
                          ...subModules,
                        ]}
                        name="subModuleID"
                        id="subModuleID"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <GenericDropdown
                        label={"Feature Name"}
                        data={allFeatures?.map((el) => {
                          return {
                            value: el?.id,
                            label: el?.featureName,
                          };
                        })}
                        name="featureID"
                        id="featureID"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <GenericLoadingButton disabled={!isValid} type="submit">
                        {SUBMIT_LABEL}
                      </GenericLoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
          <Grid item xs={12}>
            <GenericDataGrid
              columns={columns}
              rows={allModuleFeatureMappers?.map((row, index) => {
                return {
                  serialNo: index++,
                  ...row,
                };
              })}
            />
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};
export default AddMapper;
