export const LOGIN_WITH_EMAIL_AND_PASSWORD = "Login with Email & Password!";
export const LOGIN = "Login!";
export const LOGIN_WITH_MOBILE = "Login with Mobile!";
export const VERIFY_OTP = "Verify OTP";
export const REGISTER_AND_LOGIN = "Register / Login";
export const COMPANY_TYPE_LIST = [
  {
    value: "Proprietorship",
    label: "Proprietorship",
  },
  {
    value: "Partnership Firm",
    label: "Partnership Firm",
  },
  {
    value: "Pvt. Ltd. Company",
    label: "Pvt. Ltd. Company",
  },
  {
    value: "LLP",
    label: "LLP",
  },
];
export const COMPANY_REGISTRATION_FORM_LABEL = "Company Registration";
export const COMPANY_DETAILS_LABEL = "Your Company Details";
export const COMPANY_NAME_LABEL = "Company Name";
export const CONTACT_PERSON_LABEL = "Contact Person";
export const COMPANY_EMAIL_LABEL = "Company Email";
export const COMPANY_MOBILE_LABEL = "Company Mobile";
export const PINCODE_LABEL = "Pincode";
export const STATE_LABEL = "State";
export const CITY_LABEL = "City";
export const LOCALITY_LABEL = "Locality";
export const DISTRICT_LABEL = "District";
export const POSI_OFFICE_LABEL = "Post Office"
export const LANDMARK_LABEL = "Landmark";
export const ADDRESS_LABEL = "Address";
export const COMPANY_TYPE_LABEL = "Company Type";
export const INDIVIDUAL_PAN_NO_LABEL = "PAN No";
export const FIRM_PAN_NO_LABEL = "Firm PAN No";
export const CIN_NO_LABEL = "CIN No";
export const INC_NO_LABEL = "Incorporation No";
export const GST_NO_LABEL = "GST No";
export const COMPANY_WEBSITE_LABEL = "Company Website";
export const IBA_APPROVAL_LABEL = "IBA Approval";
export const IBA_APPROVAL_LIST = [
  { value: "Not Approved", label: "Not Approved" },
  { value: "Approved", label: "Approved" },
];
export const IBA_APPROVAL_CODE_LABEL = "IBA Approval Code";
export const IBA_APPROVAL_CODE_VALIDITY_LABEL = "IBA Code valid Till";
export const REGISTRATION_COMPLETE_LABEL = "Registration Complete";
export const MOBILE_NUMBER_LABEL = "Mobile Number";
export const EMAIL_ADDRESS_LABEL = "Email Address";
export const OTP_LABEL = "Your OTP";
export const PASSWORD_LABEL = "Your Password";
export const PARENT_ROLE_LABEL = "Parent Role";
export const ROLE_NAME_LABEL = "Role Name";
export const MODULE_NAME_LABEL = "Module Name";
export const FEATURE_NAME_LABEL = "Feature Name";
export const BRANCH_OR_FRANCHISE_NAME_LABEL = "Branch/Franchise Name";
export const WEBSITE_ADDRESS_LABEL = "Website Address";
export const GST_NUMBER_LABEL = "GST Number";
export const REG_MOBILE_NUMBER_LABEL = "Reg. Mobile Number";
export const BRFR_TYPE_LABEL = "Entity Type";
export const BRFR_TYPE_LIST = [
  {
    value: "Branch",
    label: "Branch",
  },
  {
    value: "Franchise",
    label: "Franchise",
  }
];
export const ACCOUNT_TYPE_LIST = [
  {
    value: "Bank Account",
    label: "Bank Account",
  },
  {
    value: "UPI",
    label: "UPI",
  },
  {
    value: "Upload QR Code",
    label: "Upload QR Code",
  }
];
export const SELECT_BANK_LABEL = "Select Bank"
export const ACCOUNT_HOLDER_NAME_LABEL = "Account Holder Name"
export const ACCOUNT_NUMBER_LABEL = "Account Number"
export const ACCOUNT_IFSC_LABEL = "Account IFSC Code"
export const VPA_LABEL = "VPA/UPI"
export const VPA_SUFFIX_LABEL = "VPA Suffix"
export const ACCOUNT_TYPE_LABEL = "Account Type"
export const OWNER_NAME_LABEL = "Owner Name";
export const ROLE_LABEL = "Add Role";
export const MODULE_LABEL = "Add Module";
export const FEATURE_LABEL = "Add Feature";
export const BRFR_LABEL = "Add Branch Or Franchise"
export const UPDATE_ROLE_LABEL = "Update Role";
export const SUBMIT_LABEL = "Submit";
export const PARENT_MODULE_NAME_LABEL = "Parent Module Name";
export const UPDATE_MODULE_LABEL = "Update Module";
export const UPDATE_FEATURE_LABEL = "Update Feature";
export const ADD_EMPLOYEE_ENROLL_LABEL = "Add Employee";
export const ADD_PLAN_LABEL = "Add Plan";
export const UPDATE_PLAN_LABEL = "Update Plan";
export const UPDATE_LABEL = "Update";
export const UPDATE_EMPLOYEE_ENROLL_LABEL = "Update Employee";
export const PROFILE_LABEL = "Company Profile";
export const BASIC_DETAILS_LABEL = "Company Basic Details";
export const ADDRESS_DETAILS_LABEL = "Company Address Details";
export const REGISTRATION_DETAILS_LABEL = "Company Registration Details";
export const SIGNATURE_DETAILS_LABEL = "Company Signature Details";
export const PUNCH_LINE_LABEL = "Company Punch Line";
export const UPDATE_DETAILS_LABEL = "Update Details";
export const ADD_BANK_LABEL = "Add Bank";
export const BANK_LABEL = "Bank";
export const ACCOUNT_LABEL = "Account";
