import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import ContactlessIcon from "@mui/icons-material/Contactless";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DeleteIcon from "@mui/icons-material/Delete";
import DirectionsBusFilledIcon from "@mui/icons-material/DirectionsBusFilled";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import EditNoteIcon from "@mui/icons-material/EditNote";
import FactoryIcon from "@mui/icons-material/Factory";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import FlightClassIcon from "@mui/icons-material/FlightClass";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import MapIcon from "@mui/icons-material/Map";
import PollIcon from "@mui/icons-material/Poll";
import PostAddIcon from "@mui/icons-material/PostAdd";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import SavingsIcon from "@mui/icons-material/Savings";
import SettingsIcon from "@mui/icons-material/Settings";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import AccountManagementRoutes from "../../modules/erp/dashboard/account-management/account-management-routes";
import AccountsRoutes from "../../modules/erp/dashboard/account-management/accounts/accounts-routes";
import BanksRoutes from "../../modules/erp/dashboard/account-management/banks/banks-routes";
import AppConfigurationRoutes from "../../modules/erp/dashboard/app-settings/app-configuration/app-configuration-routes";
import AppSettingsRoutes from "../../modules/erp/dashboard/app-settings/app-settings-routes";
import PaymentModesRoutes from "../../modules/erp/dashboard/app-settings/payment-modes/payment-modes-routes";
import BrFrManagementRoutes from "../../modules/erp/dashboard/brfr-management/brfr-management-routes";
import BrFrRoutes from "../../modules/erp/dashboard/brfr-management/brfr/branch-routes";
import ContentManagementRoutes from "../../modules/erp/dashboard/content-management/content-management-routes";
import FeatureRoutes from "../../modules/erp/dashboard/content-management/features/features-routes";
import MapperRoutes from "../../modules/erp/dashboard/content-management/mapper/mapper-routes";
import ModulesRoutes from "../../modules/erp/dashboard/content-management/modules/modules-routes";
import PlanRoutes from "../../modules/erp/dashboard/content-management/plans/plans-routes";
import EmployeeEnrollRoutes from "../../modules/erp/dashboard/user-management/employee-enroll/employee-enroll-routes";
import RoleRoutes from "../../modules/erp/dashboard/user-management/role/role-routes";
import UserManagementRoutes from "../../modules/erp/dashboard/user-management/user-management-routes";
import ProfileRoutes from "../../modules/erp/dashboard/user-management/profile/profile-routes";
export const DASHBOARD_MENU_ITEMS = [
  {
    icon: <DashboardIcon />,
    index: 0,
    label: "Dashboard",
    path: "./erp",
    nestedItems: [],
  },
  {
    icon: <ManageSearchIcon />,
    index: 1,
    label: "Enquiry Management",
    nestedItems: [
      {
        icon: <QueryStatsIcon />,
        index: 2,
        label: "Enquiry",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 3,
            label: "Add",
            path: "./enquiry-management/enquiry/add",
          },
          {
            icon: <EditNoteIcon />,
            index: 4,
            label: "Update",
            path: "./enquiry-management/enquiry/update",
          },
          {
            icon: <DeleteIcon />,
            index: 5,
            label: "Delete",
            path: "./enquiry-management/enquiry/delete",
          },
        ],
      },
      {
        icon: <PollIcon />,
        index: 6,
        label: "Survey",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 7,
            label: "Add",
            path: "./enquiry/survey/add",
          },
          {
            icon: <EditNoteIcon />,
            index: 8,
            label: "Update",
            path: "./enquiry/survey/update",
          },
        ],
      },
      {
        icon: <FollowTheSignsIcon />,
        index: 9,
        label: "FollowUps",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 10,
            label: "Add",
            path: "./enquiry/followups/add",
          },
          {
            icon: <EditNoteIcon />,
            index: 11,
            label: "Update",
            path: "./enquiry/followups/update",
          },
        ],
      },
      {
        icon: <FormatQuoteIcon />,
        index: 12,
        label: "Quotations",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 13,
            label: "Add",
            path: "./enquiry/quotations/add",
          },
          {
            icon: <EditNoteIcon />,
            index: 14,
            label: "Update",
            path: "./enquiry/quotations/update",
          },
        ],
      },
      {
        icon: <InventoryIcon />,
        index: 15,
        label: "Packing Material",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 16,
            label: "Add",
            path: "./enquiry/packing material/add",
          },
          {
            icon: <EditNoteIcon />,
            index: 17,
            label: "Update",
            path: "./enquiry/packing material/update",
          },
        ],
      },
    ],
  },
  {
    icon: <AssuredWorkloadIcon />,
    index: 18,
    label: "Branch And Franchise Management",
    nestedItems: [
      {
        icon: <AccountTreeIcon />,
        index: 19,
        label: "Branch And Franchise",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 20,
            label: "Add",
            path: "./branch-and-franchise-management/branch-and-franchise",
          },
          {
            icon: <EditNoteIcon />,
            index: 21,
            label: "Update",
            path: "./branch-and-franchise-management/branch-and-franchise",
          },
          {
            icon: <DeleteIcon />,
            index: 22,
            label: "Delete",
            path: "./branch-and-franchise-management/branch-and-franchise",
          },
        ],
      },
      {
        icon: <FactoryIcon />,
        index: 23,
        label: "Franchise",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 24,
            label: "Add",
            path: "./brfr-management/franchise/add",
          },
          {
            icon: <EditNoteIcon />,
            index: 25,
            label: "Update",
            path: "./brfr-management/franchise/update",
          },
          {
            icon: <DeleteIcon />,
            index: 26,
            label: "Delete",
            path: "./brfr-management/franchise/delete",
          },
        ],
      },
    ],
  },
  {
    icon: <FolderCopyIcon />,
    index: 27,
    label: "Content Management",
    nestedItems: [
      {
        icon: <ViewModuleIcon />,
        index: 74,
        label: "Features",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 75,
            label: "Add",
            path: "./content-management/features",
          },
          {
            icon: <EditNoteIcon />,
            index: 76,
            label: "Update",
            path: "./content-management/features",
          },
          {
            icon: <DeleteIcon />,
            index: 77,
            label: "Delete",
            path: "./content-management/features",
          },
        ],
      },
      {
        icon: <ViewModuleIcon />,
        index: 28,
        label: "Modules And SubModules",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 29,
            label: "Add",
            path: "./content-management/modules-and-submodules",
          },
          {
            icon: <EditNoteIcon />,
            index: 30,
            label: "Update",
            path: "./content-management/modules-and-submodules",
          },
          {
            icon: <DeleteIcon />,
            index: 31,
            label: "Delete",
            path: "./content-management/modules-and-submodules",
          },
        ],
      },
      {
        icon: <FeaturedPlayListIcon />,
        index: 32,
        label: "Module SubModule Mapper",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 33,
            label: "Add",
            path: "./content-management/module-submodule-mapper",
          },
          {
            icon: <EditNoteIcon />,
            index: 34,
            label: "Update",
            path: "./content-management/module-submodule-mapper",
          },
          {
            icon: <DeleteIcon />,
            index: 35,
            label: "Delete",
            path: "./content-management/module-submodule-mapper",
          },
        ],
      },
      {
        icon: <MapIcon />,
        index: 36,
        label: "Plan",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 37,
            label: "Add",
            path: "./content-management/plan",
          },
          {
            icon: <EditNoteIcon />,
            index: 38,
            label: "Update",
            path: "./content-management/plan/update",
          },
          {
            icon: <DeleteIcon />,
            index: 39,
            label: "Delete",
            path: "./content-management/plan/delete",
          },
        ],
      },
    ],
  },
  {
    icon: <ManageAccountsIcon />,
    index: 40,
    label: "User Management",
    nestedItems: [
      {
        icon: <ViewModuleIcon />,
        index: 41,
        label: "Role",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 42,
            label: "Add",
            path: "./user-management/role",
          },
          {
            icon: <EditNoteIcon />,
            index: 43,
            label: "Update",
            path: "./user-management/role",
          },
          {
            icon: <DeleteIcon />,
            index: 44,
            label: "Delete",
            path: "./user-management/role",
          },
        ],
      },
      {
        icon: <FeaturedPlayListIcon />,
        index: 45,
        label: "Companies",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 46,
            label: "Add",
            path: "./brfr-management/franchise",
          },
          {
            icon: <EditNoteIcon />,
            index: 47,
            label: "Update",
            path: "./brfr-management/franchise/update",
          },
          {
            icon: <DeleteIcon />,
            index: 48,
            label: "Delete",
            path: "./brfr-management/franchise/delete",
          },
        ],
      },
      {
        icon: <MapIcon />,
        index: 49,
        label: "Employee Enroll",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 50,
            label: "Add",
            path: "./user-management/employee-enroll",
          },
          {
            icon: <EditNoteIcon />,
            index: 51,
            label: "Update",
            path: "./user-management/employee-enroll",
          },
          {
            icon: <DeleteIcon />,
            index: 52,
            label: "Delete",
            path: "./user-management/employee-enroll",
          },
        ],
      },
    ],
  },

  {
    icon: <LocalShippingIcon />,
    index: 61,
    label: "Vehicle Management",
    nestedItems: [
      {
        icon: <DirectionsBusFilledIcon />,
        index: 62,
        label: "Vehicles",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 63,
            label: "Add",
            path: "./brfr-management/franchise/add",
          },
          {
            icon: <EditNoteIcon />,
            index: 64,
            label: "Update",
            path: "./brfr-management/franchise/update",
          },
          {
            icon: <DeleteIcon />,
            index: 65,
            label: "Delete",
            path: "./brfr-management/franchise/delete",
          },
        ],
      },
      {
        icon: <FlightClassIcon />,
        index: 66,
        label: "Drivers",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 67,
            label: "Add",
            path: "./brfr-management/franchise/add",
          },
          {
            icon: <EditNoteIcon />,
            index: 68,
            label: "Update",
            path: "./brfr-management/franchise/update",
          },
          {
            icon: <DeleteIcon />,
            index: 69,
            label: "Delete",
            path: "./brfr-management/franchise/delete",
          },
        ],
      },
      {
        icon: <DocumentScannerIcon />,
        index: 70,
        label: "Documents",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 71,
            label: "Add",
            path: "./brfr-management/franchise/add",
          },
          {
            icon: <EditNoteIcon />,
            index: 72,
            label: "Update",
            path: "./brfr-management/franchise/update",
          },
          {
            icon: <DeleteIcon />,
            index: 73,
            label: "Delete",
            path: "./brfr-management/franchise/delete",
          },
        ],
      },
    ],
  },
  {
    icon: <ManageHistoryIcon />,
    index: 74,
    label: "Account Management",
    nestedItems: [
      {
        icon: <SavingsIcon />,
        index: 75,
        label: "Banks",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 76,
            label: "Add",
            path: "./account-management/banks",
          },
          {
            icon: <EditNoteIcon />,
            index: 77,
            label: "Update",
            path: "./account-management/banks",
          },
          {
            icon: <DeleteIcon />,
            index: 78,
            label: "Delete",
            path: "./account-management/banks",
          },
        ],
      },
      {
        icon: <AccountBalanceWalletIcon />,
        index: 79,
        label: "Accounts",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 80,
            label: "Add",
            path: "./account-management/accounts",
          },
          {
            icon: <EditNoteIcon />,
            index: 81,
            label: "Update",
            path: "./account-management/accounts",
          },
          {
            icon: <DeleteIcon />,
            index: 82,
            label: "Delete",
            path: "./account-management/accounts",
          },
        ],
      },
    ],
  },
  {
    icon: <AppSettingsAltIcon />,
    index: 83,
    label: "App Settings",
    nestedItems: [
      {
        icon: <ViewModuleIcon />,
        index: 54,
        label: "Template",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 55,
            label: "Add",
            path: "./brfr-management/branch/add",
          },
          {
            icon: <EditNoteIcon />,
            index: 56,
            label: "Update",
            path: "./branch-and-franchise-management/branch-and-franchise",
          },
          {
            icon: <DeleteIcon />,
            index: 57,
            label: "Delete",
            path: "./brfr-management/branch/delete",
          },
        ],
      },
      {
        icon: <FeaturedPlayListIcon />,
        index: 58,
        label: "Serial Number",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 59,
            label: "Add",
            path: "./brfr-management/franchise/add",
          },
          {
            icon: <EditNoteIcon />,
            index: 60,
            label: "Update",
            path: "./brfr-management/franchise/update",
          },
          {
            icon: <DeleteIcon />,
            index: 61,
            label: "Delete",
            path: "./brfr-management/franchise/delete",
          },
        ],
      },
      {
        icon: <SettingsIcon />,
        index: 84,
        label: "App Configuration",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 85,
            label: "Add",
            path: "./app-settings/app-configuration",
          },
          {
            icon: <EditNoteIcon />,
            index: 86,
            label: "Update",
            path: "./app-settings/app-configuration",
          },
          {
            icon: <DeleteIcon />,
            index: 87,
            label: "Delete",
            path: "./app-settings/app-configuration",
          },
        ],
      },
      {
        icon: <ContactlessIcon />,
        index: 88,
        label: "Payment Modes",
        nestedItems: [
          {
            icon: <PostAddIcon />,
            index: 89,
            label: "Add",
            path: "./app-settings/payment-modes",
          },
          {
            icon: <EditNoteIcon />,
            index: 90,
            label: "Update",
            path: "./app-settings/payment-modes",
          },
          {
            icon: <DeleteIcon />,
            index: 91,
            label: "Delete",
            path: "./app-settings/payment-modes",
          },
        ],
      },
    ],
  },
];
export const ROLE_NOT_REQUIRED_LIST = ["super admin", "admin"];
export const SUBMODULE_NAVIGATION_ROUTES = [
  {
    path: "role",
    element: <RoleRoutes />,
  },
  {
    path: "employee-enroll",
    element: <EmployeeEnrollRoutes />,
  },

  {
    path: "features",
    element: <FeatureRoutes />,
  },
  {
    path: "modules-and-submodules",
    element: <ModulesRoutes />,
  },
  {
    path: "module-submodule-mapper",
    element: <MapperRoutes />,
  },
  {
    path: "plan",
    element: <PlanRoutes />,
  },
  {
    path: "branch-and-franchise",
    element: <BrFrRoutes />,
  },
  {
    path: "banks",
    element: <BanksRoutes />,
  },
  {
    path: "accounts",
    element: <AccountsRoutes />,
  },
  {
    path: "app-configuration",
    element: <AppConfigurationRoutes />,
  },
  {
    path: "payment-modes",
    element: <PaymentModesRoutes />,
  },
  {
    path: "profile",
    element: <ProfileRoutes />,
  },
];
export const FEATURE_NAVIGATION_ROUTES = [
  "add",
  "update",
  "delete",
  "share",
  "download",
  "upload",
];
export const PARENT_MODULE_NAVIGATION_ROUTES = [
  {
    path: "user-management",
    element: <UserManagementRoutes />,
  },
  {
    path: "content-management",
    element: <ContentManagementRoutes />,
  },
  {
    path: "branch-and-franchise-management",
    element: <BrFrManagementRoutes />,
  },
  {
    path: "account-management",
    element: <AccountManagementRoutes />,
  },
  {
    path: "app-settings",
    element: <AppSettingsRoutes />,
  },
];
