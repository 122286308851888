import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  allPlans: [],
  currentPlan:{},
  requestCurrentPlan: false,
  responseCurrentPlan: false,
  failedCurrentPlan: false,
  requestAllPlan: false,
  requestCreatePlan: false,
  requestDeletePlan: false,
  requestUpdatePlan: false,
  failedAllPlan: false,
  responseAllPlan: false,
  responseCreatePlan: false,
  responseDeletePlan: false,
  responseUpdatePlan: false,
  failedCreatePlan: false,
  failedDeletePlan: false,
  failedUpdatePlan: false,
};
export const PlanSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {
    reset: (state) => {
      state.requestCurrentPlan = false;
      state.responseCurrentPlan = false;
      state.failedCurrentPlan = false;
      state.requestAllPlan = false;
      state.failedAllPlan = false;
      state.responseAllPlan = false;
      state.requestCreatePlan = false;
      state.responseCreatePlan = false;
      state.failedCreatePlan = false;
      state.requestUpdatePlan = false;
      state.responseUpdatePlan = false;
      state.failedUpdatePlan = false;
      state.requestDeletePlan = false;
      state.responseDeletePlan = false;
      state.failedDeletePlan = false;
    },
    requestForAllPlan: (state) => {
      state.requestAllPlan = true;
    },
    responseForAllPlan: (state, action) => {
      state.allPlans = action?.payload;
      state.responseAllPlan = true;
    },
    failedForAllPlan: (state) => {
      state.failedAllPlan = true;
    },
    requestForCreatePlan: (state) => {
      state.requestCreatePlan = true;
    },
    responseForCreatePlan: (state) => {
      state.responseCreatePlan = true;
    },
    failedForCreatePlan: (state) => {
      state.failedCreatePlan = true;
    },
    requestForDeletePlan: (state) => {
      state.requestDeletePlan = true;
    },
    responseForDeletePlan: (state) => {
      state.responseDeletePlan = true;
    },
    failedForDeletePlan: (state) => {
      state.failedDeletePlan = true;
    },
    requestForUpdatePlan: (state) => {
      state.requestUpdatePlan = true;
    },
    responseForUpdatePlan: (state) => {
      state.responseUpdatePlan = true;
    },
    failedForUpdatePlan: (state) => {
      state.failedUpdatePlan = true;
    },
    requestForCurrentPlan: (state) => {
      state.requestCurrentPlan = true;
    },
    responseForCurrentPlan: (state,action) => {
      state.currentPlan = action?.payload;
      state.responseCurrentPlan = true;
      
    },
    failedForCurrentPlan: (state) => {
      state.failedCurrentPlan = true;
    },
  },
});
export default PlanSlice.reducer;
export const {
  failedForCurrentPlan,
  requestForCurrentPlan,
  responseForCurrentPlan,
  failedForAllPlan,
  failedForCreatePlan,
  failedForDeletePlan,
  failedForUpdatePlan,
  requestForAllPlan,
  requestForCreatePlan,
  responseForCreatePlan,
  requestForDeletePlan,
  requestForUpdatePlan,
  reset,
  responseForAllPlan,
  responseForDeletePlan,
  responseForUpdatePlan,
} = PlanSlice.actions;
