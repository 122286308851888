import * as Yup from "yup";
import { NAME_WITH_CHARACTERS_REGEX } from "../../../utils/regular-expressions";
export const moduleFeatureMapperInitial = {
  moduleID: 0,
  subModuleID: 0,
  featureID: 0,
};

export const moduleFeatureMapperSchema = Yup.object().shape({
  moduleID: Yup.string("Please Select Module Name!").required(
    "Module Name Is Required!",
  ),
  subModuleID: Yup.string("Please Select SubModule Name!").required(
    "SubModule Name Is Required!",
  ),
  featureID: Yup.string("Please Select Feature Name!").required(
    "Feature Name Is Required!",
  ),
});
