import { Route, Routes } from "react-router-dom";
import CompanyProfile from "./company-profile";


const ProfileRoutes = () => {
  return (
    <Routes>
      <Route path={`/`} element={<CompanyProfile />} />
      
    </Routes>
  );
};
export default ProfileRoutes;
