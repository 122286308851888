import { Grid, Paper } from "@mui/material";
import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import GenericLoadingButton from "../../../../../../common-components/form-elements/genericLoadingButton";
import GenericInput from "../../../../../../common-components/form-elements/genericInput";
import SubHeader from "../../../../../../common-components/page-elements/SubHeader";
import {
  UPDATE_FEATURE_LABEL,
  SUBMIT_LABEL,
  FEATURE_NAME_LABEL,
} from "../../../../../../languages/en";

import {
  featureInitial,
  featureSchema,
} from "../../../../../../common-components/validator/feature-validation";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { requestForUpdateFeature } from "../../../../../../store/slices/FeatureSlice";

const UpdateFeature = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { allFeatures } = useSelector((state) => state.feature);
  const formik = useFormik({
    initialValues: featureInitial,
    validationSchema: featureSchema,
  });

  useEffect(() => {
    const res = allFeatures?.find((feature) => feature.id === +id);
    formik.setFieldValue("featureName", res?.featureName);
  }, [allFeatures]);

  const updateClick = () => {
    const { values, errors } = formik;
    if (!Object.keys(errors).length) {
      dispatch(requestForUpdateFeature({ ...values, id }));
    }
  };
  return (
    <Fragment>
      <SubHeader title={UPDATE_FEATURE_LABEL} />
      <Paper sx={{ padding: "30px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <GenericInput
                    label={FEATURE_NAME_LABEL}
                    name="featureName"
                    id="featureName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.featureName}
                  />
                  {formik.errors.featureName && formik.touched.featureName ? (
                    <p name={"featureName"} className="error">
                      {formik.errors.featureName}
                    </p>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericLoadingButton
                    type="submit"
                    disabled={
                      Object.keys(formik.errors).length > 0 && !formik?.isValid
                    }
                    onClick={updateClick}
                  >
                    {SUBMIT_LABEL}
                  </GenericLoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Fragment>
  );
};
export default UpdateFeature;
