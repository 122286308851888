import { Grid, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import { ErrorMessage, Form, Formik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LogoImage from "../../../Assets/Logos/epackbookLogoBlue.svg";
import GenericAutocomplete from "../../../common-components/form-elements/genericAutocomplete";
import GenericDatePicker from "../../../common-components/form-elements/genericDatePicker";
import GenericDropdown from "../../../common-components/form-elements/genericDropdown";
import GenericInput from "../../../common-components/form-elements/genericInput";
import GenericLoadingButton from "../../../common-components/form-elements/genericLoadingButton";
import GenericRadio from "../../../common-components/form-elements/genericRadio";
import SubHeader from "../../../common-components/page-elements/SubHeader";
import {
  companyInitial,
  companySchema,
} from "../../../common-components/validator/company-validation";
import {
  ADDRESS_LABEL,
  CIN_NO_LABEL,
  CITY_LABEL,
  COMPANY_DETAILS_LABEL,
  COMPANY_EMAIL_LABEL,
  COMPANY_MOBILE_LABEL,
  COMPANY_NAME_LABEL,
  COMPANY_REGISTRATION_FORM_LABEL,
  COMPANY_TYPE_LABEL,
  COMPANY_TYPE_LIST,
  COMPANY_WEBSITE_LABEL,
  CONTACT_PERSON_LABEL,
  DISTRICT_LABEL,
  FIRM_PAN_NO_LABEL,
  GST_NO_LABEL,
  IBA_APPROVAL_CODE_LABEL,
  IBA_APPROVAL_CODE_VALIDITY_LABEL,
  IBA_APPROVAL_LABEL,
  IBA_APPROVAL_LIST,
  INC_NO_LABEL,
  INDIVIDUAL_PAN_NO_LABEL,
  LANDMARK_LABEL,
  LOCALITY_LABEL,
  PINCODE_LABEL,
  POSI_OFFICE_LABEL,
  REGISTRATION_COMPLETE_LABEL,
  STATE_LABEL,
} from "../../../languages/en";
import { getAuthState, getCompanyState } from "../../../store/selectors";
import { requestForRegister } from "../../../store/slices/CompanySlice";
const Registration = () => {
  const [isIBARequired, setIsIBARequired] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector(getAuthState);
  const company = useSelector(getCompanyState);
  const { responseFromRegister, registerResponse } = company;
  const { loginResponse } = auth;
  const { id: authID } = loginResponse;
  let initialCompanyDetails = {
    authID: authID,
    companyName: "",
    contactPerson: "",
    email: "",
    companyMobile: "",
    companyAddress: "",
    state: "",
    city: "",
    landmark: "",
    locality: "",
    pincode: "",
    companyWebsite: "",
    companyLogo: "",
    companySignature: "",
    punchLine: "",
    companyProfile: "",
    district: "",
    postOffice: "",
    companyType: "",
  };
  const onSubmit = (values) => {
    initialCompanyDetails = {
      ...initialCompanyDetails,
      companyName: values.companyName,
      companyAddress: values.address,
      city: values.city,
      state: values.state,
      locality: values.locality,
      pincode: values.pincode,
      companyWebsite: values.companyWebsite,
      companyLogo: values.companyLogo,
      contactPerson: values.contactPerson,
      companyMobile: values.companyMobile,
      email: values.companyEmail,
      landmark: values.landmark,
      district: values.district,
      postOffice: values.postOffice,
      companyEmail: values.companyEmail,
    };
    dispatch(requestForRegister(initialCompanyDetails));
  };
  useEffect(() => {}, [responseFromRegister, registerResponse]);

  return (
    <Fragment>
      <SubHeader title={COMPANY_REGISTRATION_FORM_LABEL} />
      <Paper sx={{ height: "100%", p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              srcSet={`${LogoImage}?w=120&h=120&fit=crop&auto=format&dpr=2 2x`}
              src={`${LogoImage}?w=120&h=120&fit=crop&auto=format`}
              alt={"logo"}
              width={220}
              loading="lazy"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Formik
                  initialValues={companyInitial}
                  validationSchema={companySchema}
                  onSubmit={onSubmit}
                >
                  {({ handleBlur, handleChange, errors, values }) => (
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ my: 2 }}>
                          <Typography variant="h6" fontWeight={600}>
                            {COMPANY_DETAILS_LABEL}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <GenericInput
                            label={COMPANY_NAME_LABEL}
                            name="companyName"
                            id="companyName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            name={"companyName"}
                            component="p"
                            className="error"
                          ></ErrorMessage>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <GenericInput
                            label={CONTACT_PERSON_LABEL}
                            name="contactPerson"
                            id="contactPerson"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            name={"contactPerson"}
                            component="p"
                            className="error"
                          ></ErrorMessage>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <GenericInput
                            label={COMPANY_EMAIL_LABEL}
                            name="companyEmail"
                            id="companyEmail"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            name={"companyEmail"}
                            component="p"
                            className="error"
                          ></ErrorMessage>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <GenericInput
                            label={COMPANY_MOBILE_LABEL}
                            name="companyMobile"
                            id="companyMobile"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            name={"companyMobile"}
                            component="p"
                            className="error"
                          ></ErrorMessage>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <GenericInput
                            label={COMPANY_WEBSITE_LABEL}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="companyWebsite"
                            id="companyWebsite"
                          />
                          <ErrorMessage
                            name={"companyWebsite"}
                            component="p"
                            className="error"
                          ></ErrorMessage>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <GenericDropdown
                            label={COMPANY_TYPE_LABEL}
                            data={COMPANY_TYPE_LIST}
                            selected={COMPANY_TYPE_LIST[0].value}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="companyType"
                            id="companyType"
                          />
                          <ErrorMessage
                            name={"companyType"}
                            component="p"
                            className="error"
                          ></ErrorMessage>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <GenericInput
                            label={ADDRESS_LABEL}
                            name="address"
                            id="address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            multiline
                            rows={1}
                          />
                          <ErrorMessage
                            name={"address"}
                            component="p"
                            className="error"
                          ></ErrorMessage>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <GenericAutocomplete
                            label={LOCALITY_LABEL}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="locality"
                            id="locality"
                          />
                          <ErrorMessage
                            name={"locality"}
                            component="p"
                            className="error"
                          ></ErrorMessage>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <GenericInput
                            label={LANDMARK_LABEL}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="landmark"
                            id="landmark"
                          />
                          <ErrorMessage
                            name={"landmark"}
                            component="p"
                            className="error"
                          ></ErrorMessage>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <GenericInput
                            label={DISTRICT_LABEL}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="district"
                            id="district"
                          />
                          <ErrorMessage
                            name={"district"}
                            component="p"
                            className="error"
                          ></ErrorMessage>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <GenericInput
                            label={POSI_OFFICE_LABEL}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="postOffice"
                            id="postOffice"
                          />
                          <ErrorMessage
                            name={"postOffice"}
                            component="p"
                            className="error"
                          ></ErrorMessage>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <GenericAutocomplete
                            label={CITY_LABEL}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="city"
                            id="city"
                          />
                          <ErrorMessage
                            name={"city"}
                            component="p"
                            className="error"
                          ></ErrorMessage>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <GenericAutocomplete
                            label={STATE_LABEL}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="state"
                            id="state"
                          />
                          <ErrorMessage
                            name={"state"}
                            component="p"
                            className="error"
                          ></ErrorMessage>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <GenericInput
                            label={PINCODE_LABEL}
                            name="pincode"
                            id="pincode"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            name={"pincode"}
                            component="p"
                            className="error"
                          ></ErrorMessage>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} md={4}>
                              <GenericInput
                                label={CIN_NO_LABEL}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="cinNo"
                                id="cinNo"
                              />
                              <ErrorMessage
                                name={"cinNo"}
                                component="p"
                                className="error"
                              ></ErrorMessage>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <GenericInput
                                label={INC_NO_LABEL}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="incNo"
                                id="incNo"
                              />
                              <ErrorMessage
                                name={"incNo"}
                                component="p"
                                className="error"
                              ></ErrorMessage>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <GenericInput
                                label={INDIVIDUAL_PAN_NO_LABEL}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="panNo"
                                id="panNo"
                              />
                              <ErrorMessage
                                name={"panNo"}
                                component="p"
                                className="error"
                              ></ErrorMessage>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <GenericInput
                                label={FIRM_PAN_NO_LABEL}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="firmPanNo"
                                id="firmPanNo"
                              />
                              <ErrorMessage
                                name={"firmPanNo"}
                                component="p"
                                className="error"
                              ></ErrorMessage>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <GenericInput
                                label={GST_NO_LABEL}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="gstNo"
                                id="gstNo"
                              />
                              <ErrorMessage
                                name={"gstNo"}
                                component="p"
                                className="error"
                              ></ErrorMessage>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item md={12}>
                          <Typography
                            variant={"h6"}
                            fontWeight={600}
                            sx={{ my: 1 }}
                          >
                            {IBA_APPROVAL_LABEL}
                          </Typography>

                          <GenericRadio
                            orientation="row"
                            name="ibaApproval"
                            id="ibaApproval"
                            options={IBA_APPROVAL_LIST}
                            defaultValue={IBA_APPROVAL_LIST[0].value}
                            onBlur={handleBlur}
                            onChange={(evt) => {
                              setIsIBARequired(
                                evt.target.value === IBA_APPROVAL_LIST[1].value
                                  ? true
                                  : false
                              );
                            }}
                          />
                          <ErrorMessage
                            name={"ibaApproval"}
                            component="p"
                            className="error"
                          ></ErrorMessage>
                        </Grid>
                        {isIBARequired && (
                          <Fragment>
                            <Grid item xs={12} md={6}>
                              <GenericInput
                                label={IBA_APPROVAL_CODE_LABEL}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="ibaCode"
                                id="ibaCode"
                              />
                              <ErrorMessage
                                name={"ibaCode"}
                                component="p"
                                className="error"
                              ></ErrorMessage>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <GenericDatePicker
                                label={IBA_APPROVAL_CODE_VALIDITY_LABEL}
                                name="ibaValidity"
                                id="ibaValidity"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                defaultValue={dayjs(new Date())}
                                disablePast
                                closeOnSelect={true}
                              />
                              <ErrorMessage
                                name={"ibaValidity"}
                                component="p"
                                className="error"
                              ></ErrorMessage>
                            </Grid>
                          </Fragment>
                        )}
                        <Grid item md={12} xs={12}>
                          <GenericLoadingButton
                            sx={{ my: 2, float: "right" }}
                            type="submit"
                          >
                            {REGISTRATION_COMPLETE_LABEL}
                          </GenericLoadingButton>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};
export default Registration;
