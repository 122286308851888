import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  requestToRegister: false,
  requestToUpdateCompany: false,
  responseToUpdateCompany: false,
  failedToUpdateCompany: false,
  requestToCurrentCompany: false,
  responseFromRegister: false,
  failedToRegister: false,
  registerResponse: {},
  currentCompany: {},
  getCurrentCompany: false,
  responseToCurrentCompany: false,
  failedToCurrentCompany: false
};
export const CompanySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    requestForRegister: (state) => {
      state.requestToRegister = true;
    },

    responseForRegister: (state, action) => {
      state.registerResponse = action?.payload;
      state.responseFromRegister = true;
    },
    failedToRegister: (state, action) => {
      state.failedToRegister = true;
      state.registerResponse = action?.payload;
    },
    requestToReset: (state) => {
      state = initialState;
    },
    saveCurrentCompany: (state, action) =>{
      state.getCurrentCompany = true;
      state.currentCompany = action?.payload;
    },
    requestForCurrentCompany: (state) =>{
      state.requestToCurrentCompany = true;
      
    },
    responseForCurrentCompany: (state, action) => {
      state.currentCompany = action?.payload;
      state.responseToCurrentCompany = true;
    },
    failedForCurrentCompany: (state) =>{
      state.failedToCurrentCompany = true;
      
    },
    responseForUpdateCompany: (state) => {
      state.responseToUpdateCompany = true
      
    },
    failedForUpdateCompany: (state) =>{
      state.failedToUpdateCompany = true;
      
    },
    requestForUpdateCompany: (state) =>{
      state.responseToUpdateCompany = true;
      
    }

  },
});
export default CompanySlice.reducer;
export const {
  requestForCurrentCompany,
  failedForUpdateCompany,
  requestForUpdateCompany,
  responseForUpdateCompany,
  failedToRegister,
  requestForRegister,
  responseForRegister,
  requestToReset,
  failedForCurrentCompany,
  saveCurrentCompany,
  responseForCurrentCompany
} = CompanySlice.actions;
