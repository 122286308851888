import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import React, { useRef } from "react";
const GenericAutocomplete = (props) => {
  const { options = [], defaultValue, label, ...otherProps } = props;
  return (
    <Autocomplete
      autoHighlight
      onSelect={props?.getValue}
      selectOnFocus={props?.getValue}
      onChange={props?.getValue}
      options={options}
      getOptionLabel={(option) => option.label || ""}
      defaultValue={defaultValue}
      {...props}
      freeSolo
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label}
            size="small"
            fullwidth={"true"}
            {...otherProps}
          />
        );
      }}
    />
  );
};
export default GenericAutocomplete;
