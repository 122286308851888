import "./App.css";
import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthRouting from "./modules/auth/auth.routing";
import _store, { _persistorStore } from "./store/index";
import { PersistGate } from "redux-persist/integration/react";
import GenericBackdrop from "./common-components/page-elements/genericBackdrop";
import { Provider } from "react-redux";
import ERPRouting from "./modules/erp/erp.routing";
import { AuthProvider } from "./providers/AuthProvider";


function App() {
  return (
    <React.StrictMode>
      <Provider store={_store}>
        <PersistGate loading={null} persistor={_persistorStore}>
          <Suspense fallback={<GenericBackdrop />}>
            <AuthProvider>
              <BrowserRouter>
                <Routes>
                  <Route path="/*" exact element={<AuthRouting />} />
                  <Route path="/erp/*" exact element={<ERPRouting />} />
                </Routes>
              </BrowserRouter>
            </AuthProvider>
          </Suspense>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
}

export default App;
