import { Hidden } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { getAuthState } from "../../store/selectors";
import { updateTreeVisibility } from "../../utils";
import { DASHBOARD_MENU_ITEMS } from "../constants";
import DashboardIcon from "@mui/icons-material/Dashboard";
import NestedList from "./nested-list";
import { getAuthFeatureList } from "../../utils";
const SideMenu = () => {
  const auth = useSelector(getAuthState);
  const { authDetails } = auth;
  const featureList = getAuthFeatureList(authDetails)
  const menuItems = updateTreeVisibility(featureList, DASHBOARD_MENU_ITEMS);
  menuItems.splice(0, 1, {
    icon: <DashboardIcon/>,
    index: 0,
    label: "Dashboard",
    
    path: "./erp",
    visible: true,
  });
  return (
    <React.Fragment>
      <Hidden mdDown>
        <NestedList items={menuItems} />
      </Hidden>
    </React.Fragment>
  );
};
export default SideMenu;
