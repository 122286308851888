import { Grid, Paper } from "@mui/material";
import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import GenericLoadingButton from "../../../../../../common-components/form-elements/genericLoadingButton";
import GenericInput from "../../../../../../common-components/form-elements/genericInput";
import SubHeader from "../../../../../../common-components/page-elements/SubHeader";
import {
  UPDATE_ROLE_LABEL,
  ROLE_NAME_LABEL,
  SUBMIT_LABEL,
} from "../../../../../../languages/en";

import {
  roleInitial,
  roleSchema,
} from "../../../../../../common-components/validator/role-validation";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { requestForUpdateRole } from "../../../../../../store/slices/RoleSlice";

const UpdateRole = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { allRole } = useSelector((state) => state.role);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    setRoles(() => {
      return allRole?.map((el) => {
        return {
          value: el?.id,
          label: el?.roleName,
        };
      });
    });
  }, [allRole]);
  const formik = useFormik({
    initialValues: roleInitial,
    validationSchema: roleSchema,
  });

  useEffect(() => {
    const res = allRole?.find((role) => role.id === +id);
    formik.setFieldValue("roleName", res?.roleName);
    formik.setFieldValue("parentRoleID", res?.parentRoleID);
  }, [allRole]);

  const updateClick = () => {
    const { values, errors } = formik;
    if (!Object.keys(errors).length) {
      dispatch(requestForUpdateRole({ ...values, id }));
    }
  };
  return (
    <Fragment>
      <SubHeader title={UPDATE_ROLE_LABEL} />
      <Paper sx={{ padding: "30px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <GenericInput
                    label={ROLE_NAME_LABEL}
                    name="roleName"
                    id="roleName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.roleName}
                  />
                  {formik.errors.roleName && formik.touched.roleName ? (
                    <p name={"roleName"} className="error">
                      {formik.errors.roleName}
                    </p>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericLoadingButton
                    type="submit"
                    disabled={
                      Object.keys(formik.errors).length > 0 && !formik?.isValid
                    }
                    onClick={updateClick}
                  >
                    {SUBMIT_LABEL}
                  </GenericLoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Fragment>
  );
};
export default UpdateRole;
