import { Route, Routes } from "react-router-dom";
import AddFeature from "./add";
import UpdateFeature from "./update";

const FeatureRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={<AddFeature />} />
        <Route path="/:id" element={<UpdateFeature />} />
        
      </Routes>
    );
  };
  export default FeatureRoutes;