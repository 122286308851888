import { Route, Routes } from "react-router-dom";

import AddBanks from "./add";
import UpdateBanks from "./update";

const BanksRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AddBanks />} />
      <Route path="/:id" element={<UpdateBanks />} />
    </Routes>
  );
};
export default BanksRoutes;
