import { registrationService } from "../../../services/company";

import { put, takeLatest } from "redux-saga/effects";
import {
  getAllModuleService,
  deleteModuleService,
  updateModuleService,
  createModuleService,
  getAllSubModuleService,
} from "../../../services/module";
import {
  failedForAllModule,
  failedForAllSubModule,
  failedForCreateModule,
  failedForDeleteModule,
  failedForUpdateModule,
  requestForAllModule,
  requestForAllSubModule,
  requestForCreateModule,
  requestForDeleteModule,
  requestForUpdateModule,
  responseForAllModule,
  responseForAllSubModule,
  responseForCreateModule,
  responseForDeleteModule,
  responseForUpdateModule,
} from "../../slices/ModuleSlice";

function* getAllModuleSaga(action) {
  try {
    const response = yield getAllModuleService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForAllModule(response?.data));
    } else {
      yield put(failedForAllModule(response));
    }
  } catch (error) {
    yield put(failedForAllModule(error));
  }
}

function* getAllSubModuleSaga(action) {
  try {
    const response = yield getAllSubModuleService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForAllSubModule(response?.data));
    } else {
      yield put(failedForAllSubModule(response));
    }
  } catch (error) {
    yield put(failedForAllSubModule(error));
  }
}

function* createModuleSaga(action) {
  try {
    const response = yield createModuleService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForCreateModule());
    } else {
      yield put(failedForCreateModule(response));
    }
  } catch (error) {
    yield put(failedForCreateModule(error));
  }
}

function* deleteModuleSaga(action) {
  try {
    const response = yield deleteModuleService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForDeleteModule(response));
    } else {
      yield put(failedForDeleteModule(response));
    }
  } catch (error) {
    yield put(failedForDeleteModule(error));
  }
}
function* updateModuleSaga(action) {
  try {
    const response = yield updateModuleService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForUpdateModule(response));
    } else {
      yield put(failedForUpdateModule(response));
    }
  } catch (error) {
    yield put(failedForUpdateModule(error));
  }
}

export function* watcherCreateModuleSaga() {
  yield takeLatest(requestForCreateModule.type, createModuleSaga);
}
export function* watcherAllModuleSaga() {
  yield takeLatest(requestForAllModule.type, getAllModuleSaga);
}
export function* watcherAllSubModuleSaga() {
  yield takeLatest(requestForAllSubModule.type, getAllSubModuleSaga);
}

export function* watcherDeleteModuleSaga() {
  yield takeLatest(requestForDeleteModule.type, deleteModuleSaga);
}
export function* watcherUpdateModuleSaga() {
  yield takeLatest(requestForUpdateModule.type, updateModuleSaga);
}
