import { Grid, Paper } from "@mui/material";
import { Fragment, useMemo, useState, useEffect, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Form, Formik, useFormik } from "formik";
import {
  MODULE_LABEL,
  MODULE_NAME_LABEL,
  PARENT_MODULE_NAME_LABEL,
  SUBMIT_LABEL,
} from "../../../../../../languages/en";
import SubHeader from "../../../../../../common-components/page-elements/SubHeader";
import GenericInput from "../../../../../../common-components/form-elements/genericInput";
import GenericLoadingButton from "../../../../../../common-components/form-elements/genericLoadingButton";
import GenericDataGrid from "../../../../../../common-components/form-elements/genericDataGrid";
import GenericDropdown from "../../../../../../common-components/form-elements/genericDropdown";
import {
  moduleInitial,
  moduleSchema,
} from "../../../../../../common-components/validator/module-validation";
import {
  requestForAllModule,
  requestForCreateModule,
  requestForDeleteModule,
  requestForUpdateModule,
  reset,
  responseForCreateModule,
} from "../../../../../../store/slices/ModuleSlice";
import { useNavigate } from "react-router-dom";
import GenericAction from "../../../../../../common-components/form-elements/genericAction";
import GenericSwitch from "../../../../../../common-components/form-elements/genericSwitch";

const AddModule = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let {
    allModules,
    responseCreateModule,
    responseDeleteModule,
    responseUpdateModule,
  } = useSelector((state) => state.module);
  const [modules, setModules] = useState([]);
  const columns = useMemo(() => {
    return [
      {
        field: "serialNo",
        headerName: "Serial No.",
        width: 90,
        renderCell: (data) => {
          return data.row.serialNo + 1;
        },
      },
      { field: "id", headerName: "ID.", width: 90 },
      {
        field: "moduleName",
        headerName: "Module Name",
        width: 150,
        editable: false,
      },
      {
        field: "isActive",
        headerName: "Status",
        width: 250,
        editable: false,
        renderCell: (data) => {
          return (
            <GenericSwitch
              start={"Active"}
              end={"Inactive"}
              value={data?.row?.isActive}
              onChange={() => onStatusChangeHandler(data?.row)}
            />
          );
        },
      },
      {
        field: "createdAt",
        headerName: "Created Date",
        width: 150,
        editable: false,
        renderCell: (data) => {
          return new Date(data?.row?.createdAt).toDateString();
        },
      },
      {
        field: "updatedAt",
        headerName: "Updated Date",
        width: 150,
        editable: false,
        renderCell: (data) => {
          return new Date(data?.row?.updatedAt).toDateString();
        },
      },
      {
        headerName: "Action",
        width: 150,
        field: "",
        editable: false,
        renderCell: (data) => {
          return (
            <GenericAction
              data={data?.row}
              onDeleteHandler={(data) => onDeleteHandler(data)}
              onEditHandler={(data) => onEditHandler(data)}
              deleteRequired={true}
              editRequired={true}
            />
          );
        },
      },
    ];
  });

  const onSubmit = (values) => {
    dispatch(requestForCreateModule(values));
    if (responseForCreateModule) {
      dispatch(reset());
    }
  };
  useEffect(() => {
    dispatch(requestForAllModule());
  }, [responseCreateModule, responseDeleteModule, responseUpdateModule]);
  useEffect(() => {
    dispatch(requestForAllModule());
  }, []);
  useEffect(() => {
    setModules(() => {
      return allModules?.map((el) => {
        return {
          value: el?.id,
          label: el?.moduleName,
        };
      });
    });
  }, [allModules]);

  const onDeleteHandler = (data) => {
    dispatch(requestForDeleteModule(data?.id));
    if (responseDeleteModule) {
      dispatch(reset());
    }
  };
  const onStatusChangeHandler = (row) => {
    dispatch(requestForUpdateModule({ ...row, isActive: !row.isActive }));
    if (responseUpdateModule) {
      dispatch(reset());
    }
  };
  const onEditHandler = (data) => {
    navigate(`./${data?.id}`);
  };

  return (
    <Fragment>
      <SubHeader title={MODULE_LABEL} />
      <Paper sx={{ padding: "30px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Formik
              initialValues={moduleInitial}
              validationSchema={moduleSchema}
              onSubmit={onSubmit}
            >
              {({ handleChange, handleBlur, isValid, errors }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <GenericInput
                        onChange={handleChange}
                        label={MODULE_NAME_LABEL}
                        onBlur={handleBlur}
                        name="moduleName"
                        id="moduleName"
                      />
                      <ErrorMessage
                        name={"moduleName"}
                        component="p"
                        className="error"
                      ></ErrorMessage>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <GenericDropdown
                        label={PARENT_MODULE_NAME_LABEL}
                        data={[
                          { value: 0, label: "Default Module" },
                          ...modules,
                        ]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="parentModuleID"
                        id="parentModuleID"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <GenericLoadingButton disabled={!isValid} type="submit">
                        {SUBMIT_LABEL}
                      </GenericLoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
          <Grid item xs={12}>
            <GenericDataGrid
              columns={columns}
              rows={allModules?.map((row, index) => {
                return {
                  serialNo: index++,
                  ...row,
                };
              })}
            />
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};
export default AddModule;
