import { all, fork } from "redux-saga/effects";
import {
  watcherGetAuthDetailsSaga,
  watcherLoginSaga,
  watcherRegisterEmployeeSaga,
  watcherVerifyOTPSaga,
} from "./auth-saga";
import {
  watcherAllBrFrByCompanyIdSaga,
  watcherCreateBrFrSaga,
  watcherDeleteBrFrSaga,
  watcherUpdateBrFrSaga,
} from "./brfr-saga";
import { watcherRegistrationSaga, watcherUpdateCompanySaga } from "./company-saga";
import {
  watcherAllEmployeeEnrollSaga,
  watcherCreateEmployeeEnrollSaga,
  watcherDeleteEmployeeEnrollSaga,
  watcherUpdateEmployeeEnrollSaga,
} from "./employee-enroll-saga";
import {
  watcherAllFeatureSaga,
  watcherCreateFeatureSaga,
  watcherDeleteFeatureSaga,
  watcherUpdateFeatureSaga,
} from "./feature-saga";
import {
  watcherAllModuleFeatureMapperSaga,
  watcherCreateModuleFeatureMapperSaga,
  watcherDeleteModuleFeatureMapperSaga,
  watcherUpdateModuleFeatureMapperSaga,
} from "./module-feature-mapper-saga";
import {
  watcherAllModuleSaga,
  watcherAllSubModuleSaga,
  watcherCreateModuleSaga,
  watcherDeleteModuleSaga,
  watcherUpdateModuleSaga,
} from "./module-saga";
import { watcherAllPlanSaga, watcherCreatePlanSaga, watcherDeletePlanSaga, watcherGetPlanByIdSaga, watcherUpdatePlanSaga } from "./plan-saga";
import {
  watcherAllRoleSaga,
  watcherCreateRoleSaga,
  watcherDeleteRoleSaga,
  watcherUpdateRoleSaga,
} from "./role-saga";

export default function* rootSaga() {
  yield all([
    fork(watcherLoginSaga),
    fork(watcherVerifyOTPSaga),
    fork(watcherRegistrationSaga),
    fork(watcherAllRoleSaga),
    fork(watcherCreateRoleSaga),
    fork(watcherDeleteRoleSaga),
    fork(watcherUpdateRoleSaga),
    fork(watcherAllModuleSaga),
    fork(watcherCreateModuleSaga),
    fork(watcherDeleteModuleSaga),
    fork(watcherUpdateModuleSaga),
    fork(watcherAllSubModuleSaga),
    fork(watcherAllFeatureSaga),
    fork(watcherCreateFeatureSaga),
    fork(watcherDeleteFeatureSaga),
    fork(watcherUpdateFeatureSaga),
    fork(watcherAllModuleFeatureMapperSaga),
    fork(watcherCreateModuleFeatureMapperSaga),
    fork(watcherDeleteModuleFeatureMapperSaga),
    fork(watcherUpdateModuleFeatureMapperSaga),
    fork(watcherAllBrFrByCompanyIdSaga),
    fork(watcherCreateBrFrSaga),
    fork(watcherDeleteBrFrSaga),
    fork(watcherUpdateBrFrSaga),
    fork(watcherAllEmployeeEnrollSaga),
    fork(watcherCreateEmployeeEnrollSaga),
    fork(watcherDeleteEmployeeEnrollSaga),
    fork(watcherUpdateEmployeeEnrollSaga),
    fork(watcherRegisterEmployeeSaga),
    fork(watcherUpdateCompanySaga),
    fork(watcherCreatePlanSaga),
    fork(watcherDeletePlanSaga),
    fork(watcherUpdatePlanSaga),
    fork(watcherAllPlanSaga),
    fork(watcherGetAuthDetailsSaga),
    fork(watcherGetPlanByIdSaga)

  ]);
}
