import { Box, Grid, Hidden, Paper, styled } from "@mui/material";
import React from "react";
import Header from "./header";
import SideMenu from "./side-menu";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const DashboardLayout = ({ children }) => {
  const [drawerLeftOpen, setDrawerLeftOpen] = React.useState(false);
  const [drawerRightOpen, setDrawerRightOpen] = React.useState(false);
  const openLeftDrawer = () => {
    setDrawerLeftOpen(!drawerLeftOpen);
  };
  const openRightDrawer = () => {
    setDrawerRightOpen(!drawerRightOpen);
  };
  return (
    <Box
      sx={{
        background: (theme) => theme.palette.primary.light,
        maxHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <Grid container spacing={0}>
        <Header
          openLeftDrawer={openLeftDrawer}
          drawerLeftOpen={drawerLeftOpen}
          openRightDrawer={openRightDrawer}
          drawerRightOpen={drawerRightOpen}
        />
      </Grid>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Hidden mdDown>
          <Grid item md={3}>
            <Item >
              <SideMenu />
            </Item>
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          md={9}
          sx={{ height:"92vh",  overflow: "auto" }}
        >
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};
export default DashboardLayout;
