import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  requestToGetAuthDetails: false,
  responseToGetAuthDetails: false,
  failedToGetAuthDetails: false,
  requestToVerifyOTP: false,
  responseToVerifyOTP: false,
  failedToVerifyOTP: false,
  requestToLogin: false,
  responseFromLogin: false,
  failedToLogin: false,
  requestRegisterEmployee: false,
  responseRegisterEmployee: false,
  failedRegisterEmployee: false,
  loginResponse: {},
  authDetails: {},
  error: {}
};
export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: (state) => {
      state.requestToGetAuthDetails = false;
      state.responseToGetAuthDetails = false;
      state.failedToGetAuthDetails = false;
      state.requestToVerifyOTP = false;
      state.responseToVerifyOTP = false;
      state.failedToVerifyOTP = false;
      state.requestToLogin = false;
      state.responseFromLogin = false;
      state.failedToLogin = false;
      state.requestRegisterEmployee = false;
      state.responseRegisterEmployee = false;
      state.failedRegisterEmployee = false;
    },
    requestToLogOut: (state) => {
      state = { ...initialState };
    },
    requestForVerifyOTP: (state) => {
      state.requestToVerifyOTP = true;
    },
    responseForVerifyOTP: (state, action) => {
      state.loginResponse = action?.payload
      state.responseToVerifyOTP = true;
    },
    failedForVerifyOTP: (state) => {
      state.failedToVerifyOTP = true;
    },
    requestForLogin: (state) => {
      state.requestToLogin = true;
    },
    responseForLogin: (state, action) => {
      state.loginResponse = action?.payload;
      state.responseFromLogin = true;
      state.failedToLogin = false;
    },
    failedToLogin: (state, action) => {
      state.failedToLogin = true;
      state.responseFromLogin = false;
      state.loginResponse = action?.payload;
    },
    requestForRegisterEmployee: (state) => {
      state.requestRegisterEmployee = true;
    },
    responseForRegisterEmployee: (state, action) => {
      state.responseRegisterEmployee = true;
    },
    failedToRegisterEmployee: (state, action) => {
      state.failedRegisterEmployee = true;
    },
    requestForGetAuthDetails: (state) => {
      state.requestToGetAuthDetails = true;
    },
    responseForGetAuthDetails: (state, action) => {
      state.responseToGetAuthDetails = true;
      state.authDetails = action?.payload;
    },
    failedToGetAuthDetails: (state, action) => {
      state.failedToGetAuthDetails = true;
      state.error = action?.payload
    },

  },
});
export default AuthSlice.reducer;
export const {
  resetAuth,
  requestForLogin,
  failedToLogin,
  responseForLogin,
  requestForVerifyOTP,
  failedForVerifyOTP,
  responseForVerifyOTP,
  requestToLogOut,
  failedToRegisterEmployee,
  requestForRegisterEmployee,
  responseForRegisterEmployee,
  failedToGetAuthDetails,
  requestForGetAuthDetails,
  responseForGetAuthDetails
} = AuthSlice.actions;
