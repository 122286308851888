import { put, takeLatest } from "redux-saga/effects";
import { getCompanyByIDService, registrationService, updateCompanyByIDService } from "../../../services/company";
import {
  failedForCurrentCompany,
  failedForUpdateCompany,
  failedToRegister,

  requestForCurrentCompany,

  requestForRegister,

  requestForUpdateCompany,

  responseForCurrentCompany,

  responseForRegister,
  responseForUpdateCompany,
} from "../../slices/CompanySlice";

function* registrationSaga(action) {
  try {
    const response = yield registrationService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForRegister(response?.data));
    } else {
      yield put(failedToRegister(response?.data));
    }
  } catch (error) {
    yield put(failedToRegister(error));
  }
}
function* updateCompanySaga(action) {
  try {
    const response = yield updateCompanyByIDService(action.payload, action?.payload?.id);
    const { code } = response;
    if (code === 200) {
      yield put(responseForUpdateCompany());
    } else {
      yield put(failedForUpdateCompany(response?.data));
    }
  } catch (error) {
    yield put(failedForUpdateCompany(error));
  }
}
function* getCompanySaga(action) {
  try {
    const response = yield getCompanyByIDService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForCurrentCompany());
    } else {
      yield put(failedForCurrentCompany(response?.data));
    }
  } catch (error) {
    yield put(failedForCurrentCompany(error));
  }
}


export function* watcherRegistrationSaga() {
  yield takeLatest(requestForRegister.type, registrationSaga);
}
export function* watcherUpdateCompanySaga() {
  yield takeLatest(requestForUpdateCompany.type, updateCompanySaga);
}
export function* watcherGetCompanySaga() {
  yield takeLatest(requestForCurrentCompany.type, getCompanySaga);
}
