import { Route, Routes } from "react-router-dom";
import AddPaymentModes from "./add";
import UpdatePaymentModes from "./update";

const PaymentModesRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AddPaymentModes />} />
      <Route path="/:id" element={<UpdatePaymentModes />} />
    </Routes>
  );
};
export default PaymentModesRoutes;
