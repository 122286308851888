import { put, takeLatest } from "redux-saga/effects";
import {
  
  deleteBrFrService,
  updateBrFrService,
  createBrFrService,
  getAllBrFrByCompanyIdService,
} from "../../../services/brfr";
import {
  failedForAllBrFrByCompanyId,
  failedForCreateBrFr,
  failedForDeleteBrFr,
  failedForUpdateBrFr,
  requestForAllBrFrByCompanyId,
  requestForCreateBrFr,
  requestForDeleteBrFr,
  requestForUpdateBrFr,
  responseForAllBrFrByCompanyId,
  responseForCreateBrFr,
  responseForDeleteBrFr,
  responseForUpdateBrFr,
} from "../../slices/BrFrSlice";

function* getAllBrFrByCompanyIdSaga(action) {
  try {
    const response = yield getAllBrFrByCompanyIdService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForAllBrFrByCompanyId(response?.data));
    } else {
      yield put(failedForAllBrFrByCompanyId(response));
    }
  } catch (error) {
    yield put(failedForAllBrFrByCompanyId(error));
  }
}

function* createBrFrSaga(action) {
  try {
    const response = yield createBrFrService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForCreateBrFr());
    } else {
      yield put(failedForCreateBrFr(response));
    }
  } catch (error) {
    yield put(failedForCreateBrFr(error));
  }
}

function* deleteBrFrSaga(action) {
  try {
    const response = yield deleteBrFrService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForDeleteBrFr(response));
    } else {
      yield put(failedForDeleteBrFr(response));
    }
  } catch (error) {
    yield put(failedForDeleteBrFr(error));
  }
}
function* updateBrFrSaga(action) {
  try {
    const response = yield updateBrFrService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForUpdateBrFr(response));
    } else {
      yield put(failedForUpdateBrFr(response));
    }
  } catch (error) {
    yield put(failedForUpdateBrFr(error));
  }
}

export function* watcherCreateBrFrSaga() {
  yield takeLatest(requestForCreateBrFr.type, createBrFrSaga);
}
export function* watcherAllBrFrByCompanyIdSaga() {
  yield takeLatest(requestForAllBrFrByCompanyId.type, getAllBrFrByCompanyIdSaga);
}

export function* watcherDeleteBrFrSaga() {
  yield takeLatest(requestForDeleteBrFr.type, deleteBrFrSaga);
}
export function* watcherUpdateBrFrSaga() {
  yield takeLatest(requestForUpdateBrFr.type, updateBrFrSaga);
}
