import { Route, Routes } from "react-router-dom";
import AddMapper from "./add";
import UpdateMapper from "./update";

const Mapperroutes = () => {
    return (
      <Routes>
        <Route path="/" element={<AddMapper />} />
        <Route path="/:id" element={<UpdateMapper />} />
        
        
      </Routes>
    );
  };
  export default Mapperroutes;