import { put, takeLatest } from "redux-saga/effects";
import {
  createEmployeeEnrollService,
  deleteEmployeeEnrollService,
  getAllEmployeeEnrollService,
  updateEmployeeEnrollService,
} from "../../../services/employeeEnroll";
import {
  failedForAllEmployeeEnroll,
  failedForCreateEmployeeEnroll,
  failedForDeleteEmployeeEnroll,
  failedForUpdateEmployeeEnroll,
  requestForAllEmployeeEnroll,
  requestForCreateEmployeeEnroll,
  requestForDeleteEmployeeEnroll,
  requestForUpdateEmployeeEnroll,
  responseForUpdateEmployeeEnroll,
  responseForCreateEmployeeEnroll,
  responseForDeleteEmployeeEnroll,
  responseForAllEmployeeEnroll,
} from "../../slices/EmployeeEnrollSlice";

function* getAllEmployeeEnrollSaga(action) {
  try {
    const response = yield getAllEmployeeEnrollService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForAllEmployeeEnroll(response?.data));
    } else {
      yield put(failedForAllEmployeeEnroll(response));
    }
  } catch (error) {
    yield put(failedForAllEmployeeEnroll(error));
  }
}

function* createEmployeeEnrollSaga(action) {
  try {
    const response = yield createEmployeeEnrollService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForCreateEmployeeEnroll());
    } else {
      yield put(failedForCreateEmployeeEnroll(response));
    }
  } catch (error) {
    yield put(failedForCreateEmployeeEnroll(error));
  }
}

function* deleteEmployeeEnrollSaga(action) {
  try {
    const response = yield deleteEmployeeEnrollService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForDeleteEmployeeEnroll(response));
    } else {
      yield put(failedForDeleteEmployeeEnroll(response));
    }
  } catch (error) {
    yield put(failedForDeleteEmployeeEnroll(error));
  }
}
function* updateEmployeeEnrollSaga(action) {
  try {
    const response = yield updateEmployeeEnrollService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForUpdateEmployeeEnroll(response));
    } else {
      yield put(failedForUpdateEmployeeEnroll(response));
    }
  } catch (error) {
    yield put(failedForUpdateEmployeeEnroll(error));
  }
}

export function* watcherCreateEmployeeEnrollSaga() {
  yield takeLatest(
    requestForCreateEmployeeEnroll.type,
    createEmployeeEnrollSaga,
  );
}
export function* watcherAllEmployeeEnrollSaga() {
  yield takeLatest(requestForAllEmployeeEnroll.type, getAllEmployeeEnrollSaga);
}

export function* watcherDeleteEmployeeEnrollSaga() {
  yield takeLatest(
    requestForDeleteEmployeeEnroll.type,
    deleteEmployeeEnrollSaga,
  );
}
export function* watcherUpdateEmployeeEnrollSaga() {
  yield takeLatest(
    requestForUpdateEmployeeEnroll.type,
    updateEmployeeEnrollSaga,
  );
}
