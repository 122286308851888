import { Fragment, useEffect, useMemo, useState } from "react";
import SubHeader from "../../../../../../common-components/page-elements/SubHeader";
import { Box, Grid, Paper, Typography } from "@mui/material";
import GenericDropdown from "../../../../../../common-components/form-elements/genericDropdown";
import GenericInput from "../../../../../../common-components/form-elements/genericInput";
import GenericLoadingButton from "../../../../../../common-components/form-elements/genericLoadingButton";

import {
  SUBMIT_LABEL,
  UPDATE_EMPLOYEE_ENROLL_LABEL,
} from "../../../../../../languages/en";
import GenericTreeMap from "../../../../../../common-components/page-elements/genericTreeMap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, Form, Formik, useFormik } from "formik";
import { groupByModuleID } from "../../../../../../utils";
import { requestForAllBrFr } from "../../../../../../store/slices/BrFrSlice";

import {
  employeeEnrollInitial,
  employeeEnrollSchema,
} from "../../../../../../common-components/validator/employee-enroll-validation";
import {
  requestForAllEmployeeEnroll,
  requestForCreateEmployeeEnroll,
  requestForDeleteEmployeeEnroll,
  requestForUpdateEmployeeEnroll,
  reset,
} from "../../../../../../store/slices/EmployeeEnrollSlice";
import { requestForRegisterEmployee } from "../../../../../../store/slices/AuthSlice";
import GenericRadio from "../../../../../../common-components/form-elements/genericRadio";

import GenericAction from "../../../../../../common-components/form-elements/genericAction";
import GenericSwitch from "../../../../../../common-components/form-elements/genericSwitch";

const UpdateEmployeeEnrollment = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [roles, setRoles] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [permissionError, setPermissionError] = useState("");
  const [permissions, setPermissions] = useState([]);
  let { allModuleFeatureMappers } = useSelector(
    (state) => state.moduleFeatureMapper,
  );
  let { allEmployeeEnrolls } = useSelector((state) => state.employeeEnroll);
  let { allBrFrs } = useSelector((state) => state.brFr);
  let { allRole } = useSelector((state) => state.role);
  const style = {
    display: "flex",
    alignItem: "center",
    justifyContent: "spaceBetween",
    width: "100%",
    border: "1px solid #C4C4C4",
    borderRadius: "4px",
    // color: formik.errors.shiftingLuggage && formik.touched.shiftingLuggage && '#d32f2f'
    // padding: '10px 14px'
  };

  const onSelect = (selectedKeys) => {
    setPermissionError(
      selectedKeys.length > 0 ? "" : "Please Select At Least One Permission!",
    );
  };
  const onCheck = (checkedKeys) => {
    setPermissionError(
      checkedKeys.length > 0 ? "" : "Please Select At Least One Permission!",
    );
    //   setPermissions(checkedKeys)
    //                       }
    //                       const onSubmit = (values) => {
    //                         setPermissionError(permissions.length > 0 ? "" : "Please Select Any One Permission!")
    //                         const {emailID, password,personName,
    //                           entityName,
    //                           brFrID,
    //                           mobileNumber,
    //                           roleID, } = values
    //                           dispatch(requestForCreateEmployeeEnroll({
    //                             personName,
    //                             entityName,
    //                             brFrID,
    //                             mobileNumber,
    //                             roleID,
    //                             permissions,
    //                             companyID:1
    //                           })) &&
    //                           dispatch(requestForRegisterEmployee({email:emailID, password}))
    //                           if(responseCreateEmployeeEnroll) {
    //                             dispatch(reset())
    //                           }
  };

  const updateClick = () => {
    const { values, errors } = formik;
    delete errors.emailID;
    delete errors.password;
    if (!Object.keys(errors).length) {
      dispatch(requestForUpdateEmployeeEnroll({ ...values, id }));
    }
  };
  useEffect(() => {
    const res = allRole
      ?.filter((el) => {
        if (
          (el?.roleName).toLowerCase() !== "super admin" &&
          (el?.roleName).toLowerCase() !== "admin"
        ) {
          return el;
        }
      })
      .map((el) => {
        return {
          value: el?.id,
          label: el?.roleName,
        };
      });

    setRoles(res);
  }, [allRole]);

  const formik = useFormik({
    initialValues: employeeEnrollInitial,
    validationSchema: employeeEnrollSchema,
  });
  useEffect(() => {
    setTreeData(groupByModuleID(allModuleFeatureMappers));
  }, [allModuleFeatureMappers]);

  useEffect(() => {
    const res = allEmployeeEnrolls?.find(
      (employeeEnroll) => employeeEnroll.id === +id,
    );

    formik.setFieldValue("personName", res?.personName);
    formik.setFieldValue("roleID", res?.roleID);
    formik.setFieldValue("entityName", res?.entityName);
    formik.setFieldValue("brFrID", res?.brFrID);
    formik.setFieldValue("mobileNumber", res?.mobileNumber);
    setPermissions(JSON.parse(res?.permissions));
  }, [allEmployeeEnrolls]);

  return (
    <Fragment>
      <SubHeader title={UPDATE_EMPLOYEE_ENROLL_LABEL} />
      <Paper sx={{ padding: "30px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <GenericInput
                label="Person Name"
                name="personName"
                id="personName"
                value={formik.values.personName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.personName && formik.touched.personName ? (
                <p name={"personName"} className="error">
                  {formik.errors.personName}
                </p>
              ) : null}
            </Grid>
            <Grid item md={4} xs={12}>
              <GenericDropdown
                label="Choose Entity"
                name="entityName"
                id="entityName"
                value={formik.values.entityName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                data={[
                  {
                    value: "Head Office",
                    label: "Head Office",
                  },
                  {
                    value: "Branch",
                    label: "Branch",
                  },
                  {
                    value: "Franchise",
                    label: "Franchise",
                  },
                ]}
              />
              {formik.errors.entityName && formik.touched.entityName ? (
                <p name={"entityName"} className="error">
                  {formik.errors.entityName}
                </p>
              ) : null}
            </Grid>
            <Grid item md={4} xs={12}>
              <GenericDropdown
                value={formik.values.brFrID}
                label="Choose Branch/Franschise"
                data={allBrFrs?.map((el) => {
                  return {
                    value: el?.id,
                    label: `${el?.brFrName} | ${el?.brFrType}`,
                  };
                })}
                name="brFrID"
                id="brFrID"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.brFrID && formik.touched.brFrID ? (
                <p name={"brFrID"} className="error">
                  {formik.errors.brFrID}
                </p>
              ) : null}
            </Grid>

            <Grid item md={4} xs={12}>
              <GenericInput
                value={formik.values.mobileNumber}
                name="mobileNumber"
                id="mobileNumber"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="Mobile Number"
              />
              {formik.errors.mobileNumber && formik.touched.mobileNumber ? (
                <p name={"mobileNumber"} className="error">
                  {formik.errors.mobileNumber}
                </p>
              ) : null}
            </Grid>

            <Grid item xs={12} md={12}>
              <Box sx={{ ...style }}>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#666666",
                    marginRight: "20px",
                    marginTop: "10px",
                    marginLeft: "15px",
                  }}
                >
                  Role Type *
                </Typography>
                <GenericRadio
                  options={roles}
                  name="roleID"
                  id="roleID"
                  value={formik.values.roleID}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{ display: "block" }}
                />
              </Box>
              {formik.errors.roleID && formik.touched.roleID ? (
                <p name={"roleID"} className="error">
                  {formik.errors.roleID}
                </p>
              ) : null}
            </Grid>
            <Grid item xs={12} sx={{ my: 2 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Allow Permissions
              </Typography>

              <GenericTreeMap
                defaultCheckedKeys={permissions}
                defaultExpandedKeys={permissions}
                checkable={true}
                data={treeData}
                onSelect={(selected) => {
                  onSelect(selected);
                }}
                onCheck={(selected) => {
                  onCheck(selected);
                }}
                id={"permissions"}
                name={"permissions"}
              />

              {permissionError?.length !== 0 && (
                <p name={"permissions"} className="error">
                  {permissionError}
                </p>
              )}
            </Grid>

            <Grid item md={12} xs={12}>
              <GenericLoadingButton
                onClick={updateClick}
                disabled={permissionError ? true : false}
                sx={{ my: 2, float: "right" }}
                type="submit"
              >
                {SUBMIT_LABEL}
              </GenericLoadingButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Fragment>
  );
};
export default UpdateEmployeeEnrollment;
