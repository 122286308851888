import { Route, Routes } from "react-router-dom";

import AddEmployeeEnrollment from "./add";
import UpdateEmployeeEnrollment from "./update";
const EmployeeEnrollRoutes = () => {
  return (
    <Routes>
      <Route path={`/`} element={<AddEmployeeEnrollment />} />
      <Route path={`/:id`} element={<UpdateEmployeeEnrollment />} />
    </Routes>
  );
};
export default EmployeeEnrollRoutes;
