import * as Yup from "yup";
import {
  OWNER_REGEX
} from "../../../utils/regular-expressions";

export const bankInitial = {
  bankName: "",

};
export const bankSchema = Yup.object().shape({
  bankName: Yup.string("Enter Bank Name.")
    .required("Bank Name Is Required")
    .matches(OWNER_REGEX, "Please Enter Valid Bank Name"),

});
