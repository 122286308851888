import { Route, Routes } from "react-router-dom";
import AddModule from "./add";
import UpdateModule from "./update";

const ModulesRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AddModule />} />
      <Route path="/:id" element={<UpdateModule />} />
    </Routes>
  );
};
export default ModulesRoutes;
