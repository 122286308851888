import { Route, Routes } from "react-router-dom";


import AddAppConfiguration from "./add";
import UpdateAppConfiguration from "./update";

const AppConfigurationRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AddAppConfiguration />} />
      <Route path="/:id" element={<UpdateAppConfiguration />} />
    </Routes>
  );
};
export default AppConfigurationRoutes;
