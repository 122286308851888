import { registrationService } from "../../../services/company";

import { put, takeLatest } from "redux-saga/effects";
import {
  getAllRoleService,
  deleteRoleService,
  updateRoleService,
  createRoleService,
} from "../../../services/role";
import {
  failedForAllRole,
  failedForCreateRole,
  failedForDeleteRole,
  failedForUpdateRole,
  requestForAllRole,
  requestForCreateRole,
  requestForDeleteRole,
  requestForUpdateRole,
  responseForAllRole,
  responseForCreateRole,
  responseForDeleteRole,
  responseForUpdateRole,
} from "../../slices/RoleSlice";

function* getAllRoleSaga(action) {
  try {
    const response = yield getAllRoleService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForAllRole(response?.data));
    } else {
      yield put(failedForAllRole(response));
    }
  } catch (error) {
    yield put(failedForAllRole(error));
  }
}

function* createRoleSaga(action) {
  try {
    const response = yield createRoleService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForCreateRole());
    } else {
      yield put(failedForCreateRole(response));
    }
  } catch (error) {
    yield put(failedForCreateRole(error));
  }
}

function* deleteRoleSaga(action) {
  try {
    const response = yield deleteRoleService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForDeleteRole(response));
    } else {
      yield put(failedForDeleteRole(response));
    }
  } catch (error) {
    yield put(failedForDeleteRole(error));
  }
}
function* updateRoleSaga(action) {
  try {
    const response = yield updateRoleService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForUpdateRole(response));
    } else {
      yield put(failedForUpdateRole(response));
    }
  } catch (error) {
    yield put(failedForUpdateRole(error));
  }
}

export function* watcherCreateRoleSaga() {
  yield takeLatest(requestForCreateRole.type, createRoleSaga);
}
export function* watcherAllRoleSaga() {
  yield takeLatest(requestForAllRole.type, getAllRoleSaga);
}

export function* watcherDeleteRoleSaga() {
  yield takeLatest(requestForDeleteRole.type, deleteRoleSaga);
}
export function* watcherUpdateRoleSaga() {
  yield takeLatest(requestForUpdateRole.type, updateRoleSaga);
}
