import { put, takeLatest } from "redux-saga/effects";
import {
  createPlanService,
  deletePlanService,
  getAllPlanService,
  getPlanByIdService,
  updatePlanService,
} from "../../../services/plan";
import {
  failedForAllPlan,
  failedForCreatePlan,
  failedForDeletePlan,
  failedForUpdatePlan,
  requestForAllPlan,
  requestForCreatePlan,
  requestForDeletePlan,
  requestForUpdatePlan,
  responseForUpdatePlan,
  responseForCreatePlan,
  responseForDeletePlan,
  responseForAllPlan,
  responseForCurrentPlan,
  failedForCurrentPlan,
  requestForCurrentPlan,
} from "../../slices/PlanSlice";

function* getAllPlanSaga(action) {
  try {
    const response = yield getAllPlanService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForAllPlan(response?.data));
    } else {
      yield put(failedForAllPlan(response));
    }
  } catch (error) {
    yield put(failedForAllPlan(error));
  }
}

function* createPlanSaga(action) {
  try {
    const response = yield createPlanService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForCreatePlan());
    } else {
      yield put(failedForCreatePlan(response));
    }
  } catch (error) {
    yield put(failedForCreatePlan(error));
  }
}

function* getPlanByIdSaga(action) {
  try {
    const response = yield getPlanByIdService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForCurrentPlan(response?.data));
    } else {
      yield put(failedForCurrentPlan(response));
    }
  } catch (error) {
    yield put(failedForCurrentPlan(error));
  }
}

function* deletePlanSaga(action) {
  try {
    const response = yield deletePlanService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForDeletePlan(response));
    } else {
      yield put(failedForDeletePlan(response));
    }
  } catch (error) {
    yield put(failedForDeletePlan(error));
  }
}
function* updatePlanSaga(action) {
  try {
    const response = yield updatePlanService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForUpdatePlan(response));
    } else {
      yield put(failedForUpdatePlan(response));
    }
  } catch (error) {
    yield put(failedForUpdatePlan(error));
  }
}

export function* watcherCreatePlanSaga() {
  yield takeLatest(
    requestForCreatePlan.type,
    createPlanSaga,
  );
}
export function* watcherAllPlanSaga() {
  yield takeLatest(requestForAllPlan.type, getAllPlanSaga);
}

export function* watcherDeletePlanSaga() {
  yield takeLatest(
    requestForDeletePlan.type,
    deletePlanSaga,
  );
}
export function* watcherUpdatePlanSaga() {
  yield takeLatest(
    requestForUpdatePlan.type,
    updatePlanSaga,
  );
}
export function* watcherGetPlanByIdSaga() {
  yield takeLatest(
    requestForCurrentPlan.type,
    getPlanByIdSaga,
  );
}
