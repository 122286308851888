import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  allEmployeeEnrolls: [],
  requestAllEmployeeEnroll: false,
  requestCreateEmployeeEnroll: false,
  requestDeleteEmployeeEnroll: false,
  requestUpdateEmployeeEnroll: false,
  failedAllEmployeeEnroll: false,
  responseAllEmployeeEnroll: false,
  responseCreateEmployeeEnroll: false,
  responseDeleteEmployeeEnroll: false,
  responseUpdateEmployeeEnroll: false,
  failedCreateEmployeeEnroll: false,
  failedDeleteEmployeeEnroll: false,
  failedUpdateEmployeeEnroll: false,
};
export const EmployeeEnrollSlice = createSlice({
  name: "employeeEnroll",
  initialState,
  reducers: {
    reset: (state) => {
      state.requestAllEmployeeEnroll = false;
      state.failedAllEmployeeEnroll = false;
      state.responseAllEmployeeEnroll = false;
      state.requestCreateEmployeeEnroll = false;
      state.responseCreateEmployeeEnroll = false;
      state.failedCreateEmployeeEnroll = false;
      state.requestUpdateEmployeeEnroll = false;
      state.responseUpdateEmployeeEnroll = false;
      state.failedUpdateEmployeeEnroll = false;
      state.requestDeleteEmployeeEnroll = false;
      state.responseDeleteEmployeeEnroll = false;
      state.failedDeleteEmployeeEnroll = false;
    },
    requestForAllEmployeeEnroll: (state) => {
      state.requestAllEmployeeEnroll = true;
    },
    responseForAllEmployeeEnroll: (state, action) => {
      state.allEmployeeEnrolls = action?.payload;
      state.responseAllEmployeeEnroll = true;
    },
    failedForAllEmployeeEnroll: (state) => {
      state.failedAllEmployeeEnroll = true;
    },
    requestForCreateEmployeeEnroll: (state) => {
      state.requestCreateEmployeeEnroll = true;
    },
    responseForCreateEmployeeEnroll: (state) => {
      state.responseCreateEmployeeEnroll = true;
    },
    failedForCreateEmployeeEnroll: (state) => {
      state.failedCreateEmployeeEnroll = true;
    },
    requestForDeleteEmployeeEnroll: (state) => {
      state.requestDeleteEmployeeEnroll = true;
    },
    responseForDeleteEmployeeEnroll: (state) => {
      state.responseDeleteEmployeeEnroll = true;
    },
    failedForDeleteEmployeeEnroll: (state) => {
      state.failedDeleteEmployeeEnroll = true;
    },
    requestForUpdateEmployeeEnroll: (state) => {
      state.requestUpdateEmployeeEnroll = true;
    },
    responseForUpdateEmployeeEnroll: (state) => {
      state.responseUpdateEmployeeEnroll = true;
    },
    failedForUpdateEmployeeEnroll: (state) => {
      state.failedUpdateEmployeeEnroll = true;
    },
  },
});
export default EmployeeEnrollSlice.reducer;
export const {
  failedForAllEmployeeEnroll,
  failedForCreateEmployeeEnroll,
  failedForDeleteEmployeeEnroll,
  failedForUpdateEmployeeEnroll,
  requestForAllEmployeeEnroll,
  requestForCreateEmployeeEnroll,
  responseForCreateEmployeeEnroll,
  requestForDeleteEmployeeEnroll,
  requestForUpdateEmployeeEnroll,
  reset,
  responseForAllEmployeeEnroll,
  responseForDeleteEmployeeEnroll,
  responseForUpdateEmployeeEnroll,
} = EmployeeEnrollSlice.actions;
