import * as Yup from "yup";
export const planInitial = {
  planName: "",
  planDuration: "",
  planMRP: 0,
  planOfferPrice: "",
  isCouponEnabled: false,
  
};

export const planSchema = Yup.object().shape({
  planName: Yup.string("Please Enter Plan Name!").required(
    "Plan Name Is Required!",
  ),
  planDuration: Yup.string("Please Select Plan Duration!").required(
    "Plan Duration Is Required!",
  ),
  
  planMRP: Yup.string("Please Enter Plan MRP!").required(
    "Plan MRP Is Required!",
  ),
  planOfferPrice: Yup.string("Please Enter Plan Offer Price!").required(
    "Plan Offer Price Is Required!",
  ),
  
  isCouponEnabled: Yup.string("Please Select Coupon Applicable or Not"),
  
});
