import { Fragment, useEffect, useState } from "react";
import SubHeader from "../../../../../../common-components/page-elements/SubHeader";
import { Grid, Paper } from "@mui/material";
import GenericDropdown from "../../../../../../common-components/form-elements/genericDropdown";

import { Form, Formik, useFormik } from "formik";
import {
  moduleFeatureMapperInitial,
  moduleFeatureMapperSchema,
} from "../../../../../../common-components/validator/module-feature-mapper-validation";
import GenericLoadingButton from "../../../../../../common-components/form-elements/genericLoadingButton";
import {
  FEATURE_NAME_LABEL,
  MODULE_NAME_LABEL,
  PARENT_MODULE_NAME_LABEL,
  SUBMIT_LABEL,
} from "../../../../../../languages/en";
import { useDispatch, useSelector } from "react-redux";
import GenericInput from "../../../../../../common-components/form-elements/genericInput";
import { useParams } from "react-router-dom";
import { requestForUpdateModuleFeatureMapper } from "../../../../../../store/slices/ModuleFeatureMapperSlice";

const UpdateMapper = () => {
  const { id } = useParams();
  const { allModuleFeatureMappers } = useSelector(
    (state) => state.moduleFeatureMapper,
  );
  const [subModules, setSubModules] = useState([]);
  const dispatch = useDispatch();
  let { allModules, allSubModules } = useSelector((state) => state.module);
  let { allFeatures } = useSelector((state) => state.feature);

  const formik = useFormik({
    initialValues: moduleFeatureMapperInitial,
    validationSchema: moduleFeatureMapperSchema,
  });
  const updateClick = () => {
    const { values, errors } = formik;
    if (!Object.keys(errors).length) {
      dispatch(requestForUpdateModuleFeatureMapper({ ...values, id }));
    }
  };

  useEffect(() => {
    const res = allModuleFeatureMappers?.find((module) => module.id === +id);

    formik.setFieldValue("moduleID", res?.moduleID);
    formik.setFieldValue("subModuleID", res?.subModuleID);
    formik.setFieldValue("featureID", res?.featureID);
  }, [allModuleFeatureMappers]);
  useEffect(() => {
    setSubModules(() => {
      return allSubModules?.map((el) => {
        return {
          value: el?.id,
          label: el?.moduleName,
        };
      });
    });
  }, [allSubModules]);
  return (
    <Fragment>
      <SubHeader title={"Update Module Feature Mapping"} />
      <Paper sx={{ padding: "30px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <GenericDropdown
                    label={MODULE_NAME_LABEL}
                    data={allModules?.map((el) => {
                      return {
                        value: el.id,
                        label: el.moduleName,
                      };
                    })}
                    value={formik.values.moduleID}
                    name="moduleID"
                    id="moduleID"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.moduleID && formik.touched.moduleID ? (
                    <p name={"moduleID"} className="error">
                      {formik.errors.moduleID}
                    </p>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericDropdown
                    label={PARENT_MODULE_NAME_LABEL}
                    data={[
                      { value: 0, label: "Default Sub Module" },
                      ...subModules,
                    ]}
                    value={formik.values.subModuleID}
                    name="subModuleID"
                    id="subModuleID"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.subModuleID && formik.touched.subModuleID ? (
                    <p name={"subModuleID"} className="error">
                      {formik.errors.subModuleID}
                    </p>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericDropdown
                    label={FEATURE_NAME_LABEL}
                    data={allFeatures?.map((el) => {
                      return {
                        value: el?.id,
                        label: el?.featureName,
                      };
                    })}
                    value={formik.values.featureID}
                    name="featureID"
                    id="featureID"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.featureID && formik.touched.featureID ? (
                    <p name={"featureID"} className="error">
                      {formik.errors.featureID}
                    </p>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericLoadingButton
                    type="submit"
                    disabled={
                      Object.keys(formik.errors).length > 0 && !formik?.isValid
                    }
                    onClick={updateClick}
                  >
                    {SUBMIT_LABEL}
                  </GenericLoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Fragment>
  );
};
export default UpdateMapper;
