import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useRef, useState } from "react";

const SelectCompanyModalWrapper = (props) => {
  const {  value: valueProp, onCompanyChange, data } = props;
  const [value, setValue] = useState(valueProp);
//   const radioGroupRef = useRef(null);
  
  

  return (
    <RadioGroup
    //   ref={radioGroupRef}
      aria-label="ringtone"
      name="ringtone"
      value={value}
      onChange={(evt)=> onCompanyChange(evt,data)}
    >
      {data.map((option) => (
        <FormControlLabel
          value={option?.id}
          key={option?.id}
          control={<Radio />}
          label={option?.companyName}
        />
      ))}
    </RadioGroup>
  );
};
export default SelectCompanyModalWrapper;
