import * as Yup from "yup";
import {
  ADDRESS_REGEX,
  COMPANY_NAME_REGEX,
  COMPANY_WEBSITE_REGEX,
  EMAIL_REGEX,
  GST_REGEX,
  MOBILE_REGEX,
  OWNER_REGEX,
  PINCODE_REGEX,
} from "../../../utils/regular-expressions";

export const brFrInitial = {
  website: "",
  gstNo: "",
  state: "",
  city: "",
  ownerName:"",
  locality: "",
  landmark: "",
  brFrType: "",
  companyID: "",
  brFrName: "",
  registeredMobile: "",
  pincode: "",
  address: "",
  email: ""
};
export const brFrSchema = Yup.object().shape({
  gstNo: Yup.string("Enter GST NO.")
    .required("GST NO. Is Required")
    .matches(GST_REGEX, "Please Enter Valid GST NO."),
  website: Yup.string("Enter Website")
    .matches(COMPANY_WEBSITE_REGEX, "Please Enter Valid Website"),
  state: Yup.string("Enter State")
  .required("State Is Required")
    .matches(ADDRESS_REGEX, "Please Enter Valid State"),
  city: Yup.string("Enter City")
  .required("City Is Required")
    .matches(ADDRESS_REGEX, "Please Enter Valid City"),
  locality: Yup.string("Enter Locality")
    .matches(ADDRESS_REGEX, "Please Enter Valid Locality"),
  brFrType: Yup.string("Please Select The Entity Type")
    .required("Entity Type Is Required"),
  brFrName: Yup.string("Enter Branch Or Franchise Name")
    .required("Branch Or Franchise Name Is Required")
    .matches(COMPANY_NAME_REGEX, "Please Enter Valid Name"),
    ownerName: Yup.string("Enter Owner Name")
    .required("Owner Is Required")
    .matches(OWNER_REGEX, "Please Enter Valid Owner Name"),
  registeredMobile: Yup.string("Enter Mobile Number")
    .required("Mobile Number Is Required")
    .max(10, "Mobile Number Should Be 10 Digits Only")
    .matches(MOBILE_REGEX, "Please Enter Valid Mobile Number"),
  pincode: Yup.string("Enter Pincode")
    .required("Pincode Is Required")
    .max(6, "Pincode Should Be 6 Digits Only")
    .matches(PINCODE_REGEX, "Please Enter Valid Pincode"),
  address: Yup.string("Enter Address")
    .required("Address Is Required")
    .matches(ADDRESS_REGEX, "Please Enter Valid Address"),
  landmark: Yup.string("Enter Landmark")
    .matches(ADDRESS_REGEX, "Please Enter Valid Landmark"),
  email: Yup.string("Enter Email")
    .required("Email Is Required")
    .matches(EMAIL_REGEX, "Please Enter Valid Email."),
});
