import { Grid, Paper } from "@mui/material";
import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import GenericLoadingButton from "../../../../../../common-components/form-elements/genericLoadingButton";
import GenericInput from "../../../../../../common-components/form-elements/genericInput";
import SubHeader from "../../../../../../common-components/page-elements/SubHeader";
import {
  PARENT_MODULE_NAME_LABEL,
  UPDATE_MODULE_LABEL,
  MODULE_NAME_LABEL,
  SUBMIT_LABEL,
} from "../../../../../../languages/en";
import GenericDropdown from "../../../../../../common-components/form-elements/genericDropdown";
import {
  moduleInitial,
  moduleSchema,
} from "../../../../../../common-components/validator/module-validation";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { requestForUpdateModule } from "../../../../../../store/slices/ModuleSlice";

const UpdateModule = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { allModules } = useSelector((state) => state.module);
  const [modules, setModules] = useState([]);

  useEffect(() => {
    setModules(() => {
      return allModules?.map((el) => {
        return {
          value: el?.id,
          label: el?.moduleName,
        };
      });
    });
  }, [allModules]);
  const formik = useFormik({
    initialValues: moduleInitial,
    validationSchema: moduleSchema,
  });

  useEffect(() => {
    const res = allModules?.find((module) => module.id === +id);
    formik.setFieldValue("moduleName", res?.moduleName);
    formik.setFieldValue("parentModuleID", res?.parentModuleID);
  }, [allModules]);

  const updateClick = () => {
    const { values, errors } = formik;
    if (!Object.keys(errors).length) {
      dispatch(requestForUpdateModule({ ...values, id }));
    }
  };
  return (
    <Fragment>
      <SubHeader title={UPDATE_MODULE_LABEL} />
      <Paper sx={{ padding: "30px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <GenericDropdown
                    label={PARENT_MODULE_NAME_LABEL}
                    data={[{ value: 0, label: "Default Module" }, ...modules]}
                    name="parentModuleID"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.parentModuleID}
                    id="parentModuleID"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericInput
                    label={MODULE_NAME_LABEL}
                    name="moduleName"
                    id="moduleName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.moduleName}
                  />
                  {formik.errors.moduleName && formik.touched.moduleName ? (
                    <p name={"moduleName"} className="error">
                      {formik.errors.moduleName}
                    </p>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={4}>
                  <GenericLoadingButton
                    type="submit"
                    disabled={
                      Object.keys(formik.errors).length > 0 && !formik?.isValid
                    }
                    onClick={updateClick}
                  >
                    {SUBMIT_LABEL}
                  </GenericLoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Fragment>
  );
};
export default UpdateModule;
