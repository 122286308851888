import { put, takeLatest } from "redux-saga/effects";
import {
  createModuleFeatureMapperService,
  deleteModuleFeatureMapperService,
  getAllModuleFeatureMapperService,
  updateModuleFeatureMapperService,
} from "../../../services/module-feature-mapper";
import {
  failedForAllModuleFeatureMapper,
  failedForCreateModuleFeatureMapper,
  failedForDeleteModuleFeatureMapper,
  failedForUpdateModuleFeatureMapper,
  requestForAllModuleFeatureMapper,
  requestForCreateModuleFeatureMapper,
  requestForDeleteModuleFeatureMapper,
  requestForUpdateModuleFeatureMapper,
  responseForAllModuleFeatureMapper,
  responseForCreateModuleFeatureMapper,
  responseForDeleteModuleFeatureMapper,
  responseForUpdateModuleFeatureMapper,
} from "../../slices/ModuleFeatureMapperSlice";

function* getAllModuleFeatureMapperSaga(action) {
  try {
    const response = yield getAllModuleFeatureMapperService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForAllModuleFeatureMapper(response?.data));
    } else {
      yield put(failedForAllModuleFeatureMapper(response));
    }
  } catch (error) {
    yield put(failedForAllModuleFeatureMapper(error));
  }
}

function* createModuleFeatureMapperSaga(action) {
  try {
    const response = yield createModuleFeatureMapperService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForCreateModuleFeatureMapper());
    } else {
      yield put(failedForCreateModuleFeatureMapper(response));
    }
  } catch (error) {
    yield put(failedForCreateModuleFeatureMapper(error));
  }
}

function* deleteModuleFeatureMapperSaga(action) {
  try {
    const response = yield deleteModuleFeatureMapperService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForDeleteModuleFeatureMapper(response));
    } else {
      yield put(failedForDeleteModuleFeatureMapper(response));
    }
  } catch (error) {
    yield put(failedForDeleteModuleFeatureMapper(error));
  }
}
function* updateModuleFeatureMapperSaga(action) {
  try {
    const response = yield updateModuleFeatureMapperService(action.payload);
    const { code } = response;
    if (code === 200) {
      yield put(responseForUpdateModuleFeatureMapper(response));
    } else {
      yield put(failedForUpdateModuleFeatureMapper(response));
    }
  } catch (error) {
    yield put(failedForUpdateModuleFeatureMapper(error));
  }
}

export function* watcherCreateModuleFeatureMapperSaga() {
  yield takeLatest(
    requestForCreateModuleFeatureMapper.type,
    createModuleFeatureMapperSaga,
  );
}
export function* watcherAllModuleFeatureMapperSaga() {
  yield takeLatest(
    requestForAllModuleFeatureMapper.type,
    getAllModuleFeatureMapperSaga,
  );
}

export function* watcherDeleteModuleFeatureMapperSaga() {
  yield takeLatest(
    requestForDeleteModuleFeatureMapper.type,
    deleteModuleFeatureMapperSaga,
  );
}
export function* watcherUpdateModuleFeatureMapperSaga() {
  yield takeLatest(
    requestForUpdateModuleFeatureMapper.type,
    updateModuleFeatureMapperSaga,
  );
}
