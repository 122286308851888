import AddIcon from "@mui/icons-material/Add";
import DashboardIcon from "@mui/icons-material/Dashboard";
import RemoveIcon from "@mui/icons-material/Remove";
import { IconButton, List, ListItem, ListItemText } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getAuthState, getCompanyState } from "../../store/selectors";
import { getAuthFeatureList, updateTreeVisibility } from "../../utils";
import { DASHBOARD_MENU_ITEMS } from "../constants";

const NestedList = ({ items }) => {
  const [openItems, setOpenItems] = useState([]);
  const auth = useSelector(getAuthState);
  const company = useSelector(getCompanyState);
  const { authDetails } = auth;
  const [featureList, setFeatureList] = useState(
    getAuthFeatureList(authDetails)
  );
  const [menuItems, setMenuItems] = useState(items);
  const { currentCompany } = company;

  useEffect(() => {
    const dashboard = {
      icon: <DashboardIcon />,
      index: 0,
      label: "Dashboard",

      path: "./erp",
      visible: true,
    };
    if (featureList) {
      setMenuItems([
        { ...dashboard },
        ...updateTreeVisibility(featureList, DASHBOARD_MENU_ITEMS),
      ]);
    } else {
      setMenuItems([{ ...dashboard }]);
    }
  }, [featureList]);
  useEffect(() => {
    if (currentCompany?.purchase?.plan?.planFeatures) {
      setFeatureList(currentCompany?.purchase?.plan?.planFeatures);
    }
  }, [currentCompany?.purchase?.plan?.planFeatures]);

  const handleClick = (index) => {
    if (openItems.includes(index)) {
      setOpenItems(openItems.filter((item) => item !== index));
    } else {
      setOpenItems([...openItems, index]);
    }
  };

  const renderNestedList = (nestedItems) => {
    return (
      <List disablePadding>
        {nestedItems.map((nestedItem, index) => {
          if (!nestedItem.visible) return null; // Only render if visible is true

          return (
            <NavLink
              to={nestedItem.path}
              key={index}
              style={{ textDecoration: "none", color: "black" }}
            >
              <NestedListItem item={nestedItem} />
            </NavLink>
          );
        })}
      </List>
    );
  };

  const NestedListItem = ({ item }) => {
    const hasNestedItems = item.nestedItems && item.nestedItems.length > 0;
    const isOpen = openItems.includes(item.index);

    if (!item.visible) return null;

    return (
      <Fragment>
        <ListItem
          button
          onClick={() => handleClick(item.index)}
          sx={{
            pl: hasNestedItems ? 1 : 1,
          }}
        >
          <Box sx={{ marginRight: "10px" }}> {item.icon}</Box>
          <ListItemText primary={item.label} className="sideList" />
          {hasNestedItems && (
            <IconButton
              sx={{ marginLeft: "5rem" }}
              color="primary"
              className="sideList"
              edge="end"
              size="small"
              disableRipple={true}
              onClick={() => handleClick(item.index)}
            >
              {isOpen ? (
                <RemoveIcon sx={{ height: "1rem", width: "1rem" }} />
              ) : (
                <AddIcon sx={{ height: "1rem", width: "1rem" }} />
              )}
            </IconButton>
          )}
        </ListItem>
        {hasNestedItems && (
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            {renderNestedList(item.nestedItems)}
          </Collapse>
        )}
      </Fragment>
    );
  };

  return (
    <List
      component="nav"
      disablePadding
      sx={{ height: "88vh", overflow: "auto" }}
    >
      {menuItems.map((item, index) => (
        <NestedListItem key={index} item={item} />
      ))}
    </List>
  );
};

export default NestedList;
