import { Route, Routes } from "react-router-dom";

import AddAccounts from "./add";
import UpdateAccounts from "./update";

const AccountsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AddAccounts />} />
      <Route path="/:id" element={<UpdateAccounts />} />
    </Routes>
  );
};
export default AccountsRoutes;
